import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { Global } from "../../data/Global";
import { _DICC_ALUMNO } from "../../data/modulo/Alumno";
import { _SvEscuelaObtenerDashboardInfo } from "../../data/modulo/Escuela";
import { DataModuloMain } from "../../data/ModuloMain";
import { DataUtil } from "../../data/util/Util";
import { MainPage } from "../../MainPage";
import { DateV2 } from "../../util/DateV2";
import _L, { _FixHTMLTags } from "../../util/Labels";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { HTMLProgressElement } from "../controlWC/ProgressComponent";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import dashboardHTMLTemplate from "../template/dashboard.html?raw";
import { UIUtilElement } from "../util/Element";
import { UIUtilElementBehaviors } from "../util/ElementBehaviors";
import { UIUtilFormat } from "../util/Format";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";

type IAlumnoCumple = Pick<Entidad.IAlumno, "NombreCompleto" | "FechaNacimiento">;

interface IEventoAlumno {
    TipoEvento: Entidad.CTipoEvento;
    SrcIcon: string;
    EvCount: number;
}

interface IFinanzasPerMonth {
    MonthName: string;
    MonthShortName: string;
    Ingresos: Entidad.IFinanzasMesDashboard;
    Egresos: Entidad.IFinanzasMesDashboard;
}

export class UIVentanaHome extends VentanaBase {
    private escuelaSelectorItemTemplate: HTMLDivElement;
    private rowAgendaTemplate: HTMLDivElement;
    private rowCumpleAlumnoTemplate: HTMLDivElement;
    private eventItemTemplate: HTMLDivElement;
    private labelAxisTemplate: HTMLSpanElement;
    private lineAxisTemplate: HTMLHRElement;
    private monthAxisLabelTemplate: HTMLDivElement;
    private chartsGroupTemplate: HTMLDivElement;
    private tooltipChartTemplate: HTMLDivElement;
    private divAgendaEmptyCard: HTMLDivElement;
    private divAlumnoCumpleEmptyCard: HTMLDivElement;
    private escuelasOrganizacionList: Entidad.IEscuela[];
    private eventosInfoList: IEventoAlumno[];
    private agendaEventosList: Entidad.IEventosAgendaDashboard[];
    private alumnosCumplesList: IAlumnoCumple[];
    private escuelaSelected: Entidad.IEscuela;
    private finanzasAnioList: IFinanzasPerMonth[];
    private dashboardDataDefault: Entidad.IDashboardInfo;
    private chartMaxHeigthValue: number;
    private ctrlProgress: TSelectionHTML<"wc-progress">;
    private currentDate: Date;
    private dashboardData: Entidad.IDashboardInfo;
    private reziseObserver: ResizeObserver;

    private escuelasDashboardData: Map<number, Entidad.IDashboardInfo>;

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);

        this.Init();

        if (!this.IsDashboardAvailable()) {
            return
        }
        this.dashboardDataDefault = {
            GruposCount: 0,
            MaestrosCount: 0,
            AlumnosActivosCount: 0,
            EventosAlumnos: [],
            IngresosMesCount: 0,
            IngresosMesSum: 0,
            PagosPorRecibirMesCount: 0,
            EgresosMesCount: 0,
            EgresosMesSum: 0,
            PagosPorRealizarMesCount: 0,
            EventosAgenda: [],
            IngresosAnioCount: 0,
            IngresosAnioSum: 0,
            PagosPorRecibirAnioCount: 0,
            EgresosAnioCount: 0,
            EgresosAnioSum: 0,
            PagosPorRealizarAnioCount: 0,
            PagosPorRecibirMesAlumnosCount: 0,
            EgresosPorMes: [],
            IngresosPorMes: []
        }
        this.finanzasAnioList = [];
        this.ctrlProgress = this.windowContent.append<HTMLProgressElement>("wc-progress").attr("oculto", true);
        this.currentDate = new Date();
        this.escuelasDashboardData = new Map<number, Entidad.IDashboardInfo>();
    }

    private async Init() {
        let finalHTML = _FixHTMLTags(dashboardHTMLTemplate)

        if (!this.IsDashboardAvailable()) {
            this.BuildBasicHomeView()
            return
        }

        this.reziseObserver = UIUtilElement._GetResizeObserver(entries => {
            setTimeout(() => {
                this.RefreshCharts();
            }, 100)
        })

        this.reziseObserver?.observe(document.body);

        const templateNode: HTMLTemplateElement = UIUtilElement._CreateElementFromHTML(finalHTML) as any
        this.windowContent.classed("dashboard", true)
            .append(() => templateNode.content.cloneNode(true) as any)

        await UIUtilGeneral._Sleep(0);
        this.Loading();

        this.ctrlProgress.attr("oculto", false);
        await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Escuela);
        this.escuelasOrganizacionList = DataModuloMain._GetReqDataArrayByName("Escuela")
            .filter(({ IdKinder }) => UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Ver, Entidad.CModulo.Dashboard, IdKinder))
        if (this.escuelasOrganizacionList.length) {
            this.escuelaSelected = this.escuelasOrganizacionList[0];
        }
        this.RefreshEscuelasSelectorItems();
        const escuelasHeaderContainer = d3.select<HTMLDivElement, any>(".escuelas_selector_header");
        this.ctrlProgress.attr("oculto", "true");
        escuelasHeaderContainer.selectAll<HTMLDivElement, any>(".escuela_item_select").nodes()[0].click();
        this.StartDashBoardMonitor();
        return
    }

    private IsDashboardAvailable() {
        return !Global._GLOBAL_CONF.RELEASE_MODE && UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Ver, Entidad.CModulo.Dashboard)
    }

    private BuildBasicHomeView() {
        this.windowContent
            .classed("dashboard_basic", true)
            .append("div")
            .attr("class", "welcome_box")
            .text(_L("home.welcome"))
    }

    private StartDashBoardMonitor() {
        this["_DashboardMonitor"] = setInterval(() => {
            this.RefreshDashboard(this.escuelaSelected, true);
        }, 60000 * 10)
    }

    private Loading() {
        d3.selectAll<HTMLDivElement, any>(".card_finanzas_chart .axis_months >div").nodes().forEach((element, i, arr) => {
            arr[i].textContent = UIUtilTime._GetMonthName(i, "short");
        })

        this.currentDate = new Date();
        const currentMonth = UIUtilTime._GetMonthName(this.currentDate.getMonth());
        const currentYear = this.currentDate.getFullYear();

        d3.select<HTMLElement, any>(".card_finanza1.mes >b").text(_L("home.finanzas_period", currentMonth.toLowerCase()));
        d3.select<HTMLElement, any>(".card_finanza1.anio >b").text(_L("home.finanzas_period", currentYear));
        d3.select<HTMLElement, any>(".card_cal_birthdays >div[header] >b").text(_L("home.cumpleanios_period", currentMonth.toLowerCase()));
        d3.select<HTMLElement, any>(".card_finanza2.income >b").text(_L("home.ingresos_period", currentMonth.toLowerCase()));
        d3.select<HTMLElement, any>(".card_finanza2.outcome >b").text(_L("home.egresos_period", currentMonth.toLowerCase()));

        const escuelasHeaderSelector = d3.select<HTMLDivElement, any>(".escuelas_selector_header");
        this.escuelaSelectorItemTemplate = escuelasHeaderSelector.select<HTMLDivElement>(":scope>.escuela_item_select").remove().node();
        const nEscuelasLoading = 4;
        for (let i = 0; i < nEscuelasLoading; i++) {
            const escuelaItemLoad = this.escuelaSelectorItemTemplate.cloneNode(true) as HTMLDivElement;
            escuelasHeaderSelector.append(() => escuelaItemLoad);
            escuelaItemLoad.classList.add("animated-background");
        }

        d3.select<HTMLElement, any>(".card_info_gral>b").classed("animated-background", true);
        const asi = d3.select<HTMLSpanElement, any>(".card_asistencia span[lbl_2_asistencia_1]").node();
        asi.textContent = _L("home.asistencia_desc", 0, 0);

        this.tooltipChartTemplate = d3.select<HTMLDivElement, any>("div[dashboard_tooltip_chart_content]").remove().node();

        const nEventItemsLoading = 6;
        const EventosContainer = d3.select<HTMLDivElement, any>(".card_ev div[container]");
        this.eventItemTemplate = EventosContainer.select<HTMLDivElement>(":scope>div").remove().node();

        for (let i = 0; i < nEventItemsLoading; i++) {
            const evItemLoad = this.eventItemTemplate.cloneNode(true) as HTMLDivElement;
            EventosContainer.append(() => evItemLoad);
            evItemLoad.classList.add("animated-background");
        }

        const nRowsLoading = 3;
        const AgendaContainer = d3.select<HTMLDivElement, any>(".card_cal_evs div[container]");
        const CumplesContainer = d3.select<HTMLDivElement, any>(".card_cal_birthdays div[container]");
        this.rowAgendaTemplate = AgendaContainer.select<HTMLDivElement>(":scope>div").remove().node();
        this.rowCumpleAlumnoTemplate = CumplesContainer.select<HTMLDivElement>(":scope>div").remove().node();
        this.divAgendaEmptyCard = d3.select<HTMLDivElement, any>(".card_cal_evs div[empty]")
            .call((sel) => sel.select("label").text(_L("home.noevents_inday")))
            .call((sel) => sel.select<HTMLDivElement>("div").append("img").attr("src", UIUtilIconResources.CGeneral.Evento))
            .remove().node();

        this.divAlumnoCumpleEmptyCard = d3.select<HTMLDivElement, any>(".card_cal_birthdays div[empty]")
            .call((sel) => sel.select("label").text(_L("home.nobirths_inmonth")))
            .call((sel) => sel.select<HTMLDivElement>("div").append("img").attr("src", UIUtilIconResources.CGeneral.Pastel))
            .remove().node();

        for (let i = 0; i < nRowsLoading; i++) {
            const rowAgendaLoad = this.rowAgendaTemplate.cloneNode(true) as HTMLDivElement;
            const rowCumpleLoad = this.rowCumpleAlumnoTemplate.cloneNode(true) as HTMLDivElement;
            AgendaContainer.append(() => rowAgendaLoad);
            CumplesContainer.append(() => rowCumpleLoad);
            rowAgendaLoad.classList.add("animated-background");
            rowCumpleLoad.classList.add("animated-background");
        }

        const graficaContainer = d3.select<HTMLDivElement, any>(".card_finanzas_chart div[container]");
        this.labelAxisTemplate = graficaContainer.select<HTMLSpanElement>(":scope>.amount_axis_label>span").remove().node();
        this.lineAxisTemplate = graficaContainer.select<HTMLHRElement>(":scope>.amount_axis_lines>hr").remove().node();
        this.monthAxisLabelTemplate = graficaContainer.select<HTMLDivElement>(":scope>.axis_months>div").remove().node();
        this.chartsGroupTemplate = graficaContainer.select<HTMLDivElement>(":scope>.charts_container>div").remove().node();

        const nYDivs = 11;
        for (let i = 0; i < nYDivs; i++) {
            const labelAxisLoad = this.labelAxisTemplate.cloneNode(true) as HTMLSpanElement;
            const lineAxisLoad = this.lineAxisTemplate.cloneNode(true) as HTMLHRElement;
            graficaContainer.select(":scope>.amount_axis_label").append(() => labelAxisLoad);
            graficaContainer.select(":scope>.amount_axis_lines").append(() => lineAxisLoad);
            labelAxisLoad.classList.add("animated-background");
        }

        const nMonths = this.currentDate.getMonth() + 1;
        for (let i = 0; i < nMonths; i++) {
            const monthAxisLabelLoad = this.monthAxisLabelTemplate.cloneNode(true) as HTMLDivElement;
            const mothChartsGroupLoad = this.chartsGroupTemplate.cloneNode(true) as HTMLDivElement;
            monthAxisLabelLoad.textContent = UIUtilTime._GetMonthName(i);
            graficaContainer.select(":scope>.axis_months").append(() => monthAxisLabelLoad);
            graficaContainer.select(":scope>.charts_container").append(() => mothChartsGroupLoad);
        }
    }

    private async RefreshDashboard(escuela: Entidad.IEscuela, comesFromMonitor = false) {
        this.dashboardData = this.escuelasDashboardData.get(escuela?.IdKinder) || this.dashboardDataDefault;
        this.escuelaSelected = escuela;
        this.currentDate = new Date();
        const EscuelaPlaying: Entidad.IEscuela = this.escuelaSelected ? this.escuelaSelected : <Entidad.IEscuela>{ IdKinder: -1, Nombre: "" };
        if (this.escuelaSelected) {
            if (!comesFromMonitor) this.ctrlProgress.attr("oculto", false);
            const resEscuelaDashboardInfo = await _SvEscuelaObtenerDashboardInfo(this.escuelaSelected.IdKinder, new Date().toISOString());
            if (!comesFromMonitor) this.ctrlProgress.attr("oculto", true);
            if (resEscuelaDashboardInfo.Resultado > 0) {
                this.dashboardData = (resEscuelaDashboardInfo as any).InfoResult || this.dashboardDataDefault;
                this.escuelasDashboardData.set(EscuelaPlaying.IdKinder, this.dashboardData);
            } else if (!comesFromMonitor) {
                NotificacionV2._Mostrar(_L("general.notif_fail"), "ADVERTENCIA");
            }
        }

        // AJUSTE DE FECHAS AGENDA: Las fechas que terminan al primer instante del día deberán de ser ajustadas para terminar en el último instante del día anterior
        this.dashboardData.EventosAgenda.forEach((d) => {
            const dtFin = new DateV2(new Date(d.Fin))._SetTimeZoneByIdSchool(EscuelaPlaying.IdKinder);
            const dtAux = new DateV2(dtFin);
            dtAux.setMilliseconds(dtAux.getMilliseconds() - 1);
            if (dtFin.getDate() != dtAux.getDate()) {
                d.Fin = dtAux._ToISOString();
            }
        })
        for (let i = 0; i < 12; i++) {
            this.finanzasAnioList[i] = { MonthName: UIUtilTime._GetMonthName(i), MonthShortName: UIUtilTime._GetMonthName(i, "short"), Ingresos: this.dashboardData.IngresosPorMes[i] || { Count: 0, Sum: 0 }, Egresos: this.dashboardData.EgresosPorMes[i] || { Count: 0, Sum: 0 } };
        }

        let dtAuxiliar = new DateV2(new Date())._SetTimeZoneByIdSchool(EscuelaPlaying.IdKinder);
        dtAuxiliar.setHours(0);
        dtAuxiliar.setMinutes(0);
        dtAuxiliar.setSeconds(0);
        dtAuxiliar.setMilliseconds(0);
        dtAuxiliar._ToISOString();
        this.dashboardData.EventosAgenda = this.dashboardData.EventosAgenda.filter(d => d.Fin >= dtAuxiliar._ToISOString());

        // INFO GRAL
        this.RefreshInfoGral();

        // ASISTENCIA
        const alumnosAsistenciaCount = d3Group((this.dashboardData.EventosAlumnos).filter(d => d.IdTipoEvento == 9), d => d.IdPersona).size;
        this.RefreshAsistencia(alumnosAsistenciaCount);

        // EVENTOS
        this.RefreshEvents();

        // AGENDA DEL DÍA
        this.agendaEventosList = this.dashboardData.EventosAgenda;
        this.agendaEventosList = this.agendaEventosList.sort((a, b) => {
            const dateA = new DateV2(new Date(a.Inicio))._SetTimeZoneByIdSchool(EscuelaPlaying.IdKinder);
            dateA.setFullYear(dtAuxiliar.getFullYear(), dtAuxiliar.getMonth(), dtAuxiliar.getDate());
            const dateB = new DateV2(new Date(b.Inicio))._SetTimeZoneByIdSchool(EscuelaPlaying.IdKinder);
            return dateA.getTime() - dateB.getTime();
        });
        this.RefreshAgendaDelDiaRows();

        // CUMPLEAÑOS
        const dtAux = new DateV2(new Date())._SetTimeZoneByIdSchool(EscuelaPlaying.IdKinder);
        this.alumnosCumplesList = Array.from(_DICC_ALUMNO.values()).filter(d => d.IdChildMovimiento == Entidad.CNinioMovimiento.Activo && d.IdKinder == EscuelaPlaying.IdKinder);
        this.alumnosCumplesList = this.alumnosCumplesList.filter(d => new DateV2(new Date(d.FechaNacimiento)).getMonth() == dtAux.getMonth()).sort((a, b) => new DateV2(new Date(a.FechaNacimiento))._SetTimeZoneByIdSchool(EscuelaPlaying.IdKinder).getDate() - new DateV2(new Date(b.FechaNacimiento))._SetTimeZoneByIdSchool(EscuelaPlaying.IdKinder).getDate());
        this.RefreshCumplesRows();

        // FINANZAS MES
        this.RefreshFinanzasInfoMes();

        // FINANZAS AÑO
        this.RefreshFinanzasInfoAnio();

        // INGRESOS MES
        this.RefreshIngresosMesInfo();

        // INGRESOS ANIO
        this.RefreshEgresosMesInfo();

        this.RefreshFinanzasAnioCharts();
    }

    private RefreshEscuelasSelectorItems() {
        if (!this.escuelasOrganizacionList.length) {
            this.escuelasOrganizacionList = [<Entidad.IEscuela>{ IdKinder: -1, Nombre: "Sin Escuelas", Logo: UIUtilIconResources.CMenuIco.Escuela }]
        }
        const escuelasHeaderContainer = d3.select<HTMLDivElement, any>(".escuelas_selector_header");
        escuelasHeaderContainer.selectAll<HTMLDivElement, Entidad.IEscuela>(":scope>.escuela_item_select")
            .data(this.escuelasOrganizacionList, d => d?.IdKinder)
            .join(
                enter => this.RefreshEscuelaItem(enter.append(() => this.escuelaSelectorItemTemplate.cloneNode(true) as HTMLDivElement)),
                update => this.RefreshEscuelaItem(update),
                exit => exit.remove()
            )
    }

    private RefreshEscuelaItem(item: TSelectionHTML<"div", Entidad.IEscuela>) {
        return item.each((d, i, items) => {
            const itemEsc = items[i];
            itemEsc.classList.remove("animated-background");
            let wcImage = d3.select(itemEsc).select("wc-img");
            if (!wcImage.node()) {
                wcImage = d3.select(itemEsc).append("wc-img");
                wcImage.attr("default-src", UIUtilIconResources.CMenuIco.Escuela);
            }

            wcImage.attr("src", d.Logo);

            itemEsc.querySelector<HTMLSpanElement>(":scope>span").textContent = d.Nombre;

            itemEsc.onclick = () => {
                for (let j = 0; j < items.length; j++) { items[j].classList.remove("active"); }
                itemEsc.classList.add("active");
                this.RefreshDashboard(d.IdKinder > 0 ? d : null);
            }
        })
    }

    private RefreshInfoGral() {
        d3.select<HTMLElement, any>(".card_info_gral >b").text("Resumen").classed("animated-background", false);
        const gruposCountSel = d3.select<HTMLSpanElement, any>(".card_info_gral span[lbl_1_grupos] span[val]").node();
        this.InterpolateNumbers(gruposCountSel, this.dashboardData.GruposCount);
        const maestrosCountSel = d3.select<HTMLSpanElement, any>(".card_info_gral span[lbl_1_maestr] span[val]").node();
        this.InterpolateNumbers(maestrosCountSel, this.dashboardData.MaestrosCount);
        const alumnosActivosSel = d3.select<HTMLSpanElement, any>(".card_info_gral span[lbl_1_activs] span[val]").node();
        this.InterpolateNumbers(alumnosActivosSel, this.dashboardData.AlumnosActivosCount);
    }

    private RefreshAsistencia(alumnosAsistCount: number) {
        const asistenciaPercent = !this.dashboardData.AlumnosActivosCount ? 0 : (alumnosAsistCount * 100 / this.dashboardData.AlumnosActivosCount).toFixed(0);
        const asisPercentSel = d3.select<HTMLLabelElement, any>(".card_asistencia .chart_container >label");
        this.InterpolateNumbers(asisPercentSel.node(), Number(asistenciaPercent), (d) => d + "%", (d) => Number(d.replaceAll("%", "")));
        const donutchartPercentSel = d3.select<SVGCircleElement, any>(".card_asistencia .donut .donut-segment-2");
        donutchartPercentSel
            .transition()
            .ease(d3.easeExpOut)
            .duration(2000)
            .attr("stroke-dasharray", `${asistenciaPercent} ${(100 - Number(asistenciaPercent))}`);
        const asi = d3.select<HTMLSpanElement, any>(".card_asistencia span[lbl_2_asistencia_1]").node();
        this.InterpolateNumbers(asi, alumnosAsistCount, (d) => _L("home.asistencia_desc", d, this.dashboardData.AlumnosActivosCount), d => Number(d.split(" ")[0]));
    }

    private RefreshEvents() {
        const moduoloEval = DataModuloMain._GetDataValueFieldByName("Escuela", this.escuelaSelected?.IdKinder || -1, "ModuloEval");
        const isLogros = !moduoloEval || moduoloEval == Entidad.CTipoEvento.Logros;
        this.eventosInfoList = [
            {
                TipoEvento: Entidad.CTipoEvento.Comentarios,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Comentarios,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == Entidad.CTipoEvento.Comentarios).length
            },
            {
                TipoEvento: Entidad.CTipoEvento.Humor,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Humor,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == Entidad.CTipoEvento.Humor).length
            },
            {
                TipoEvento: Entidad.CTipoEvento.Alimentos,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Alimentos,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == Entidad.CTipoEvento.Alimentos).length
            },
            {
                TipoEvento: Entidad.CTipoEvento.Fotos,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Fotos,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == Entidad.CTipoEvento.Fotos).length
            },
            {
                TipoEvento: Entidad.CTipoEvento.Higiene,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Higiene,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == Entidad.CTipoEvento.Higiene).length
            },
            {
                TipoEvento: Entidad.CTipoEvento.Actividades,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Actividades,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == Entidad.CTipoEvento.Actividades).length
            },
            {
                TipoEvento: isLogros ? Entidad.CTipoEvento.Logros : Entidad.CTipoEvento.Evaluacion,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Logros,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == (isLogros ? Entidad.CTipoEvento.Logros : Entidad.CTipoEvento.Evaluacion)).length
            },
            {
                TipoEvento: Entidad.CTipoEvento.Siesta,
                SrcIcon: UIUtilIconResources.CActividadesEscolaridad.Siesta,
                EvCount: this.dashboardData.EventosAlumnos.filter(d => d.IdTipoEvento == Entidad.CTipoEvento.Siesta).length
            }
        ]
        this.RefreshEventosItems();
    }

    private RefreshEventosItems() {
        let tooltip = d3.select(this.windowContent.node().parentElement).select<HTMLTooltipComponent>("wc-tooltip[evs]").node();
        if (!tooltip) {
            tooltip = d3.select(this.windowContent.node().parentElement).append<HTMLTooltipComponent>("wc-tooltip")
                .attr("max-width", "250px")
                .attr("evs", "")
                .attr("position", "cursor")
                .node()
        }

        const ev_container = d3.select<HTMLDivElement, any>(".card_ev div[container]");
        ev_container.selectAll<HTMLDivElement, any>(":scope>div")
            .data(this.eventosInfoList)
            .join(
                enter => this.RefreshEventItem(enter.append(() => this.eventItemTemplate.cloneNode(true) as HTMLDivElement), tooltip),
                update => this.RefreshEventItem(update, tooltip),
                exit => exit.remove()
            )
    }

    private RefreshEventItem(item: TSelectionHTML<"div", IEventoAlumno>, tooltip: HTMLTooltipComponent) {
        return item.each((d, i, items) => {
            const itemEv = items[i];
            itemEv.classList.remove("animated-background");
            itemEv.querySelector<HTMLImageElement>(":scope>div[ev_img] img").src = d.SrcIcon;
            this.InterpolateNumbers(itemEv.querySelector<HTMLSpanElement>(":scope>div[ev_counts] span[val]"), d.EvCount)
            itemEv.querySelector<HTMLSpanElement>(":scope>div[ev_counts] span:not([val])").textContent = UIUtilViewData._GetStr_TipoEvento(Entidad.CTipoEvento[d.TipoEvento].toLowerCase() as any)
            UIUtilElementBehaviors._EllipsisTextTooltip(itemEv.querySelector<HTMLSpanElement>(":scope>div[ev_counts] span[val]"), "top", null, tooltip);
        })
    }

    private RefreshFinanzasInfoMes() {
        const totalIngresosMesCount = this.dashboardData.IngresosMesCount + this.dashboardData.PagosPorRecibirMesCount;
        const pagosRecibidosMesPercent = !totalIngresosMesCount ? 0 : this.dashboardData.IngresosMesCount * 100 / totalIngresosMesCount;
        const totalEgresosMesCount = this.dashboardData.EgresosMesCount + this.dashboardData.PagosPorRealizarMesCount;
        const pagosRealizadosMesPercent = !totalEgresosMesCount ? 0 : this.dashboardData.EgresosMesCount * 100 / totalEgresosMesCount;
        const saldoMes = this.dashboardData.IngresosMesSum - this.dashboardData.EgresosMesSum;
        const saldoMesPercent = !this.dashboardData.IngresosMesSum ? 0 : saldoMes * 100 / this.dashboardData.IngresosMesSum;

        const finanzasMesContainerSel = d3.select<HTMLDivElement, any>(".card_finanza1.mes div[container]");
        const ingresosMesSel = finanzasMesContainerSel.select("div[lbl_ingreso]");
        const ingresosMesAmountSel = ingresosMesSel.select<HTMLSpanElement>("span[val]");
        this.InterpolateNumbers(ingresosMesAmountSel.node(), this.dashboardData.IngresosMesSum, (d) => UIUtilFormat._CurrencyFmt(d), d => this.UnFormatCurrencyTemp(d));
        const ingresosMesChartSel = ingresosMesSel.select("div[semi_donut_chart]");
        ingresosMesChartSel
            .transition()
            .ease(d3.easeExpOut)
            .duration(2000)
            .style("--percentage", pagosRecibidosMesPercent > 0 ? pagosRecibidosMesPercent : 0);

        const egresosMesSel = finanzasMesContainerSel.select("div[lbl_egreso]");
        const egresosMesAmountSel = egresosMesSel.select<HTMLSpanElement>("span[val]");
        this.InterpolateNumbers(egresosMesAmountSel.node(), this.dashboardData.EgresosMesSum, (d) => UIUtilFormat._CurrencyFmt(d), d => this.UnFormatCurrencyTemp(d));
        const egresosMesChartSel = egresosMesSel.select("div[semi_donut_chart]");
        egresosMesChartSel
            .transition()
            .ease(d3.easeExpOut)
            .duration(2000)
            .style("--percentage", pagosRealizadosMesPercent > 0 ? pagosRealizadosMesPercent : 0);

        const saldoMesSel = finanzasMesContainerSel.select("div[lbl_saldo]");
        const saldoMesAmountSel = saldoMesSel.select<HTMLSpanElement>("span[val]");
        this.InterpolateNumbers(saldoMesAmountSel.node(), saldoMes, (d) => UIUtilFormat._CurrencyFmt(d), d => this.UnFormatCurrencyTemp(d))
        const saldoMesChartSel = saldoMesSel.select("div[semi_donut_chart]");
        saldoMesChartSel
            .transition()
            .ease(d3.easeExpOut)
            .duration(2000)
            .style("--percentage", saldoMesPercent > 0 ? saldoMesPercent : 0)
    }

    private RefreshAgendaDelDiaRows() {
        const ev_agendacard = d3.select<HTMLDivElement, any>(".card_cal_evs");
        const ev_agendacontainer = d3.select<HTMLDivElement, any>(".card_cal_evs div[container]");
        ev_agendacontainer.selectAll<HTMLDivElement, Entidad.IEventosAgendaDashboard>(":scope>div")
            .data(this.agendaEventosList)
            .join(
                enter => this.RefreshAgendaDelDiaRow(enter.append(() => this.rowAgendaTemplate.cloneNode(true) as HTMLDivElement)),
                update => this.RefreshAgendaDelDiaRow(update),
                exit => exit.remove()
            )
        if (this.agendaEventosList.length)
            this.divAgendaEmptyCard.remove();
        else
            ev_agendacard.append(() => this.divAgendaEmptyCard);
    }

    private RefreshAgendaDelDiaRow(row: TSelectionHTML<"div", Entidad.IEventosAgendaDashboard>) {
        return row.each((d, i, rows) => {
            const dtInicio = new DateV2(new Date(d.Inicio))._SetTimeZoneByIdSchool(this.escuelaSelected.IdKinder);
            const dtFin = new DateV2(new Date(d.Fin))._SetTimeZoneByIdSchool(this.escuelaSelected.IdKinder);
            const elapsedTime = UIUtilTime._GetTimeElapsedAdvanced(dtInicio, dtFin, "Day");
            const isAllDay = (elapsedTime.Hour >= 23 && elapsedTime.Minute >= 59);
            const rowEv = rows[i];
            rowEv.classList.remove("animated-background");
            rowEv.querySelector<HTMLLabelElement>("label[event]").textContent = d.Evento;
            rowEv.querySelector<HTMLLabelElement>("label[event_time]").textContent = isAllDay ? _L("calendar.fullday") : UIUtilTime._DateFormatStandar(dtInicio, "h12:mm");
        })
    }

    private RefreshFinanzasInfoAnio() {
        const totalIngresosAnioCount = this.dashboardData.IngresosAnioCount + this.dashboardData.PagosPorRecibirAnioCount;
        const pagosRecibidosAnioPercent = !totalIngresosAnioCount ? 0 : this.dashboardData.IngresosAnioCount * 100 / totalIngresosAnioCount;
        const totalEgresosAnioCount = this.dashboardData.EgresosAnioCount + this.dashboardData.PagosPorRealizarAnioCount;
        const pagosRealizadosAnioPercent = !totalEgresosAnioCount ? 0 : this.dashboardData.EgresosAnioCount * 100 / totalEgresosAnioCount;
        const saldoAnio = this.dashboardData.IngresosAnioSum - this.dashboardData.EgresosAnioSum;
        const saldoAnioPercent = !this.dashboardData.IngresosAnioSum ? 0 : saldoAnio * 100 / this.dashboardData.IngresosAnioSum;

        const finanzasAnioContainerSel = d3.select<HTMLDivElement, any>(".card_finanza1.anio div[container]");
        const ingresosAnioSel = finanzasAnioContainerSel.select("div[lbl_ingreso]");
        const ingresosAnioAmountSel = ingresosAnioSel.select<HTMLSpanElement>("span[val]");
        this.InterpolateNumbers(ingresosAnioAmountSel.node(), this.dashboardData.IngresosAnioSum, (d) => UIUtilFormat._CurrencyFmt(d), d => this.UnFormatCurrencyTemp(d));
        const ingresosAnioChartSel = ingresosAnioSel.select("div[semi_donut_chart]");
        ingresosAnioChartSel
            .transition()
            .ease(d3.easeExpOut)
            .duration(2000)
            .style("--percentage", pagosRecibidosAnioPercent > 0 ? pagosRecibidosAnioPercent : 0);
        const egresosAnioSel = finanzasAnioContainerSel.select("div[lbl_egreso]");
        const egresosAnioAmountSel = egresosAnioSel.select<HTMLSpanElement>("span[val]");
        this.InterpolateNumbers(egresosAnioAmountSel.node(), this.dashboardData.EgresosAnioSum, (d) => UIUtilFormat._CurrencyFmt(d), d => this.UnFormatCurrencyTemp(d));
        const egresosAnioChartSel = egresosAnioSel.select("div[semi_donut_chart]");
        egresosAnioChartSel
            .transition()
            .ease(d3.easeExpOut)
            .duration(2000)
            .style("--percentage", pagosRealizadosAnioPercent > 0 ? pagosRealizadosAnioPercent : 0);
        const saldoAnioSel = finanzasAnioContainerSel.select("div[lbl_saldo]");
        const saldoAnioAmountSel = saldoAnioSel.select<HTMLSpanElement>("span[val]");
        this.InterpolateNumbers(saldoAnioAmountSel.node(), saldoAnio, (d) => UIUtilFormat._CurrencyFmt(d), d => this.UnFormatCurrencyTemp(d));
        const saldoAnioChartSel = saldoAnioSel.select("div[semi_donut_chart]");
        saldoAnioChartSel
            .transition()
            .ease(d3.easeExpOut)
            .duration(2000)
            .style("--percentage", saldoAnioPercent > 0 ? saldoAnioPercent : 0);
    }

    private RefreshCumplesRows() {
        const cumplesCard = d3.select<HTMLDivElement, any>(".card_cal_birthdays");
        const cumplesContainer = d3.select<HTMLDivElement, any>(".card_cal_birthdays div[container]");
        cumplesContainer.selectAll<HTMLDivElement, IAlumnoCumple>(":scope>div")
            .data(this.alumnosCumplesList)
            .join(
                enter => this.RefreshCumplesRow(enter.append(() => this.rowCumpleAlumnoTemplate.cloneNode(true) as HTMLDivElement)),
                update => this.RefreshCumplesRow(update),
                exit => exit.remove()
            )
        if (this.alumnosCumplesList.length)
            this.divAlumnoCumpleEmptyCard.remove();
        else
            cumplesCard.append(() => this.divAlumnoCumpleEmptyCard);
    }

    private RefreshCumplesRow(row: TSelectionHTML<"div", IAlumnoCumple>) {
        return row.each((d, i, rows) => {
            const rowCumple = rows[i];
            const dtAux = new DateV2(new Date())._SetTimeZoneByIdSchool(this.escuelaSelected.IdKinder);
            const dtCumple = new DateV2(new Date(d.FechaNacimiento))._SetTimeZoneByIdSchool(this.escuelaSelected.IdKinder);
            const edadAlumno = UIUtilTime._GetTimeElapsedAdvanced(dtCumple, dtAux).Year;
            const cumpleDt_fmt = UIUtilTime._DateFormatStandar(dtCumple, "d MMM");
            rowCumple.classList.remove("animated-background");
            rowCumple.querySelector<HTMLLabelElement>("label[nombre]").textContent = d.NombreCompleto;
            rowCumple.querySelector<HTMLLabelElement>("label[edad]").textContent = `${edadAlumno} ${_L("time.anios").toLowerCase()}`;
            rowCumple.querySelector<HTMLLabelElement>("label[dtcumple]").textContent = cumpleDt_fmt;
        })
    }

    private RefreshIngresosMesInfo() {
        const PagosRecibidosMesSel = d3.select<HTMLSpanElement, any>(".card_finanza2.income div[income] span[val]").node();
        this.InterpolateNumbers(PagosRecibidosMesSel, this.dashboardData.IngresosMesCount);
        const PagosPendientesMesSel = d3.select<HTMLSpanElement, any>(".card_finanza2.income div[pending] span[val]").node();
        this.InterpolateNumbers(PagosPendientesMesSel, this.dashboardData.PagosPorRecibirMesCount);
        const AlumnosPagosPendientesMesSel = d3.select<HTMLSpanElement, any>(".card_finanza2 div[student_pending] span[val]").node();
        this.InterpolateNumbers(AlumnosPagosPendientesMesSel, this.dashboardData.PagosPorRecibirMesAlumnosCount);
    }

    private RefreshEgresosMesInfo() {
        const PagosRecibidosAnioSel = d3.select<HTMLSpanElement, any>(".card_finanza2.outcome div[outcome] span[val]").node();
        this.InterpolateNumbers(PagosRecibidosAnioSel, this.dashboardData.EgresosMesCount);
        const PagosPendientesAnioSel = d3.select<HTMLSpanElement, any>(".card_finanza2.outcome div[pending] span[val]").node();
        this.InterpolateNumbers(PagosPendientesAnioSel, this.dashboardData.PagosPorRealizarMesCount);
    }

    private RefreshFinanzasAnioCharts() {
        const month = this.currentDate.getMonth();
        this.finanzasAnioList = this.finanzasAnioList.filter((d, i) => i <= month);
        const highestAmountPerMonth: number[] = [];
        this.finanzasAnioList.forEach(d => {
            highestAmountPerMonth.push([d.Ingresos.Sum, d.Egresos.Sum].sort((a, b) => b - a)[0]);
        })
        highestAmountPerMonth.sort((a, b) => b - a);
        const highestAmountInYear = highestAmountPerMonth[0];
        const divs = this.ObtenerChartsDivs(highestAmountInYear);
        this.RefreshAxisItems(divs);
        this.RefreshMonthsAxisItems();
        this.RefreshCharts();
    }

    private RefreshAxisItems(divisors: number[]) {
        const amountAxis = d3.select<HTMLDivElement, any>(".card_finanzas_chart div[container] .amount_axis_label");
        amountAxis.selectAll<HTMLSpanElement, number>(":scope>span")
            .data(divisors)
            .join(
                enter => this.RefreshAxisItem(enter.append(() => this.labelAxisTemplate.cloneNode(true) as HTMLSpanElement)),
                update => this.RefreshAxisItem(update),
                exit => exit.remove()
            )
    }

    private RefreshAxisItem(item: TSelectionHTML<"span", number>) {
        return item.each((d, i, items) => {
            const itemAxis = items[i];
            itemAxis.classList.remove("animated-background");
            itemAxis.textContent = UIUtilFormat._CurrencyFmt(d);
        })
    }

    private RefreshMonthsAxisItems() {
        const monthAxis = d3.select<HTMLDivElement, any>(".card_finanzas_chart div[container] .axis_months");
        monthAxis.selectAll<HTMLDivElement, IFinanzasPerMonth>(":scope>div")
            .data(this.finanzasAnioList)
            .join(
                enter => this.RefreshMonhtsAxisItem(enter.append(() => this.monthAxisLabelTemplate.cloneNode(true) as HTMLDivElement)),
                update => this.RefreshMonhtsAxisItem(update),
                exit => exit.remove()
            )
    }

    private RefreshMonhtsAxisItem(item: TSelectionHTML<"div", IFinanzasPerMonth>) {
        return item.each((d, i, items) => {
            const itemMonth = items[i];
            itemMonth.classList.remove("animated-background");
            itemMonth.textContent = d.MonthShortName;
        })
    }

    private RefreshCharts() {
        const charts_container = d3.select<HTMLDivElement, any>(".card_finanzas_chart div[container] .charts_container")
        let tooltip = d3.select(this.windowContent.node().parentElement).select<HTMLTooltipComponent>("wc-tooltip[charts]").node();
        if (!tooltip) {
            tooltip = d3.select(this.windowContent.node().parentElement).append<HTMLTooltipComponent>("wc-tooltip")
                .attr("max-width", "250px")
                .attr("charts", "")
                .attr("position", "cursor")
                .node()
        }
        charts_container.selectAll(":scope>div").classed("animated-background", false);
        charts_container.selectAll<HTMLDivElement, IFinanzasPerMonth>(":scope>div")
            .data(this.finanzasAnioList)
            .join(
                enter => this.RefreshChartsItem(enter.append(() => this.chartsGroupTemplate.cloneNode(true) as HTMLDivElement), tooltip),
                update => this.RefreshChartsItem(update, tooltip),
                exit => exit.remove()
            )
        this.RefreshChartLine();
    }

    private RefreshChartsItem(item: TSelectionHTML<"div", IFinanzasPerMonth>, tooltip: HTMLTooltipComponent) {
        const offsetHeight = 10;
        const circleRadius = 8;
        const getHeightValue = (amount: number) => {
            if (!amount || !AmountHundrendPercent) return 0;
            const heightRelative = amount * widthChartsContainer / AmountHundrendPercent;
            return (heightRelative > 0 && heightRelative < 3) ? 3 : heightRelative;
        }
        const widthChartsContainer = 400;
        const AmountHundrendPercent = this.chartMaxHeigthValue;
        return item.each((d, i, items) => {
            const itemCharts = items[i];
            const incomeHeight = getHeightValue(d.Ingresos.Sum);
            const outcomeHeight = getHeightValue(d.Egresos.Sum);
            const isValueZero = (val: number) => val <= 0;

            d3.select(itemCharts).select("div[ingreso]>div")
                .style("width", (circleRadius * 2) + "px")
                .style("height", (circleRadius * 2) + "px")
                .transition()
                .ease(d3.easeLinear)
                .duration(250)
                .style("bottom", (incomeHeight + offsetHeight - circleRadius) + "px");
            d3.select(itemCharts).select("div[ingreso]>div")
                .classed("zero", isValueZero(incomeHeight))

            d3.select(itemCharts).select("div[egreso]>div")
                .style("width", (circleRadius * 2) + "px")
                .style("height", (circleRadius * 2) + "px")
                .transition()
                .ease(d3.easeLinear)
                .duration(250)
                .style("bottom", (outcomeHeight + offsetHeight - circleRadius) + "px");
            d3.select(itemCharts).select("div[egreso]>div")
                .classed("zero", isValueZero(outcomeHeight))

            const tooltipContentIngreso = this.tooltipChartTemplate.cloneNode(true) as HTMLDivElement;
            const tooltipContentEgreso = this.tooltipChartTemplate.cloneNode(true) as HTMLDivElement;
            tooltipContentIngreso.classList.add("income");
            tooltipContentEgreso.classList.add("outcome");
            tooltipContentIngreso.querySelector("label[time]").textContent = `${d.MonthName} ${this.currentDate.getFullYear()}`;
            tooltipContentIngreso.querySelector("label[amount]").textContent = UIUtilFormat._CurrencyFmt(d.Ingresos.Sum);
            tooltipContentEgreso.querySelector("label[time]").textContent = `${d.MonthName} ${this.currentDate.getFullYear()}`;
            tooltipContentEgreso.querySelector("label[amount]").textContent = UIUtilFormat._CurrencyFmt(d.Egresos.Sum);

            tooltip._Position = "top"
            tooltip._SetObservedElementsAdvanced(
                {
                    Target: itemCharts.querySelector("div[ingreso]"),
                    FocusTarget: itemCharts.querySelector("div[ingreso]>div"),
                    HTMLBasic: tooltipContentIngreso.outerHTML,
                },
                {
                    Target: itemCharts.querySelector("div[egreso]"),
                    FocusTarget: itemCharts.querySelector("div[egreso]>div"),
                    HTMLBasic: tooltipContentEgreso.outerHTML
                }
            );
        });
    }

    private RefreshChartLine() {
        const charts_container = d3.select<HTMLDivElement, any>(".card_finanzas_chart div[container] .charts_container");
        const widthAreaChart = charts_container.node().getBoundingClientRect().width;
        const svg_VP_width = widthAreaChart;
        const svg_VP_height = 400;
        let svgLineChart = d3.select<SVGElement, any>(".card_finanzas_chart div[container] >.chart_line >svg[line_chart]");
        const divChartWidth = (svg_VP_width / this.finanzasAnioList.length) / 2;

        const scaleXIncome = d3.scaleLinear()
            .domain([0, this.finanzasAnioList.length - 1])
            .range([divChartWidth / 2, svg_VP_width - (divChartWidth * 1.5)]);

        const scaleXOutcome = d3.scaleLinear()
            .domain([0, this.finanzasAnioList.length - 1])
            .range([divChartWidth * 1.5, svg_VP_width - (divChartWidth / 2)]);

        const scaleY = d3.scaleLinear()
            .domain([0, this.chartMaxHeigthValue])
            .range([svg_VP_height, 0]);

        const lineIncome = d3.line<IFinanzasPerMonth>()
            .x((d, i) => scaleXIncome(i)!)
            .y(d => scaleY(d.Ingresos.Sum));

        const lineOutcome = d3.line<IFinanzasPerMonth>()
            .x((d, i) => scaleXOutcome(i)!)
            .y(d => scaleY(d.Egresos.Sum));

        lineIncome.curve(d3.curveMonotoneX)
        lineOutcome.curve(d3.curveMonotoneX)

        svgLineChart.select("path[ingresos]")
            .datum(this.finanzasAnioList)
            .transition()
            .ease(d3.easeLinear)
            .duration(250)
            .attr("d", lineIncome(this.finanzasAnioList))

        svgLineChart.select("path[egresos]")
            .datum(this.finanzasAnioList)
            .transition()
            .ease(d3.easeLinear)
            .duration(250)
            .attr("d", lineOutcome(this.finanzasAnioList))
    }

    private InterpolateNumbers(element: HTMLSpanElement, endNumber: number, format?: (d: number) => string, unFormat?: (d: string) => number) {
        const durationMs = 700
        const startNumber = (() => {
            const textElement = element.textContent.trim()
            let n = unFormat ? unFormat(textElement) : Number(textElement);
            if (isNaN(n)) return 0
            return n
        })()

        const i = d3.interpolate(startNumber, endNumber)
        return d3.select(element as HTMLSpanElement)
            .transition()
            .ease(d3.easeCircleOut)
            .duration(durationMs)
            .tween("text", function (d) {
                return function (t) {
                    const v = Math.round(i(t))
                    return element.textContent = format ? format(v) : v.toString()
                };
            })
            .end()
    }

    private UnFormatCurrencyTemp(numberFmt: string): number {
        return Number(numberFmt.replaceAll("$", "").replaceAll(",", ""));
    }

    private ObtenerChartsDivs(maxAmount: number) {
        if (maxAmount <= 0) {
            this.chartMaxHeigthValue = 11;
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reverse();
        }

        const magnitude = Math.pow(10, Math.floor(Math.log10(maxAmount)));
        const numShort = maxAmount / magnitude;
        const numShortRound = Math.floor(numShort);

        let decimal = numShort - numShortRound;
        if (decimal > 0.5) maxAmount = (numShortRound + 1) * magnitude;
        else maxAmount = (numShortRound + 0.5) * magnitude;

        const chartsDivs: number[] = [0];
        let baseStep = (maxAmount / 10);
        for (let i = 1; i <= 10; i++) { chartsDivs.push(i * baseStep); }
        chartsDivs.reverse();
        this.chartMaxHeigthValue = chartsDivs[0] + baseStep; // NOTE 
        return chartsDivs;
    }

    private ActiveDevelopmentUtils(element: HTMLElement) {
        const REQUIRED_CLICKS = 7;
        const TIMELAPSE = 200;
        let clicks = 0;
        let timeout: NodeJS.Timeout;

        element.addEventListener("click", () => {
            if (d3.select("body").classed("devutil")) {
                Global._DEVELOPMENT_UTILS = false;
                d3.select("body").classed("devutil", Global._DEVELOPMENT_UTILS);
                DataUtil._RefreshConsoleMode();
                console.debug("DEVELOPMENT_UTILS: %c OFF ", "background-color: orangered; color: #FFF;");
                return;
            }
            clicks++;
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                timeout = null;
                if (clicks == REQUIRED_CLICKS) {
                    Global._DEVELOPMENT_UTILS = true;
                    d3.select("body").classed("devutil", Global._DEVELOPMENT_UTILS);
                    DataUtil._RefreshConsoleMode();
                    console.debug("DEVELOPMENT_UTILS: %c ON ", "background-color: greenyellow; color: #000;");
                }
                clicks = 0;
            }, TIMELAPSE);
        })
    }

    public _Destroy(): void {
        super._Destroy();
        clearInterval(this["_DashboardMonitor"]);
        d3.select(this.windowContent.node().parentElement).select<HTMLTooltipComponent>("wc-tooltip[evs]");
        d3.select(this.windowContent.node().parentElement).select<HTMLTooltipComponent>("wc-tooltip[charts]");
        this["_DashboardMonitor"] = null;

        this.reziseObserver?.unobserve(document.body);
    }
}