import { DateV2 } from "../../util/DateV2";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtilDevice } from "./Device";
import { DataUtil } from "./Util";

export namespace DataUtilAlertBot {
    const BASEURL = "https://api.telegram.org/bot";
    const APITOKEN = import.meta.env.APP_TELEGRAMBOT_TOKEN;
    const BOTURL = BASEURL + APITOKEN + "/";
    const CHATID = import.meta.env.APP_TELEGRAMBOT_CHATID;
    const DEV_TIMEZONE = "America/Monterrey";
    const MAXMESSAGELENGHT = 4000; // 4096;
    const DAYS_WITHOUT_NOTIFICATION = [0];
    const MIN_NOTIFICATION_HOUR = 6;
    const MAX_NOTIFICATION_HOUR = 21;
    let lastSesionUser: Entidad.IUsuarioSesion

    const TTypes = {
        "error": "☠️",
        "warn": "⚠️",
        "info": "ℹ️",
    }
    type TMessageType = keyof typeof TTypes;

    setTimeout(() => {
        RefreshSesionUserData()
    }, 1000);

    export function _SendInfo(title: string, message?: string, source?: string) {
        let extraMsg = ""
        if (message)
            extraMsg += `<b>Message: </b> <code>${GetFixedHtmlString(message)}</code>\n`;

        if (source)
            extraMsg += `<b>Source: </b>\n <code>${GetFixedHtmlString(source)}</code>`;

        SendMessageFinal(title, extraMsg, "info");
    }

    export function _SendWarn(title: string, message?: string, source?: string) {
        let extraMsg = ""
        if (message)
            extraMsg = `<b>Message: </b> <code>${GetFixedHtmlString(message)}</code>\n`;

        if (source) {
            extraMsg += `<b>Source: </b>\n <code>${GetFixedHtmlString(source)}</code>`;
        }

        SendMessageFinal(title, extraMsg, "warn");
    }

    export async function _SendError(error: Error, description: string = "") {
        // let hash = location.hash.replace("#", "");
        // `<b>Current location: </b> <a href="${location.href}">location.href</a>`
        // `<b>Name: </b> <code>${GetFixedHtmlString(error.name)}</code>\n`
        // `<b>Message: </b> <code>${GetFixedHtmlString(error.message)}</code>\n`
        let extraMsg = ``
        if (description?.trim()) {
            extraMsg += ">> " + GetFixedHtmlString(description) + "\n"
        }
        if (error?.name) {
            extraMsg += `<b>name: </b><code>${GetFixedHtmlString(error.name)}</code>\n`
        }
        if (error?.message) {
            extraMsg += `<b>message: </b><code>${GetFixedHtmlString(error.message)}</code>\n`
        }
        if (error?.stack) {
            extraMsg += `<b>stack: </b> \n<code>${GetFixedHtmlString(error.stack)}</code>\n`
        }
        SendMessageFinal("", extraMsg, "error");
    }

    let msgs300ms: [string, string, string, number, Date][] = [];
    let timeout: NodeJS.Timeout;
    export function _SendInfoDelay300ms(title: string, message: any, source?: string) {
        let lastMsg = msgs300ms[msgs300ms.length - 1];
        let lastIndex = (lastMsg ? lastMsg[3] : 0);

        msgs300ms.push([title, message, source, (lastIndex + 1), new Date()]);

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            let map = new Map();

            msgs300ms
                .forEach((args) => {
                    let a_title = args[0];
                    let a_mess = args[1];
                    let a_source = args[2];
                    let a_index = args[3];
                    let a_dt = args[4];

                    let a_msg = map.get(a_title);

                    if (a_msg) {
                        a_msg += `\n👾\n\n`
                            + `Index: ${a_index}\n`
                            + `Message: \n${a_mess}\n`
                            + `${a_source ? ("Source: \n" + a_source) + "\n" : ""}`
                            + `Date: ${a_dt.toISOString()}`;
                    } else {
                        a_msg = "\n"
                            + `Index: ${a_index}\n`
                            + `Message: \n${a_mess}\n`
                            + `${a_source ? ("Source: \n" + a_source) + "\n" : ""}`
                            + `Date: ${a_dt.toISOString()}`
                    }

                    map.set(title, a_msg);
                });

            map.forEach((message, title) => {
                _SendInfo(title, message);
            });
            map.clear();

            timeout = null;
        }, 300);;
    }

    // ********************
    // Final
    // ********************

    function SendMessageFinal(title: string, extraMsg: string, typeMess: TMessageType): Promise<number> {
        RefreshSesionUserData()
        return new Promise<number>(async (resolve) => {
            if (location.protocol == "http:") {
                resolve(-2)
                return;
            }
            try {
                const url = BOTURL + "sendMessage";
                const localDate = new Date();
                const timeZoneOffset = -(localDate.getTimezoneOffset() / 60);

                // Message
                let host = GetFixedHtmlString(location.href);
                let strMsg =
                    `#kidiadmin_${typeMess} ${TTypes[typeMess]}\n`
                    + `<b>From: </b> <code>${host}</code> \n`
                    + `<b>Version: </b> <code>${Global._APP_VERSION_NAME}</code>/<code>${Global._APP_VERSION_BUILD}</code>\n`
                    + `<b>Date: </b> <code>${localDate.toISOString()} (${timeZoneOffset})</code>`

                if (lastSesionUser) {
                    let idSesion: number = lastSesionUser.IdSesion
                    if (!DataUtil._Usuario?.__Entity) {
                        idSesion *= -1
                    }
                    strMsg += `\n<b>Sesion: </b> <code>${idSesion}</code>`
                        + `\n<b>User: </b> <code>${lastSesionUser.IdUsuario}</code>`;
                }
                strMsg += "\n" + DataUtilDevice._GetUserAgent() + " (" + navigator.platform + ")";

                strMsg += "\n\n";

                if (title)
                    strMsg += `<b>» ${GetFixedHtmlString(title)}</b>\n`
                        + extraMsg;
                else
                    strMsg += extraMsg;

                if (strMsg.length > MAXMESSAGELENGHT) {
                    strMsg = strMsg.substring(0, MAXMESSAGELENGHT - 3) + "...";
                }

                // Mensajes silenciosos
                let disableNotification = false;
                const currentDate = new DateV2()._SetTimeZone(DEV_TIMEZONE);
                const diaInactivo = DAYS_WITHOUT_NOTIFICATION.indexOf(currentDate.getDay());
                if (diaInactivo > -1 || currentDate.getHours() < MIN_NOTIFICATION_HOUR || currentDate.getHours() > MAX_NOTIFICATION_HOUR)
                    disableNotification = true;

                const params = {
                    "chat_id": CHATID,
                    "parse_mode": "html",
                    "text": strMsg.trim(),
                    "disable_notification": disableNotification,
                }

                _Request(url, "POST", params, (res: any) => {
                    if (res?.ok && res.result.message_id) {
                        // console.warn("AlertBot >> ", res, params);
                        resolve(res.result.message_id);
                    } else {
                        resolve(-1);
                    }
                });
            }
            catch (e) {
                console.error("AlertBot >>", e);
                resolve(-1);
            }
        });
    }

    // // @ts-ignore
    // function GetFixedMarkdownString(str: string) {
    //     let fixs = ['_', '*', '[', ']', '(', ')', '~', '`', '<', '>', '#', '+', ' -', '=', '|', '{', '}', '.', '!'];
    //     fixs.forEach((v) => {
    //         let vF = ('\ ' + v + "")//.replace(" ", "");
    //         str = str.split(v)
    //             .join(vF);
    //     });
    //     return str;
    // }

    function RefreshSesionUserData() {
        if (!DataUtil._Usuario?.__Entity || lastSesionUser?.IdSesion == DataUtil._Usuario.__Entity.IdSesion) {
            return
        }
        lastSesionUser = DataUtil._Usuario.__Entity
    }

    function GetFixedHtmlString(str: string) {
        let fixs = new Map<string, string>();
        fixs.set('&', '&amp;');
        fixs.set('#', '&#35;');
        fixs.set('<', '&lt;');
        fixs.set('>', '&gt;');
        fixs.set('"', '&#34;');
        fixs.set('!', '&#33;');

        if (str) fixs.forEach((v, k) => {
            str = str?.split(k).join(v) || "";
        });

        return str;
    }

    interface IMetodos {
        "POST": string;
        "GET": string;
    }
    type KMetodos = keyof IMetodos;
    type RequestCallback<TResult> = (result: TResult, response: Response, error: Error) => void;

    function ParamsGET(params: any): string {
        var concatStr = "?";
        var i = 0;
        for (var k in params) {
            if (i > 0)
                concatStr += "&";

            concatStr += k + "=" + params[k];
            i++;
        }

        return encodeURI(concatStr);
    }

    export function _Request<TResponse = Object>(requestURL: string, method: KMetodos, params: Object, callback: RequestCallback<TResponse>) {
        if (method == 'GET' && params != null)
            requestURL += ParamsGET(params);

        var request = new Request(requestURL, {
            method: method,
            body: method == "POST" ? JSON.stringify(params) : null,
            cache: "no-cache",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });

        fetch(request)
            .then(async (response) => {
                if (!response.ok) {
                    callback(null, response, null);
                }
                else {
                    let result = await response.json();
                    if (result) {
                        callback(result, null, null);
                    } else {
                        console.warn("Result response null", response);
                    }
                }
            })
            .catch((e: Error) => {
                callback(null, null, e);
                if (e.message != "Failed to fetch")
                    console.error(e.stack);
            });
    }
}
