import { DataDRequest } from "../../data/DRequest";
import { Global } from "../../data/Global";
import { CLicenciaFormaPago, ILicenciaEstado } from "../../data/entidad/LicenciaOld";
import { _LOCALDATA_GetURLFile_SPEIReferencePay, _LOCALDATA_GetURLFile_StoreReferencePay, _SvLicenciaCardPayAdd3DCharge, _SvLicenciaCardPayAddCharge, _SvLicenciaGenerarFolio, _SvLicenciaPaystoreGenerarReferencia, _SvLicenciaTransferGenerarReferenciaSPEI } from "../../data/modulo/LicenciaOld";
import { _SvOpenPayCardTokenCreate } from "../../data/modulo/OpenPay";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { Button } from "../controlD3/Button";
import { FormGenerator } from "../controlD3/Formulario";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { UIUtilFormat } from "../util/Format";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilMask } from "../util/Mask";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";

export function _OpenModal_ProcesoPago(idEscuela: number, totalPagar: number, licencias: ILicenciaEstado[], alumnosN: number) {
    const p_3DPayAvailable = (totalPagar >= Global._GLOBAL_CONF.IncOpenPay_AmountPay3D);
    let p_formaPago: CLicenciaFormaPago;
    let p_formUserData: FormGenerator<IUserData>;
    let p_formCardData: FormGenerator<ICardData>;
    let p_deviceSessionId: string;
    let p_tokenCard: OpenPay.IToken;
    let p_finalURL: string;
    let p_folioRes = {
        Id: -1,
        Folio: ""
    }
    let p_resultCode = 0;

    const fnFinProceso = (resolve: (res: DataDRequest.IRequestResponseA) => void) => {
        resolve(p_resultCode
            ? { Resultado: p_resultCode }
            : null)
        if (p_formaPago == CLicenciaFormaPago.Tarjeta && p_resultCode == 1) {
            NotificacionV2._Mostrar(_L("licencia.pago_exitoso"), "INFO");
        }
        return null;
    }

    return new Promise<DataDRequest.IRequestResponseA<undefined>>((resolve, reject) => {
        // let modalT =
        ModalThings._GetModalToALongProccess({
            // LangModuleKeyInContext: this.labelsKeyBase,
            OnClose: () => fnFinProceso(resolve),
            StartEndIds: ["licenciapay_costumerdata", "licenciapay_end"],
            StepsConfig: [
                // >> [1️] DATOS DE USUARIO
                {
                    Id: "licenciapay_costumerdata",
                    Title: _L("licencia.tag_userdata"),
                    NextID: "licenciapay_payform",
                    Width: 400,
                    OnDrawContent: (content, mt) => {
                        p_formUserData = GetForm_UserData();
                        content.append(() => p_formUserData._Form.node());
                    },
                    OnFocusContent: (content, mt) => {
                        Button._EnableButton(mt.BtnRight, true);
                    },
                    OnValideStep: () => p_formUserData._GetIsValidForm()
                },
                // >> [2️] FORMA DE PAGO
                {
                    Id: "licenciapay_payform",
                    Title: _L("licencia.tag_payform"),
                    PreviousID: "licenciapay_costumerdata",
                    Width: 400,
                    NextID: () => (p_formaPago == CLicenciaFormaPago.Tarjeta) ? "licenciapay_carddata" : "licenciapay_acceptpay",
                    OnDrawContent: (content, mt) => {
                        mt.BtnRight._Enable(false)

                        content
                            .classed(UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.CenterCenter, true)
                            .style("column-gap", "25px")

                        const fnCreateOptionBtn = (labelText: string, payForm: CLicenciaFormaPago, icon: UIUtilIconResources.Payment) => {
                            let payFormOption = content.append("div").classed("payform", true)
                                .on("click", () => {
                                    content.selectAll(":scope > div").classed("selected", false);
                                    payFormOption.classed("selected", true);
                                    p_formaPago = payForm;
                                    mt.BtnRight._Enable(true);
                                });
                            payFormOption.append("div")
                                .classed("img shadow1 border_rad", true)
                                .style("background-image", `url(${icon})`)
                            payFormOption.append("label")
                                .text(labelText);
                        }

                        fnCreateOptionBtn(_L("licencia.formpay_card"), CLicenciaFormaPago.Tarjeta, UIUtilIconResources.Payment.Card);
                        fnCreateOptionBtn(_L("licencia.formpay_transfer"), CLicenciaFormaPago.Transferencia, UIUtilIconResources.Payment.Transfer);
                        fnCreateOptionBtn(_L("licencia.formpay_cash"), CLicenciaFormaPago.Efectivo, UIUtilIconResources.Payment.MoneyBill);
                    },
                    OnValideStep: (content, mt) => {
                        let res = Boolean(p_formaPago);
                        if (!res) {
                            NotificacionV2._Mostrar(_L("licencia.notif_frmpagoreq"), "REQUIRED");
                        }
                        return res;
                    }
                },
                // >> [2.1] DATOS DE TARJETA
                {
                    Id: "licenciapay_carddata",
                    Title: _L("licencia.card_data"),
                    PreviousID: "licenciapay_payform",
                    NextID: "licenciapay_acceptpay",
                    Width: 600,
                    OnDrawContent: (content, mt) => {
                        p_formCardData = GetForm_CardData();
                        content.append(() => p_formCardData._Form.node());
                    },
                    OnFocusContent: () => {
                        p_folioRes.Id = -1; // Se resetea el folio para que el paso siguente lo recree
                        p_folioRes.Folio = "";
                        p_finalURL = null;
                    },
                    OnValideStep: (content, mt) => {
                        if (!p_formCardData._GetIsValidForm()) {
                            return false
                        }
                        return new Promise(async (resolve_card) => {
                            DataUtil._BuildOpenPay()
                            p_tokenCard = null;
                            p_deviceSessionId = OpenPay.deviceData.setup("licencias-payment-form", "deviceDataId");
                            // console.warn("Devide session id generada: ", p_deviceSessionId);
                            if (!p_deviceSessionId) {
                                resolve_card({
                                    IsValid: false,
                                    Message: UIUtilLang._GetHTTPMessage({ Resultado: -1 })
                                });
                                return;
                            }
                            const tokenRes: DataDRequest.IRequestResponseA<OpenPay.IToken> = await (() => {
                                const cardData = p_formCardData._Data;
                                const cardNumberFixed = cardData.card_number.split(" ").join("")
                                return _SvOpenPayCardTokenCreate(
                                    cardNumberFixed,
                                    cardData.holder_name,
                                    cardData.expiration_year,
                                    cardData.expiration_month,
                                    cardData.cvv2
                                )
                            })()
                            // console.warn("card token generado", tokenRes);
                            if (tokenRes.Resultado <= 0) {
                                resolve_card({
                                    IsValid: false,
                                    Message: UIUtilLang._GetHTTPMessage(tokenRes, "openpay_errors")
                                });
                                return;
                            }
                            p_tokenCard = tokenRes.Datos;
                            resolve_card(true)
                        });
                    }
                },
                // >> [3] CONFIRMACIÓN DE PAGO
                {
                    Id: "licenciapay_acceptpay",
                    Title: _L("licencia.confirmpay"),
                    Width: 350,
                    PreviousID: () => (p_formaPago == CLicenciaFormaPago.Tarjeta) ? "licenciapay_carddata" : "licenciapay_payform",
                    NextID: "licenciapay_end",
                    OnDrawContent: (content, mt) => {
                        content
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                            .style("row-gap", "5px");
                    },
                    OnFocusContent: (content, mt) => {
                        let htmlContent =
                            `<b>${_L("licencia.tag_userdata").toUpperCase()} (${_L("licencia.tag_cliente").toUpperCase()})</b>
                            <label><b>${_L("licencia.d_field_nombre")}: </b> <span> ${p_formUserData._Data.Nombre}</span></label>
                            <label><b>${_L("licencia.correo")}: </b> <span> ${p_formUserData._Data.Correo}</span></label>
                            <br>
                            <b>${_L("licencia.pay_data").toUpperCase()}</b>
                            <label><b>${_L("licencia.tag_totalalumnos")}: </b> <span> ${alumnosN}</span></label>
                            <label><b>${_L("licencia.tag_totalpago")}: </b> <span style="text-decoration:underline;"> ${UIUtilFormat._CurrencyFmt(totalPagar)}</span></label>
                            <label><b>${_L("licencia.tag_payform")}: </b> <span> ${UIUtilViewData._GetStr_FormaPago(p_formaPago)}</span></label>`;

                        if (p_formaPago == CLicenciaFormaPago.Tarjeta) {
                            console.debug("ES 3D ", p_3DPayAvailable);
                            htmlContent +=
                                `<br>
                                <b>${_L("licencia.card_data").toUpperCase()}</b>
                                <label><b>${_L("licencia.card_holder_name_b")}: </b> <span> ${p_tokenCard.card.holder_name}</span></label>
                                <label><b>${_L("licencia.card_number_b")}: </b> <span> ${UIUtilFormat._CardNumberFmt(p_tokenCard.card.card_number)}</span></label>
                                <label><b>${_L("licencia.card_expiration_dt")}: </b> <span> ${p_tokenCard.card.expiration_month}/${p_tokenCard.card.expiration_year}</span></label>`
                        }
                        content.html(htmlContent);
                        setTimeout(() => {
                            // setTimeout(() => {mt.Modal.met_HabilitarBtns();}, 500);
                            if (p_formaPago == CLicenciaFormaPago.Tarjeta) {
                                mt.BtnRight.text(_L("licencia.tag_pay"));
                            } else {
                                mt.BtnRight.text(_L("licencia.btn_generarref"));
                            }
                        });
                    },
                    OnValideStep: (content, mt) => {
                        return new Promise(async (resolve_pay) => {
                            let finalPayRes: DataDRequest.IRequestResponseA<unknown>;
                            let userData = p_formUserData._Data;
                            p_resultCode = 0;

                            if (p_folioRes.Id <= 0) { // && p_folioRes.Folio != ""
                                let folioRes = await _SvLicenciaGenerarFolio(
                                    idEscuela,
                                    p_formaPago,
                                    licencias.map(d => ({
                                        Id: d.IdLicencia,
                                        IdAlumno: d.IdAlumno,
                                        Monto: d.CostoTotal
                                    }))
                                );

                                console.warn("-d", "Folio generado ", folioRes);
                                if (folioRes.Resultado <= 0) {
                                    let errorMessageFolio = UIUtilLang._GetHTTPMessage(folioRes, "licencia_generarfolio");
                                    NotificacionV2._Mostrar(errorMessageFolio, "ADVERTENCIA");
                                    resolve_pay(false);
                                    if (folioRes.Resultado == -1 || folioRes.Resultado == -2) {
                                        // Existe información que no podrá procesarse. Requiere salir del proceso y sincronizar la info de la ventana

                                        ModalThings._GetConfirmacionModal({
                                            Title: _L("licencia.tag_pagosalir"),
                                            Message: _L("licencia.notif_generafolioerror"),
                                            Width: 300,
                                            DrawContent: (content, mtSalir) => {
                                                mtSalir.Modal._BtnCloseSelection.remove();
                                                mtSalir.BtnLeft.remove();
                                                mtSalir.BtnRight.text(_L("licencia.btn_exitandsync"));
                                            },
                                            OnAccept: (mtSalir) => {
                                                resolve({
                                                    Resultado: 1 // Code para cerrar modal principal
                                                });
                                                mt.Modal._Ocultar(); // Cerrar modal de proceso
                                            }
                                        })
                                    }
                                    return null;
                                }
                                p_folioRes.Id = folioRes.Resultado;
                                p_folioRes.Folio = folioRes.Folio;
                            }

                            switch (p_formaPago) {
                                case CLicenciaFormaPago.Tarjeta:

                                    if (p_3DPayAvailable) {
                                        let cardPay3DRes = await _SvLicenciaCardPayAdd3DCharge(p_tokenCard.id, p_deviceSessionId, totalPagar, p_folioRes.Folio, p_folioRes.Id, userData.Correo, userData.Nombre);
                                        finalPayRes = cardPay3DRes;

                                        if (cardPay3DRes.Resultado > 0) {
                                            p_finalURL = cardPay3DRes.Datos.payment_method.url;
                                        }
                                    } else {
                                        let cardPayRes = await _SvLicenciaCardPayAddCharge(p_tokenCard.id, p_deviceSessionId, totalPagar, p_folioRes.Folio, p_folioRes.Id, userData.Correo, userData.Nombre);
                                        finalPayRes = cardPayRes;

                                        if (cardPayRes.Resultado > 0) {
                                            let transactionId = cardPayRes.Datos.id;
                                            p_finalURL = Global._GLOBAL_CONF.URL_RSTLicenciasRoot + `pages/redirect-cardpay/index.php?id=${transactionId}`;
                                        }
                                    }
                                    break;

                                case CLicenciaFormaPago.Transferencia:
                                    let transferSPEIReferenceRes = await _SvLicenciaTransferGenerarReferenciaSPEI(totalPagar, p_folioRes.Folio, p_folioRes.Id, userData.Correo, userData.Nombre);
                                    finalPayRes = transferSPEIReferenceRes;

                                    if (transferSPEIReferenceRes.Resultado > 0) {
                                        let transactionId = transferSPEIReferenceRes.Datos.id;
                                        p_finalURL = _LOCALDATA_GetURLFile_SPEIReferencePay(transactionId);
                                    }
                                    break;

                                case CLicenciaFormaPago.Efectivo:
                                    let storeReferenceRes = await _SvLicenciaPaystoreGenerarReferencia(totalPagar, p_folioRes.Folio, p_folioRes.Id, userData.Correo, userData.Nombre);
                                    finalPayRes = storeReferenceRes;

                                    if (storeReferenceRes.Resultado > 0) {
                                        let reference = storeReferenceRes.Datos.payment_method.reference;
                                        p_finalURL = _LOCALDATA_GetURLFile_StoreReferencePay(reference);
                                    }
                                    break;
                            }

                            let message = "";
                            p_resultCode = finalPayRes.Resultado; // NOTE No aplica con pago3D (Se verifica con resultCode de windowReference)

                            if (finalPayRes.Resultado > 0) {
                                //message = UIUtilLang.fn_GetHTTPMessage({ Resultado: 1 });
                                //NotificacionV2.fn_Mostrar(message, "INFO");
                                resolve_pay(true);
                            } else {
                                mt.BtnRight.text(_L("general.reintentar"));

                                if (finalPayRes.Resultado <= -1000 || finalPayRes.Resultado == -3) { // ERRORES OPENPAY APLICA PARA CARGO CON TARJETA NO 3D
                                    message = UIUtilLang._GetHTTPMessage({ Resultado: finalPayRes.Resultado }, "openpay_errors");
                                    p_folioRes.Id = -1; // Se resetea el folio porque ya ha quedado registrado en opay, requiere crear nuevo al REINTENTAR
                                    p_folioRes.Folio = "";
                                } else {
                                    message = UIUtilLang._GetHTTPMessage(finalPayRes);
                                }
                                NotificacionV2._Mostrar(message, "ADVERTENCIA");
                                resolve_pay(false);
                            }

                        });
                    },
                    // OnAccept: async (mt) => {}
                },
                // >> [4] RESUMEN
                {
                    Id: "licenciapay_end",
                    Title: _L("licencia.payend_title"),
                    Width: 300,
                    PreviousID: "licenciapay_carddata", // Disponible en pago con TARJETA 3D y solo al finalizar la verificación 3D
                    OnFocusContent: (content, mt) => {
                        setTimeout(() => {
                            mt.BtnLeft.classed("hide", true);
                            mt.BtnRight.text(_L("general.salir"));
                            content.style("min-height", "80px");

                            if (!p_finalURL) {
                                content.text(_L("general.notif_fail"));
                                mt.BtnLeft
                                    .classed("hide", false)
                                    .text(_L("general.reintentar"));
                                // mt.Modal.met_HabilitarBtns();
                                DataUtilAlertBot._SendWarn("Modal pay error", "La url no fue obtenida");
                                return;
                            }

                            switch (p_formaPago) {
                                case CLicenciaFormaPago.Tarjeta:
                                    content.text("..."); // Redirigiendo...
                                    mt.Modal._DeshabilitarBtns();
                                    let windowReference: Window;
                                    let timerWinRefObserver: NodeJS.Timeout;

                                    if (p_3DPayAvailable) {
                                        content.text(_L("licencia.tag_3dcheck_pop_up_window"));
                                    }

                                    const fnCloseWinRef = () => {
                                        clearInterval(timerWinRefObserver);
                                        if (p_resultCode == 1) {
                                            mt.Modal._Ocultar(); // Invoca OnAccept (cerrar modal de proceso-pago)
                                        } else {
                                            mt.Modal._HabilitarBtns();
                                        }
                                        windowReference.close();
                                        window.focus();
                                        window.removeEventListener("message", fnEventMessage);
                                    }

                                    let fnEventMessage = (e: MessageEvent) => {
                                        console.warn("-d", "MESSAGE RECIVED", e.data);
                                        if (e.data.closed) {
                                            fnCloseWinRef();
                                        } else if (e.data.resultCode != null) {
                                            if (p_3DPayAvailable) {
                                                p_resultCode = e.data.resultCode; // resultCode de pago3D Toma valor solo cuando se redirige al resumen de pago (voucher)
                                            }
                                            let messageResult: string;
                                            if (p_resultCode == 1) {
                                                messageResult = _L("licencia.tag_close_pop_up_window");
                                            } else {
                                                if (p_resultCode > 0) {
                                                    p_resultCode = -p_resultCode;
                                                }
                                                messageResult = UIUtilLang._GetHTTPMessage({ Resultado: p_resultCode }, "openpay_errors");
                                                mt.BtnLeft
                                                    .classed("hide", false)
                                                    .text(_L("general.reintentar"));
                                            }
                                            content.text(messageResult);
                                        }
                                    }

                                    let fnWinRefStartObserver = () => {
                                        timerWinRefObserver = setInterval(() => { // // NOTE WindowReference.onclose no se puede capturar
                                            if (!windowReference || windowReference.closed) {
                                                fnCloseWinRef();
                                            }
                                        }, 1000);

                                        window.addEventListener("message", fnEventMessage);
                                    }

                                    // >> Redirige al usuario a la ventana de Voucher o Verificacion3D, para continuar,
                                    // Modo: Auto
                                    windowReference = window.open(p_finalURL, "_blank");
                                    if (windowReference && !windowReference.closed) {
                                        fnWinRefStartObserver();
                                        // p_resultCode = 1;
                                    } else {
                                        let linkTag = "";
                                        if (p_3DPayAvailable) {
                                            linkTag = _L("licencia.tag_3dcheck_continuar");
                                        } else {
                                            linkTag = _L("licencia.tag_cardpay_abrirvoucher");
                                        }
                                        content.html(`<label class="link_item"> ${linkTag}</label>`);
                                        content.select<HTMLAnchorElement>("label")
                                            .node()
                                            .onclick = () => {
                                                windowReference = window.open(p_finalURL, "_blank");
                                                if (windowReference && !windowReference.closed) {
                                                    // Modo: Interacción de usuario
                                                    fnWinRefStartObserver();
                                                } else {
                                                    // Modo: No controlado
                                                    DataUtilAlertBot._SendInfo("Window auto-open fail", "Don't allow pop-ups or use redirects");
                                                    // NOTE KidiAdmin pierde el control de las acciones del usuario
                                                    content.html(`<a class="link_item" href="${p_finalURL}" target="_self"> ${linkTag}</a>`);
                                                }
                                            }
                                    }
                                    break;
                                case CLicenciaFormaPago.Transferencia:
                                case CLicenciaFormaPago.Efectivo:
                                    mt.Modal._ModalSelection.style("width", "250px");
                                    content
                                        .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                        .style("row-gap", "10px")
                                        .append("div")
                                        .html(
                                            `<b>${_L("licencia.tag_payreferencia")}</b><br>
                                            <b>${_L("licencia.tag_folio")}: </b> ${p_folioRes.Folio}`
                                        );
                                    // let filename = "Referencia de pago " + p_folioRes.Folio + ".pdf";

                                    let inputF: InputFileControl.InputFile = new InputFileControl.InputFile({
                                        Dimentions: "120px",
                                        MinDimention: "min-content",
                                        MaxDimention: "100%",
                                        ControlStyle: "foto_control_style2",
                                        ControlForm: InputFileControl.ControlForm.Cuadrado,
                                        ShowBtnLoadFile: false,
                                        ShowBtnDownloadFile: false,
                                    });
                                    inputF._AuxExtensionFile = "pdf";
                                    content.append(() => inputF._ControlNode)
                                        .style("cursor", "pointer")
                                        .on("click", () => {
                                            window.open(p_finalURL, "_blank");
                                        });

                                    window.open(p_finalURL, "_blank");

                                    // Descargar comprobante de pago automáticamente
                                    // Mostrar botón para volver a descargar
                                    break;
                            }
                        });
                    },
                    OnDrawContent: (content, mt) => { },
                    OnAccept: async (mt) => { // "Salir"
                        mt.Modal._Ocultar()
                        return null
                    }
                }
            ]
        });
    });
}

function GetForm_UserData(): FormGenerator<IUserData> {
    const userAdmin = DataUtil._Usuario;
    return new FormGenerator<IUserData>()
        ._Crear({
            LabelMaxWidth: 100,
            schema: [
                { model: "Nombre", type: "input", labelText: _L("licencia.d_field_nombre"), inputAttr: { inputProperties: { required: true } } },
                { model: "Correo", type: "input", labelText: _L("licencia.correo"), inputAttr: { type: "email", inputProperties: { required: true } } },
            ]
        }, <IUserData>{
            Nombre: `${userAdmin.Nombre} ${userAdmin.ApPaterno} ${userAdmin.ApMaterno}`.trim(),
            Correo: userAdmin.Correo.trim()
        })
}

/**
 * NOTE: FormElement id="licencias-payment-form"
 * */
function GetForm_CardData(): FormGenerator<ICardData> {
    let form = new FormGenerator<ICardData>()
        ._Crear({
            schema: [
                {
                    model: "holder_name", type: "input", labelText: _L("licencia.card_holder_name"),
                    inputAttr: { type: "text", maxlength: 50, placeholder: _L("licencia.card_holder_name_placeholder"), inputProperties: { required: true } }
                },
                {
                    model: "card_number", type: "input", labelText: _L("licencia.card_number"),
                    inputAttr: { type: "bank_card", inputProperties: { required: true } }
                },
                {
                    model: "expiration_month", type: "input",
                    inputAttr: { type: "text", maxlength: 2, minlength: 2, size: 2, placeholder: "MM", /* valueAs: "text",*/ inputProperties: { required: true } }
                },
                {
                    model: "expiration_year", type: "input",
                    inputAttr: { type: "text", maxlength: 2, minlength: 2, size: 2, placeholder: "YY", /*valueAs: "text",*/ inputProperties: { required: true } }
                },
                {
                    model: "cvv2", type: "input", labelText: _L("licencia.card_cvv2"),
                    inputAttr: { type: "text", placeholder: "CVV", valueAs: "text", maxlength: 4, minlength: 3, inputProperties: { required: true } }
                }
            ],
            BuildView: (container, controlsForm) => {
                container.classed("form_openpay_data", true)
                    .html(`<input type="hidden" name="deviceDataId" id="deviceDataId">`);

                const AddItem = (model: keyof ICardData) => {
                    let control = controlsForm.get(model);
                    control.selection
                        .classed(model, true);
                    if (control) {
                        let input = <d3.Selection<HTMLInputElement, any, any, any>>control.selection
                            .attr("data-openpay-card", model);

                        if (model == "cvv2" || model == "expiration_month" || model == "expiration_year") {
                            UIUtilGeneral._InputAddKeyPress_OnlyNumber(input);
                            UIUtilGeneral._InputAddKeyPress_NoWhiteSpaces(input);
                        }
                        if (model == "cvv2") {
                            control.row.select(".input_content")
                                .style("width", "30%");
                        }
                        if (model == "holder_name") {
                            UIUtilMask._ApplyUpperCaseMask(input, true, false, false);
                        }
                        if (model == "expiration_month" || model == "expiration_year") {
                            let inputsWrapper = container.select(".inputs_wrapper")
                                .style("width", "30%");

                            if (!inputsWrapper.node()) {
                                let itemAux = container.append("div")
                                    .attr("class", "row_expiracion row");

                                itemAux.append("label")
                                    .attr("class", "titulos_base")
                                    .text(_L("licencia.card_expiration_dt"));

                                inputsWrapper = itemAux.append("div").attr("class", "inputs_wrapper");

                            }
                            inputsWrapper.append(() => input.node());
                        } else {
                            container.append(() => control.row.node());
                        }
                    }
                }

                AddItem("holder_name");
                AddItem("card_number");
                AddItem("expiration_month");
                AddItem("expiration_year");
                AddItem("cvv2");

                container.append("div")
                    .attr("class", "cards")
                    .html(
                        `<div class="credit_cards">${_L("licencia.pay_creditcards")}</div>
                        <div class="debit_cards">${_L("licencia.pay_debitcards")}</div>
                        <div class="openpay_logo">${_L("licencia.pay_viaopay")}</div>`
                    )
            },
            Validation: (value, field, dataForm, controlsForm) => {
                // NOTE Validación básica (// FIXME falta validar decimales?)
                let controlRow = controlsForm.get(field);
                let inputControl = <HTMLInputElement>controlRow.selection.node();

                if (value !== undefined && value !== null && String(value).trim() != "" && inputControl.validity.valid) {
                    let cardNumberFixed = dataForm.card_number.split(" ").join("");
                    switch (field) {
                        case "card_number":
                            if (!OpenPay.card.validateCardNumber(cardNumberFixed)) {
                                NotificacionV2._Mostrar(_L("licencia.notif_cardnumber_invalid"), "ADVERTENCIA");
                                return false;
                            }
                            break;
                        case "expiration_month":
                        case "expiration_year":
                            if (!OpenPay.card.validateExpiry(dataForm.expiration_month, dataForm.expiration_year)) {
                                NotificacionV2._Mostrar(_L("licencia.notif_expirationdate_invalid"), "ADVERTENCIA");
                                return false;
                            }
                            break;
                        case "cvv2":
                            if (!OpenPay.card.validateCVC(dataForm.cvv2, cardNumberFixed)) {
                                NotificacionV2._Mostrar(_L("licencia.notif_cvv2_invalid"), "ADVERTENCIA");
                                return false;
                            }
                            break;
                    }
                    return true;
                }
                return false;
            }
        }, <ICardData>{});

    form._Form.attr("id", "licencias-payment-form");
    return form;
}

interface IUserData {
    Nombre: string;
    Correo: string;
    // FormaPago: CLicenciaFormaPago; // FIXME IMPLEMENTAR O REMOVER
}

interface ICardData {
    card_number: string;
    holder_name: string;
    expiration_year: string;
    expiration_month: string;
    cvv2: string;
}