import { select } from 'd3'
import licenciaPayInfo from '../../cfg/licencia-payinfo.json'
import { Entidad } from '../../data/Entidad'
import { CLicenciaConfigOrganizacionTipoRegistro, ILicenciaConfigOrganizacionDetalleAdeudo } from '../../data/entidad/Licencia'
import { Global } from '../../data/Global'
import { _DiccFullEscuelas } from '../../data/modulo/Escuela'
import { Main } from '../../Main'
import { MainPage } from '../../MainPage'
import _L, { _FixHTMLTags } from '../../util/Labels'
import { _ShowTemporalTooltip } from '../controlD3/TemporalTooltip'
import licenciaViewHTML from '../template/licenciamiento_bloqueo.html?raw'
import { UIUtilElement } from '../util/Element'
import { UIUtilFormat } from '../util/Format'
import { UIUtilIconResources } from '../util/IconResourses'
import { UIUtilStrings } from '../util/Strings'
import { UIUtilTime } from '../util/Time'

export class LicenciamientoBloqueoView {
    private viewElement: HTMLDivElement
    private itemLicTemplate: HTMLDivElement
    private licenciasContElement: HTMLDivElement

    constructor() {
        UIUtilElement._AppendHTML(document.body, _FixHTMLTags(licenciaViewHTML))
        this.viewElement = document.body.querySelector(".licenciamiento_bloqueo") as HTMLDivElement
        const view = this.viewElement

        this.itemLicTemplate = view.querySelector(".item") as HTMLDivElement
        this.licenciasContElement = this.itemLicTemplate.parentElement as HTMLDivElement
        this.itemLicTemplate.remove()

        const btnLogout = view.querySelector(".btn_logout") as HTMLButtonElement
        btnLogout.onclick = () => {
            Main._Logout()
            this.EnableAllButtons(false)
        }

        view.querySelector(".box>pre[subject]").textContent = Object.keys(Global._LICENCIA.DetalleAdeudos).length == 1
            ? _L("licenciamiento_bloqueo.description_singular")
            : _L("licenciamiento_bloqueo.description_plural")

        this.RefreshPayInfo()
        this.RefreshLicenciasItems()

        const callEscListener = () => {
            if (!view.parentElement) {
                MainPage._RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.Escuela, callEscListener)
            }
            this.RefreshLicenciasItems()
        }
        MainPage._AddEventListenerWorkerRequest(Entidad.CTipoRequest.Escuela, callEscListener)
    }

    private RefreshPayInfo() {
        const clabeStr = licenciaPayInfo.clabe
        const clabeElement = this.viewElement.querySelector<HTMLElement>("*[pago_clabe]")
        clabeElement.textContent = clabeStr
        const img = this.viewElement.querySelector("*[pago_copiar]") as HTMLImageElement
        img.alt = _L("general.copiar")
        img.src = UIUtilIconResources.CGeneral.Copy
        img.onclick = () => {
            navigator.clipboard.writeText(clabeStr)
            _ShowTemporalTooltip(select(clabeElement), _L("general.copiedtext"))
        }

        this.viewElement.querySelector("*[pago_beneficiario]").textContent = licenciaPayInfo.beneficiario
        this.viewElement.querySelector("*[pago_institucion]").textContent = licenciaPayInfo.institucion

        // >> Total
        let total = 0
        for (const k in Global._LICENCIA.DetalleAdeudos) {
            total += Global._LICENCIA.DetalleAdeudos[k].SaldoFinal
        }
        this.viewElement.querySelector("*[pago_total]").textContent = UIUtilFormat._CurrencyFmt(total)
    }

    private RefreshLicenciasItems() {
        this.licenciasContElement.innerHTML = ""
        Global._LICENCIA._GetDetalleAdeudosArray()
            .sort((a, b) => new Date(a.FechaTolerancia).getTime() - new Date(b.FechaTolerancia).getTime())
            .forEach(d => {
                // console.warn(idEscuela, _DiccFullEscuelas, _DiccEscuela)
                if (_DiccFullEscuelas.has(d._IdEscuela))
                    this.BuildItemLicencia(this.itemLicTemplate, d._IdEscuela, d)
            })
    }

    private BuildItemLicencia(itemLicTemplate: HTMLDivElement, idEscuela: number, d: ILicenciaConfigOrganizacionDetalleAdeudo) {
        const itemFinalElement = itemLicTemplate.cloneNode(true) as HTMLDivElement

        let strCosto = UIUtilFormat._CurrencyFmt(d.Saldo)
        if (d.Detalles.length) {
            d.Detalles.forEach(det => {
                if (det.TipoRegistro == CLicenciaConfigOrganizacionTipoRegistro.IVA) {
                    strCosto += (Global._LICENCIA.IVA ? ` + IVA(${UIUtilFormat._CurrencyFmt(det.Valor)})` : "")
                }
            })
            strCosto += ` = ` + UIUtilFormat._CurrencyFmt(d.SaldoFinal)
        }

        itemFinalElement.querySelector("*[escuela_nombre]").textContent = _DiccFullEscuelas.get(idEscuela).Nombre
        itemFinalElement.querySelector("*[monto_pago]").innerHTML = strCosto
        itemFinalElement.querySelector("*[periodo]").innerHTML = UIUtilTime._GetDaysInMonth(d.FechaInicio) == UIUtilTime._GetTimeElapsedSimple(new Date(d.FechaInicio), new Date(d.FechaFin)).Days() + 1
            ? UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(d.FechaInicio, "MMM yyyy"))
            : `${UIUtilTime._DateFormatStandar(d.FechaInicio)} - ${UIUtilTime._DateFormatStandar(d.FechaFin)}`
        itemFinalElement.querySelector<HTMLImageElement>("*[escuela_logo]").src = _DiccFullEscuelas.get(idEscuela).Logo
        itemFinalElement.querySelector("*[fecha_limite]").innerHTML = UIUtilTime._DateFormatStandar(d.FechaTolerancia, "d MMM yyyy")
        if (d._EsExpirado())
            itemFinalElement.querySelector<HTMLElement>("*[fecha_limite]").style.color = "var(--color_app_red1)"

        this.licenciasContElement.appendChild(itemFinalElement)
    }

    private EnableAllButtons(enable: boolean) {
        select(this.viewElement).selectAll<HTMLButtonElement, any>("button").each((_, i, btns) => {
            const btn = btns[i]
            if (btn["_forcedisabled_"]) return
            btn.disabled = !enable
            if (enable && btn["fnActive"]) {
                btn.onclick = btn["fnActive"]
                delete btn["fnActive"]
            } else if (!enable && !btn["fnActive"]) {
                btn["fnActive"] = btn.onclick
                btn.onclick = null
            }
        })
    }
}