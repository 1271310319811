import { Entidad } from "../data/Entidad";
import { DataUtil } from "../data/util/Util";
import { UIUtilIconResources } from "../ui/util/IconResourses";
import { UIUtilPermission } from "../ui/util/Permission";
import { TRoutePath as TPath, TRoutePath, _ROUTES } from "./Routes";
import { _MenuLabel } from "../util/Labels";

const MENUPAGES: IPageItem[] = [
    {
        Path: "home",
        Icon: UIUtilIconResources.CGeneral.AppLightBackground,
    },
    {
        LabelKey: "escuelas",
        Icon: UIUtilIconResources.CMenuIco.Escuela,
        Childs: [
            {
                Path: "escuelas/escuelas",
                NoMenuChilds: [
                    { Path: "escuelas/escuelas/panel" },
                ]
            },
            {
                Path: "escuelas/grupos",
                NoMenuChilds: [
                    { Path: "escuelas/grupos/panel" },
                ]
            },
            {
                Path: "escuelas/maestros",
            },
            {
                Path: "escuelas/calendarios",
            }
        ]
    },
    {
        LabelKey: "alumnos",
        Icon: UIUtilIconResources.CMenuIco.Alumno,
        Childs: [
            {
                Path: "alumnos/alumnos",
                NoMenuChilds: [
                    { Path: "alumnos/alumnos/panel" },
                ]
            },
            {
                Path: "alumnos/tutores",
            },
            {
                LabelKey: "alumnos/licencias",
                Childs: [
                    {
                        Path: "alumnos/licencias/licencias",
                    },
                    {
                        Path: "alumnos/licencias/pagospendientes",
                    }
                ]
            },
            {
                Path: "alumnos/covid",
            },
        ]
    },
    {
        LabelKey: "finanzas",
        Icon: UIUtilIconResources.CMenuIco.Finanzas,
        Childs: [
            {
                LabelKey: "finanzas/catalogos",
                Childs: [
                    {
                        Path: "finanzas/catalogos/metodospago",
                    },
                    {
                        Path: "finanzas/catalogos/ingresos",
                    },
                    {
                        Path: "finanzas/catalogos/egresos",
                    },
                    {
                        Path: "finanzas/catalogos/cuentas",
                    },
                    {
                        Path: "finanzas/catalogos/proveedores",
                    },
                    {
                        Path: "finanzas/catalogos/egresoscategorias",
                    }
                ]
            },
            {
                LabelKey: "finanzas/ingresos",
                Childs: [
                    {
                        Path: "finanzas/ingresos/horasextras",
                    },
                    {
                        Path: "finanzas/ingresos/pagosrecibidos",
                    },
                    {
                        Path: "finanzas/ingresos/pagospendientes",
                    },
                    {
                        Path: "finanzas/ingresos/cargosporaplicar",
                    }
                ]
            },
            {
                LabelKey: "finanzas/egresos",
                Childs: [
                    {
                        Path: "finanzas/egresos/cuentasporpagar",
                    },
                    {
                        Path: "finanzas/egresos/movimientos",
                    },
                    {
                        Path: "finanzas/egresos/movimientosproveedor",
                    }
                ]
            },
        ]
    },
    {
        Path: "circulares",
        Icon: UIUtilIconResources.CMenuIco.Circular,
    },
    {
        LabelKey: "logros",
        Icon: UIUtilIconResources.CMenuIco.Logros,
        Childs: [
            {
                Path: "logros/logros",
                // IdModulo: CModulo.Logros, // NOTE Pendiente de implementar cambios
            },
            {
                Path: "logros/categorias",
                // IdModulo: CModulo.LogrosCategorias, // NOTE Pendiente de implementar cambios //Cambié IdModulo
            }
        ]
    },
    {
        LabelKey: "calificaciones",
        Icon: UIUtilIconResources.CMenuIco.Logros,
        Childs: [
            {
                Path: "calificaciones/materias",
            },
            {
                Path: "calificaciones/boletasgeneradas",
            },
            {
                Path: "calificaciones/materiascalificadas",
            }
        ]
    },
    {
        Path: "usuarios",
        Icon: UIUtilIconResources.CMenuIco.Usuario,
    },
    {
        LabelKey: "reportes",
        Icon: UIUtilIconResources.CMenuIco.Reportes,
        // IdModulo: CModulo.Alumnos, // TEMPORAL // REMOVER
        Childs: [
            {
                Path: "reportes/asistencias",
            },
            {
                Path: "reportes/eventos",
            },
            {
                Path: "reportes/logros",
            }
        ]
    },
    {
        LabelKey: "ayuda",
        Icon: UIUtilIconResources.CMenuIco.Ayuda,
        Childs: [
            {
                Path: "ayuda/admin",
            },
            {
                Path: "ayuda/maestros",
            },
            {
                Path: "ayuda/papas",
            },
            {
                Path: "ayuda/alumnos",
            }
        ]
    },
];

export namespace UIWindowPagesNavUtils {
    let initialized = false;

    function GetSharePages() {
        function GetItemsClones(items: IPageItem[], parent: IPageItem) {
            return items
                .filter(d => {
                    const route = _ROUTES[d.Path];
                    if (!route) return true;
                    return (DataUtil._EvalItemEnvironment(route.Environment, true));
                })
                .map(d => {
                    const dd: IPageItem = { ...d };

                    if (dd.Childs?.length) {
                        (dd.Childs as any) = GetItemsClones(dd.Childs, dd);
                    } else {
                        (dd.Childs as any) = null;
                    }
                    return dd;
                });
        }
        return GetItemsClones(MENUPAGES, undefined);
    }

    function GetNavigationScheme() {
        if (!initialized) {
            type T = Pick<IPageItem, "Path" | "Parent" | "Childs" | "NoMenuChilds" | "LabelKey" | "Label">;
            function fnInitMenuItems(menuItems: T[], menuItemParent?: T): void {
                for (const item of menuItems) {
                    item.LabelKey = item.LabelKey || item.Path;
                    item.Label = _MenuLabel(item.LabelKey as any);
                    // @ts-ignore
                    item.Parent = menuItemParent;

                    if (item.NoMenuChilds) {
                        fnInitMenuItems(item.NoMenuChilds, item);
                    }
                    if (item.Childs) {
                        fnInitMenuItems(item.Childs, item);
                    }
                }
            }
            fnInitMenuItems(MENUPAGES);
            initialized = true;
        }
        return GetSharePages();
    }

    /** Retorna los elementos destinados al menu principal, evalúa */
    export function _GetMainPageMenu(): IPageItem[] {
        // const startLevel = 1;
        const forItems = (menu: IPageItem[]/* , lvl: number = startLevel */) => {
            let validItems: IPageItem[] = [];

            for (let menuItem of menu) {
                const item = { ...menuItem };
                const itemRoute = (_ROUTES[menuItem.Path] || {}) as typeof _ROUTES[TPath];

                // if (!item.IsMenuItem) {
                //     continue;
                // }

                if (itemRoute.IdModulo !== undefined) {
                    if (itemRoute.IdModulo == Entidad.CModulo.Usuarios && DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Limitado) {
                        continue;
                    }
                    if (!UIUtilPermission._HasModulePermission(itemRoute.IdModulo)) {
                        // console.warn("No permission found for menu", item.Path);
                        continue;
                    } else {
                        if (!UIUtilPermission._IsConfigurableModuleAvailable(itemRoute.IdModulo)) continue;
                    }
                }

                if (item.Childs) {
                    (item.Childs as any) = forItems(item.Childs/* , lvl + 1 */);
                } else {
                    (item.Childs as any) = [];
                }

                if (item.Childs.length > 0 || itemRoute.View) {
                    validItems.push(item);
                }
            }
            return validItems;
        };

        return forItems(GetNavigationScheme());
    }

    // InitPagesAndPantallasMap(MENUPAGES);
    type TMainPageMenuItems = { [key in TPath]: IPageItem };
    function InitMainPageMenuItems(menuItems: IPageItem[]): TMainPageMenuItems {
        const res: TMainPageMenuItems = {} as any;
        const forEach = (menuItems: Pick<IPageItem, "Path" | "Childs" | "NoMenuChilds" | "LabelKey">[]) => {
            menuItems.forEach(item => {
                res[item.Path || item.LabelKey] = item;
                // if (route.View) {
                //     pantallasDisponibles.set(item.Path, item);
                // }
                if (item.NoMenuChilds?.length) {
                    forEach(item.NoMenuChilds);
                }
                if (item.Childs?.length) {
                    forEach(item.Childs);
                }
            })
        }
        forEach(menuItems);
        return res;
    }

    export function _GetMainPageMenuItem(path: TPath): IPageItem | null {
        const menuAvailableItems = InitMainPageMenuItems(GetNavigationScheme());
        // console.debug(menuAvailableItems)
        return menuAvailableItems[path] || null;
    }
}

export type IPageItem = {
    // readonly DefaultParams?: string | ({ [k in string]: (string | number | boolean | any[]) });
    /** Se defina al exportar `MENUPAGES` */
    Label?: string;
    /** `default`: {@link IPageItem.Path} */
    LabelKey?: string;
    readonly Icon?: string;
    /** Se crea al invocar {@link GetNavigationScheme} */
    readonly Parent?: IPageItem,
    /** Rutas descendientes, se usan para poder determinar el esquema. No forman parte del Menú */
    readonly NoMenuChilds?: Pick<IPageItem, "Path" | "Parent" | "LabelKey" | "NoMenuChilds" | "Label">[];
} & (
        {
            readonly Path: TPath;
            readonly Childs?: IPageItem[];
        }
        | {
            readonly Path?: TPath;
            readonly Childs: IPageItem[];
        }
    )

