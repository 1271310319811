import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { CLicenciaFormaPago as CFormaPago, ILicenciaPagoPendiente } from '../../data/entidad/LicenciaOld';
import { _DICC_ALUMNO } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { _LOCALDATA_GetURLFile_SPEIReferencePay as _LOCALDATA_Licencia_GetURLFile_SPEIReferencePay, _LOCALDATA_GetURLFile_StoreReferencePay as _LOCALDATA_Licencia_GetURLFile_StoreReferencePay, _SvLicenciaObtenerListaPagoProgramado } from "../../data/modulo/LicenciaOld";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumno } from "../utilView/Alumno";

import IAlumno = Entidad.IAlumno;

interface ILicenciaPPGrid extends ILicenciaPagoPendiente {
    KinderFiltro: number[];
    NombreEscuela: string;
    MontoFmt: string;
    MontoTotal: number;
    Alumnos: IAlumno[];
    FormaPagoStr: string;
}

export class UIVentanaLicenciasPagosPendientes extends VentanaGrid<ILicenciaPPGrid> {
    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, "licenciapagopendiente");
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ILicenciaPPGrid[]): Table.ITableMenuDataSelectedOptionConfig<ILicenciaPPGrid>[] {
        let opciones: Table.ITableMenuDataSelectedOptionConfig<ILicenciaPPGrid>[] = [];

        if (dataGridSelected.length == 1) {
            opciones.push({
                Label: "tag_verreferencia",
                MultiData: false,
                Callback: (datos) => {
                    let licPP = datos[0];
                    let id = licPP.Detalles[licPP.Detalles.length - 1];
                    let url: string;
                    if (licPP.FormaPago == CFormaPago.Transferencia) {
                        url = _LOCALDATA_Licencia_GetURLFile_SPEIReferencePay(id);
                    } else if (licPP.FormaPago == CFormaPago.Efectivo) {
                        url = _LOCALDATA_Licencia_GetURLFile_StoreReferencePay(id);
                    }
                    let windowReference = window.open(url, "_blank");
                    windowReference.focus();
                }
            });
        }

        return opciones;
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<ILicenciaPPGrid>[] {
        return [
            { Label: "Folio", Field: "Folio" },
            { LabelLangKey: "d_field_montofmt", Field: "MontoTotal", Type: "number" },
            {
                Field: "FormaPagoStr",
                Type: "select",
                Options: [
                    { Id: CFormaPago.Transferencia, Name: UIUtilViewData._GetStr_FormaPago(CFormaPago.Transferencia) },
                    { Id: CFormaPago.Efectivo, Name: UIUtilViewData._GetStr_FormaPago(CFormaPago.Efectivo) },
                ]
            },
            {
                LabelLangKey: "tag_alumno",
                Field: "Alumnos",
                Type: "text",
                OnGetValueToMatch: (dato: ILicenciaPPGrid) => dato.Alumnos.map(d => d.Nombre)
            },
            { Label: "FechaC", Field: "FechaCreacion", Type: "date", MaxDate: new Date().toISOString() },
            { Label: "FechaV", Field: "FechaVigencia", Type: "date", MinDate: new Date().toISOString() },
        ]
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ILicenciaPPGrid> {
        return {
            IdTabla: "LicenciasPagosPendientes",
            IdData: "Id",
            DefaultSort: null,
            MinWidth: 1100,
            Columns: [
                { Field: "Folio", Label: "Folio", Width: "12%", MinWidth: "100px" },
                { Field: "MontoFmt", Label: "Monto", Width: "10%", MinWidth: "100px" },
                { Field: "FormaPagoStr", Label: "FormaPago", Width: "9%", MinWidth: "100px" },
                // { Field: "EstadoPago", Label: "EstadoPago", Width: "10%", MinWidth: "100px" },
                { Field: "Alumnos", Label: "Alumnos", Width: "14%", MinWidth: "100px" },
                { Field: "NombreEscuela", Label: "Escuela", Width: "14%", MinWidth: "100px" },
                { Field: "FechaCreacion", Label: "FechaCreacion", Width: "12%", MinWidth: "100px" },
                { Field: "FechaVigencia", Label: "FechaVigencia", Width: "15%", MinWidth: "100px" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ILicenciaPPGrid> {
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field) => {
                    switch (field) {
                        case "FechaCreacion":
                        case "FechaVigencia":
                            container.text(UIUtilTime._DateFormatStandar(datum[field], "dd/mm/yyyy h12:mm"));
                            break;
                        case "Alumnos":
                            UIUtilGeneral._CreaElementosLinkeablesV2({
                                Container: container.style("overflow", "visible"),
                                Data: datum.Alumnos,
                                Path: "alumnos/alumnos/panel",
                                GetId: (d) => d.IdChild,
                                GetTag: (d) => d.NombreCompleto || UIUtilLang._GetUIString("general", "nodisponible") + "_" + d.IdChild,
                                OnStepItemLink: (element, d) => UIUtilViewAlumno._UIAlumnoLicenciaInfo(element, d.getEdoLicencia, "oninfoico", -6),
                            })
                            break;
                    }
                }
            }
        };
    }

    protected GRID_GetExportarConfig(dataGrid: ILicenciaPPGrid[]): IConfigGridExcelExport<ILicenciaPPGrid> {
        return {
            IdsEscuelas: [... new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map(d => ({
                    Field: d.Field,
                    HeaderTag: d.Label,
                    WidthCell: 30,
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map((entrie) => ({
                        IdSheet: entrie[0],
                        SheetName: entrie[1][0].NombreEscuela,
                        Data: entrie[1]
                    }))
            },
            OnStepFieldInDataRow: (field, datum) => {
                switch (field) {
                    case "FechaCreacion":
                    case "FechaVigencia":
                        return UIUtilTime._DateFormatStandar(datum[field], "dd/mm/yyyy h12:mm")
                    case "Alumnos":
                        return datum.Alumnos
                            .map(d => (d.NombreCompleto || (UIUtilLang._GetUIString("general", "nodisponible") + "_" + d.IdChild)))
                            .join(", ");
                }
                return datum[field] + "";
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreEscuela
        };
    }

    protected async GridGetData(): Promise<ILicenciaPPGrid[]> {
        return new Promise(async (resolve) => {
            let idsEscuelas = Array.from(DataModuloEscuela._DiccEscuela.values()).map(d => d.IdKinder);
            let licenciaPagoPendiente: ILicenciaPPGrid[];

            let resLicPagoPendiente = await _SvLicenciaObtenerListaPagoProgramado(idsEscuelas);

            if (resLicPagoPendiente.Resultado <= 0) {
                this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                resolve([]);
                return;
            }

            licenciaPagoPendiente = resLicPagoPendiente.Datos
                .map(d => {
                    let dd = d as ILicenciaPPGrid;

                    dd.KinderFiltro = [dd.IdEscuela];
                    dd.NombreEscuela = DataModuloEscuela._DiccEscuela.get(dd.IdEscuela)?.Nombre;
                    dd.MontoTotal = 0;
                    dd.Montos.forEach(monto => {
                        dd.MontoTotal += monto;
                    });
                    dd.FormaPagoStr = UIUtilViewData._GetStr_FormaPago(dd.FormaPago);
                    dd.MontoFmt = UIUtilFormat._CurrencyFmt(dd.MontoTotal);
                    dd.Alumnos = Array.from(new Set(dd.IdsAlumno))
                        .map(idAlumno => (_DICC_ALUMNO.get(idAlumno) || <IAlumno>{ IdChild: idAlumno }));

                    return dd;
                });

            resolve(licenciaPagoPendiente);
        })
    }
}
