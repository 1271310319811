import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEgresoMovimiento from "../../data/modulo/EgresoMovimiento";
import DataModuloEgresoPredefinido from "../../data/modulo/EgresoPredefinido";
import DataModuloPermisos from "../../data/modulo/Permisos";
import DataModuloProveedor from "../../data/modulo/Proveedor";
import { DateV2 } from "../../util/DateV2";
import { Button } from "../controlD3/Button";
import { FormGenerator } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { UIUtilLang } from "../util/Language";
import { UIUtilMask } from "../util/Mask";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaEgresoCategoria } from "./FinanzaEgresoCategoria";
import { UIUtilViewFinanzaEgresoUtil } from "./FinanzasEgresoUtil";
import { UIUtilViewFinanzasProveedores } from "./FinanzasProveedor";

export namespace UIUtilViewFinanzaEgreso {
    import CModulo = Entidad.CModulo;
    import IEscuela = Entidad.IEscuela;
    import IEgresoPlantilla = Entidad.IFinanzaEgresoPredefinido;
    import IProveedor = Entidad.IFinanzaProveedor;
    import ICuentaBancaria = Entidad.IFinanzaCuentaBancaria;
    import CTipoEgreso = Entidad.CFinanzaEgresoTipo;
    import ICategoriaEgreso = Entidad.IFinanzasEgresoCategoria;
    // import IEgresoMovDetalle = Entidad.IFinanzaEgresoMovimientoDetalle;

    const { _GetReqDataArrayByName: GetReqDataArray } = DataModuloMain;
    const { _GetUIString: LangString } = UIUtilLang;
    const { _Mostrar: MostrarNotificacion } = NotificacionV2;
    const { _HasAccionPermission: HasAccionPermiso } = UIUtilPermission;
    const ID_PERMISO_AGREGAR = Entidad.CAccionPermiso.Agregar;
    const ID_PERMISO_EDITAR = Entidad.CAccionPermiso.Editar;

    // import IMetodoPago = Entidad.IFinanzaMetodoPago;
    // import ICuentaBancaria = Entidad.IFinanzaCuentaBancaria;

    interface IEgresoForm {
        IdEscuela: number;
        IdEgresoPlantilla: number;

        Descripcion: string;
        IdProveedor: number;
        TipoEgreso: CTipoEgreso;
        IdCategoria: number;
        SubTotal: number;
        FechaAplicacion: string;
        FechaVencimiento: string;
        Folio: string;
        Descuento: number;
        AplicaIntereses: boolean;
        TrasladoIVA: number;
        TrasladoIEPS: number;
        RetencionIEPS: number;
        RetencionISR: number;
        RetencionIVA: number;
        Total: number;

        // CantidadPago: number;
        IdCuentaPago: number;
        FechaPago: string;

        RegistrarPlantilla: boolean;
        Pagado: boolean;
    }
    type IEgresoEditar = Pick<IEgresoForm, "Folio" | "Descripcion" | "IdEscuela" | "IdProveedor"
        | "IdCategoria" | "TipoEgreso" | "FechaAplicacion" | "FechaVencimiento" | "Descuento"
        | "TrasladoIVA" | "TrasladoIEPS" | "RetencionIEPS" | "RetencionISR" | "RetencionIVA"> & {
            Id: number;
            ValorCargo: number;
        }
    export const _EGRESO_LANGKEY = "finanzasegresosmovimientos";
    export const _CTABBANCARIA_STRINGKEY = "finanzacuentabancaria";
    const PROVEEDOR_LANGKEY = CModulo[CModulo.FinanzasProveedores];
    const ID_AGREGARPERSONALIZADO = 0;

    type Modulo = CModulo.FinanzasEgresosPagosPendientes | CModulo.FinanzasEgresosMovimientos;

    /**
     * @param modulo ({@linkcode CModulo.FinanzasEgresosPagosPendientes} | {@linkcode CModulo.FinanzasEgresosMovimientos})
     * @param escuelasDisponibles Si tiene items, serán los agregados al formulario, sino toma las escuelas con permiso
     * @returns (`Egreso operación exitosa` || `null`)
     */
    export function _OpenModal_MovEgreso_Agregar(modulo: Modulo, escuelasDisponibles?: IEscuela[]): Promise<IEgresoForm> {
        return OpenModal_MovEgreso_AgregaEdita(modulo, escuelasDisponibles);
    }

    /**
     * @param modulo ({@linkcode CModulo.FinanzasEgresosPagosPendientes} | {@linkcode CModulo.FinanzasEgresosMovimientos})
     * @param egresoEditar dato a Editar
     * @returns (`Egreso operación exitosa` || `null`)
     */
    export function _OpenModal_MovEgreso_Editar(modulo: Modulo, egresoEditar: IEgresoEditar): Promise<IEgresoForm> {
        return OpenModal_MovEgreso_AgregaEdita(modulo, null, egresoEditar);
    }

    /**
     * @param modulo ({@linkcode CModulo.FinanzasEgresosPagosPendientes} | {@linkcode CModulo.FinanzasEgresosMovimientos})
     * @param escuelasDisponibles Si tiene items, serán los agregados al formulario, sino toma las escuelas con permiso
     * @param egresoEditar si tiene valor, será formulario en modo Editar
     * @returns (`Egreso operación exitosa` || `null`)
     */
    function OpenModal_MovEgreso_AgregaEdita(modulo: Modulo, escuelasDisponibles?: IEscuela[], egresoEditar?: IEgresoEditar): Promise<IEgresoForm> {
        // const pagarAvailable = modulo == CModulo.FinanzasEgresosMovimientos;
        egresoEditar = egresoEditar || <IEgresoEditar>{};
        const esEditar = !!egresoEditar.Id;
        const permissionID = esEditar ? ID_PERMISO_EDITAR : ID_PERMISO_AGREGAR;
        const escuelasList = escuelasDisponibles?.length
            ? escuelasDisponibles
            : DataModuloMain._GetReqDataArrayByName("Escuela", true)
                .filter(escuela => HasAccionPermiso(permissionID, modulo, escuela.IdKinder));

        const datoInicial: IEgresoForm = {
            ...egresoEditar,
            IdEscuela: (() => {
                if (esEditar)
                    return egresoEditar.IdEscuela;
                if (escuelasList.length == 1)
                    return escuelasList[0].IdKinder;
                return null;
            })(),
            IdEgresoPlantilla: ID_AGREGARPERSONALIZADO,
            IdCuentaPago: 0,
            FechaPago: "",
            RegistrarPlantilla: false,
            Pagado: false,
            FechaAplicacion: UIUtilTime._FmtToInputDate(egresoEditar.FechaAplicacion),
            FechaVencimiento: UIUtilTime._FmtToInputDate(egresoEditar.FechaVencimiento),
            AplicaIntereses: !!(egresoEditar.TrasladoIEPS || egresoEditar.TrasladoIVA | egresoEditar.RetencionIEPS | egresoEditar.RetencionISR | egresoEditar.RetencionIVA),
            SubTotal: egresoEditar.ValorCargo,
            Total: 0, // Se calcula al asignar datos fnCalcularTotal
        };

        const GetMessageNoPermisoAgregarEscuela = (idEscuela: number, idModulo?: CModulo) => {
            let message = idModulo ? (UIUtilViewData._GetStr_Modulo(idModulo) + ": ") : "";
            message += LangString("permission", "accionparaescuelas");
            message = message.replace("_ACCION", DataModuloPermisos._PermisosAccionesMap.get(permissionID)?.Nombre?.toLowerCase());
            return message.replace("_ESCUELA", DataModuloMain._GetDataValueFieldByName("Escuela", idEscuela, "Nombre"));
        };

        if (escuelasList.length == 0) {
            if (escuelasDisponibles?.length) {
                NotificacionV2._Mostrar(GetMessageNoPermisoAgregarEscuela(escuelasDisponibles[0].IdKinder), "ADVERTENCIA")
            } else
                NotificacionV2._Mostrar(LangString("permission", "notif_noschoolspermission"), "ADVERTENCIA")
            return null;
        }

        const tipoGastoList = UIUtilViewData._GetList_EgresoTipo();
        //  GetReqDataArray("Escuela", true); // MISSING_PERMISSION
        // let _form: FormGenerator<IGeneraCargo>;

        const fnGetEgresosList = (idEscuela?: number) => {
            return <IEgresoPlantilla[]>[
                <IEgresoPlantilla>{
                    Id: ID_AGREGARPERSONALIZADO,
                    Descripcion: LangString(_EGRESO_LANGKEY, "tag_egresopersonal"),
                },
                ...(idEscuela
                    ? GetReqDataArray("EgresoPredefinido", true).filter(d => d.IdEscuela === idEscuela)
                        .sort((a, b) => d3.ascending(a.Descripcion, b.Descripcion))
                    : [])
            ]
        }
        const fnGetEgresoCategoriasList = (idEscuela?: number): ICategoriaEgreso[] => UIUtilViewFinanzaEgresoCategoria._GetCategoriasFullList(idEscuela || -1);
        const fnGetProveedoresList = (idEscuela?: number) => {
            return <IProveedor[]>[
                <IProveedor>{
                    Id: ID_AGREGARPERSONALIZADO,
                    RazonSocial: LangString(_EGRESO_LANGKEY, "tag_provpersonal"),
                },
                ...(idEscuela
                    ? GetReqDataArray("Proveedor", true).filter(d => d.IdEscuela === idEscuela)
                        .sort((a, b) => d3.ascending(a.RazonSocial, b.RazonSocial))
                    : [])
            ]
        }
        const fnGetCuentas = (idEscuela?: number) => {
            return <ICuentaBancaria[]>[
                // <ICuentaBancaria>{Id: 0,Numero: LangString("general", "ninguno"),},
                ...(idEscuela
                    ? GetReqDataArray("CuentaBancaria", true).filter(d => d.IdEscuela === idEscuela)
                        .sort((a, b) => d3.ascending(a.Beneficiario, b.Beneficiario))
                    : [])
            ]
        }
        const fnOpenModalProveedor = (proveedor: IProveedor, idEscuelaFrm) => {
            if (idEscuelaFrm && !HasAccionPermiso(ID_PERMISO_AGREGAR, CModulo.FinanzasProveedores, idEscuelaFrm)) {
                NotificacionV2._Mostrar(GetMessageNoPermisoAgregarEscuela(idEscuelaFrm, CModulo.FinanzasProveedores), "ADVERTENCIA");
                return null;
            }
            return new Promise<IProveedor>(resolve => {
                ModalThings._GetModalToForm<IProveedor>({
                    Title: LangString(_EGRESO_LANGKEY, "d_field_proveedor"),
                    GetForm: () => {
                        const form = UIUtilViewFinanzasProveedores._GetForm(ID_PERMISO_AGREGAR, null, false);
                        form._ControlsData.get("IdEscuela").row.classed("hide", true);
                        form._AsignaData(proveedor);
                        return form;
                    },
                    OnClose: () => resolve(proveedor),
                    OnAccept: (form, mt) => {
                        resolve(form._Data);
                        mt.Modal._Ocultar();
                        return null;
                    },
                })
            })
        }
        const fnGetProovedorInfoText = (proveedor: IProveedor) => (
            !proveedor ? "" :
                (!proveedor.Id ? `${LangString(PROVEEDOR_LANGKEY, "d_field_razonsocial")}: ${proveedor.RazonSocial}\n` : "")
                + `${LangString(PROVEEDOR_LANGKEY, "d_field_rfc")}: ${proveedor.RFC}`
                + `\n${LangString(PROVEEDOR_LANGKEY, "d_field_cp")}: ${proveedor.CP}`
                + `\n${LangString(PROVEEDOR_LANGKEY, "d_field_telefono")}: ${proveedor.Telefono || ""}`
                + `\n${LangString(PROVEEDOR_LANGKEY, "d_field_correo")}: ${proveedor.Correo || ""}`
        )
        const fnCalcularTotal = (form: FormGenerator<IEgresoForm>) => {
            const interesRetenido = ((form._Data.RetencionIEPS || 0) + (form._Data.RetencionISR || 0) + (form._Data.RetencionIVA || 0));
            const interesTrasladado = ((form._Data.TrasladoIVA || 0) + (form._Data.TrasladoIEPS || 0));
            const total = (form._Data.SubTotal || 0) - (form._Data.Descuento || 0) - interesRetenido + interesTrasladado;
            const totalFixed = total.toFixed(2);
            form._ControlsData.get("Total").selection.property("value", totalFixed);
        }

        return new Promise<IEgresoForm>((resolve, reject) => {
            let _egresoExitoso: IEgresoForm = null;
            let _proveedorPersonalizado: IProveedor;
            let _proveedorPersonalizadoID: number;
            ModalThings._GetModalToForm<IEgresoForm>({
                Action: permissionID,
                Modulo: modulo,
                LangModuleKeyInContext: _EGRESO_LANGKEY,
                Title: "tag_add_egreso",
                Width: 900,
                OnClose: () => resolve(_egresoExitoso),
                OnAccept: async (form, mt) => {
                    const dataForm: IEgresoForm = form._Data;
                    let crearPlantillaAux = false;

                    // Agregar/validar id proveedor personalizado
                    if (dataForm.IdProveedor == ID_AGREGARPERSONALIZADO && !_proveedorPersonalizadoID) {
                        const resProv = await DataModuloProveedor._NuevoRegistro({
                            ..._proveedorPersonalizado,
                            IdEscuela: dataForm.IdEscuela,
                        });
                        if (resProv.Resultado <= 0) {
                            MostrarNotificacion(LangString(_EGRESO_LANGKEY, "notif_fail_proveedoragregar"), "ADVERTENCIA");
                            return null;
                        } else {
                            // _proveedorPersonalizado = null;
                            _proveedorPersonalizadoID = resProv.Resultado;
                            MostrarNotificacion(LangString(_EGRESO_LANGKEY, "notif_proveedoragregado"), "INFO");
                            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Proveedor, dataForm.IdEscuela);
                        }
                    } else if (_proveedorPersonalizadoID && dataForm.IdProveedor != ID_AGREGARPERSONALIZADO) {
                        _proveedorPersonalizadoID = null;
                    }
                    if (_proveedorPersonalizadoID) {
                        dataForm.IdProveedor = _proveedorPersonalizadoID;
                    }

                    // Agregar egreso plantilla
                    if (!esEditar && dataForm.IdEgresoPlantilla == ID_AGREGARPERSONALIZADO) {
                        let registraRegresoTitle = LangString(_EGRESO_LANGKEY, "tag_add_egreso2");
                        let registrarEgresoMessage = LangString(_EGRESO_LANGKEY, "tag_add_egreso2_mess");
                        crearPlantillaAux = await ModalThings._GetConfirmacionBasico(registraRegresoTitle, registrarEgresoMessage, 390, "sí_no");
                        if (crearPlantillaAux) {
                            let resCrearEgresoPlantilla = await DataModuloEgresoPredefinido._NuevoRegistro({
                                Descripcion: dataForm.Descripcion,
                                IdEscuela: dataForm.IdEscuela,
                                TipoGasto: dataForm.TipoEgreso,
                                Valor: dataForm.SubTotal,
                                IdCategoria: dataForm.IdCategoria,
                            })
                            if (resCrearEgresoPlantilla.Resultado <= 0) {
                                MostrarNotificacion(LangString(_EGRESO_LANGKEY, "notif_fail_plantillaagregar"), "ADVERTENCIA");
                                crearPlantillaAux = false;
                            } else {
                                MostrarNotificacion(LangString(_EGRESO_LANGKEY, "notif_plantillaagregada"), "INFO");
                            }
                        }
                    }
                    dataForm.FechaAplicacion = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataForm.FechaAplicacion))
                        ._SetTimeZoneByIdSchool(dataForm.IdEscuela, true)
                        ._ToISOString()

                    dataForm.FechaVencimiento = dataForm.FechaVencimiento
                        ? new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataForm.FechaVencimiento))
                            ._SetTimeZoneByIdSchool(dataForm.IdEscuela, true)
                            ._ToISOString()
                        : null

                    let res: DataDRequest.IRequestResponseA;
                    if (esEditar) {
                        res = await DataModuloEgresoMovimiento._ActualizarEgreso(egresoEditar.Id, dataForm);
                    } else {
                        res = await DataModuloEgresoMovimiento._NuevoEgreso({
                            ...dataForm,
                            FechaEmision: null,
                            EsFactura: false,
                        });
                    }
                    if (res.Resultado > 0) {
                        _egresoExitoso = dataForm;
                    }
                    if (res.Resultado > 0 && crearPlantillaAux) {
                        MainPage._ReloadService(Entidad.CTipoRequest.EgresoPredefinido, dataForm.IdEscuela);
                    }

                    if (!esEditar && dataForm.Pagado && res.Resultado > 0) {
                        let resPago = await DataModuloEgresoMovimiento._NuevoPago({
                            IdMovimiento: res.Resultado,
                            IdCuenta: dataForm.IdCuentaPago,
                            Cantidad: dataForm.Total, //dataForm.SubTotal + dataForm.ValorInteres,
                            FechaPago: new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataForm.FechaPago))
                                ._SetTimeZoneByIdSchool(dataForm.IdEscuela, true)
                                ._ToISOString(),
                        });
                        if (resPago.Resultado <= 0) {
                            MostrarNotificacion(LangString(_EGRESO_LANGKEY, "notif_fail_pago"), "ADVERTENCIA");
                        }
                    }
                    return res;
                },
                GetForm: (container, mt) => {
                    // const fnFocusEscuelaSelected = async (idEscuela: number) => {}
                    const fnReqEscuelaModules = async (idEscuela: number) => {
                        mt.Progress.attr("oculto", false);
                        const resSyncEgPlant = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.EgresoPredefinido, idEscuela);
                        const resSyncProv = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Proveedor, idEscuela);
                        const resSyncCtaBan = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.CuentaBancaria, idEscuela);
                        const resSyncCategoria = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.EgresoCategoria, idEscuela);
                        mt.Progress.attr("oculto", true);
                        if (!resSyncEgPlant || !resSyncProv || !resSyncCtaBan || !resSyncCategoria) {
                            MostrarNotificacion(LangString("general", "notif_fail_infosync"), "ADVERTENCIA");
                            return false;
                        }
                        return true;
                    }
                    const form = new FormGenerator<IEgresoForm>()
                    form._Crear({
                        LangModuleKeyInContext: _EGRESO_LANGKEY,
                        LabelMaxWidth: 150,
                        schema: [
                            {
                                model: "IdEscuela",
                                type: "selectMaterial",
                                labelText: "d_field_nombreescuela",
                                selectMaterialAttr: {
                                    disabled: esEditar,
                                    removeBorder: esEditar,
                                    valueMember: <keyof IEscuela>("IdKinder"),
                                    displayMember: <keyof IEscuela>("Nombre"),
                                    required: true,
                                    onChange: async (_, escuela: IEscuela) => {
                                        if (!escuela) return;
                                        const { IdKinder: idEscuela } = escuela;
                                        if (idEscuela) {
                                            await fnReqEscuelaModules(idEscuela);
                                        }

                                        // Actualiza las opciones de proveedores
                                        let controlProovedor = form._ControlsData.get("IdProveedor");
                                        let selectProovedor = controlProovedor.instance as SelectV2<IProveedor, "Id">;
                                        selectProovedor._UpdateList(() => fnGetProveedoresList(idEscuela));

                                        // Actualiza las opciones de egresos
                                        let controlEgreso = form._ControlsData.get("IdEgresoPlantilla");
                                        let selectEgreso = controlEgreso.instance as SelectV2<IEgresoPlantilla, "Id">;
                                        selectEgreso._UpdateList(() => fnGetEgresosList(idEscuela));

                                        let controlCategoriaEgreso = form._ControlsData.get("IdCategoria");
                                        let selectCategoriaEgreso = controlCategoriaEgreso.instance as SelectV2<ICategoriaEgreso, "Id">;
                                        selectCategoriaEgreso._UpdateList(() => fnGetEgresoCategoriasList(idEscuela));

                                        // Actualiza las opciones de Cuenta bancaria
                                        let controlCuenta = form._ControlsData.get("IdCuentaPago");
                                        let selectCuenta = controlCuenta.instance as SelectV2<ICuentaBancaria, "Id">;
                                        selectCuenta._UpdateList(() => fnGetCuentas(idEscuela));

                                        // Resetea la cantidad
                                        let controlCantidad = form._ControlsData.get("SubTotal");
                                        let inputCantidad = controlCantidad.selection as TSelectionHTML<"input">;
                                        inputCantidad.property("value", 0);

                                    },
                                },
                                values: escuelasList,
                            },
                            {
                                model: "IdProveedor",
                                type: "selectMaterial",
                                labelText: "d_field_proveedor",
                                selectMaterialAttr: {
                                    valueMember: <keyof IProveedor>("Id"),
                                    displayMember: <keyof IProveedor>("RazonSocial"),
                                    ShowAndEnableSearchText: true,
                                    required: true,
                                    OnStepItemListUI: (container, proveedor: IProveedor) => {
                                        container.html(proveedor.Id == ID_AGREGARPERSONALIZADO ? `<i>${proveedor.RazonSocial}</i>` : proveedor.RazonSocial)
                                    },
                                    onChange: async (idProv: number, proveedor: IProveedor) => {
                                        const infoProveedor = form._ControlsData.get("IdProveedor").row.select<HTMLDivElement>(".proveedor_info");
                                        if (idProv == ID_AGREGARPERSONALIZADO) {
                                            const controlProveedor = (form._ControlsData.get("IdProveedor").instance as SelectV2<IProveedor, "Id", "monoselect">);
                                            _proveedorPersonalizado = await fnOpenModalProveedor(_proveedorPersonalizado, form._Data.IdEscuela);
                                            infoProveedor.classed("hide", !_proveedorPersonalizado)
                                            if (_proveedorPersonalizado) {
                                                infoProveedor.text("");
                                                infoProveedor.append("pre").text(fnGetProovedorInfoText(_proveedorPersonalizado) + "\n")
                                                new Button(infoProveedor.node(), LangString("c_actions", "editar"))
                                                    ._d3Selection
                                                    .on("click", async () => {
                                                        _proveedorPersonalizado = await fnOpenModalProveedor(_proveedorPersonalizado, form._Data.IdEscuela);
                                                        infoProveedor.select("pre").text(fnGetProovedorInfoText(_proveedorPersonalizado) + "\n")
                                                    })
                                                infoProveedor.append("b").text(LangString(_EGRESO_LANGKEY, "tag_proveedor_registrar_info"))
                                            } else if (controlProveedor._dataSelected[0])
                                                controlProveedor._ResetSelect();
                                        } else {
                                            _proveedorPersonalizado = null;
                                            _proveedorPersonalizadoID = null;
                                            form._ControlsData.get("IdProveedor").row.select(".proveedor_info")
                                                .classed("hide", !proveedor)
                                                .text(fnGetProovedorInfoText(proveedor))
                                        }
                                    },
                                    Data: () => fnGetProveedoresList(form._Data.IdEscuela),
                                },
                                onLoad: ({ controlWrapper }) => {
                                    controlWrapper.append("pre")
                                        .attr("class", "proveedor_info hide")
                                        .style("padding", "var(--padding1)")
                                        .style("font-size", "var(--fontsize_me2)")
                                        .style("border", "1px solid var(--color_borderbox1)")
                                        .style("border-radius", "var(--border_radius_base)")
                                },
                                onValidate: (idProveedor, control) => {
                                    if (idProveedor == ID_AGREGARPERSONALIZADO && form._Data.IdEscuela && !HasAccionPermiso(ID_PERMISO_AGREGAR, CModulo.FinanzasProveedores, form._Data.IdEscuela)) {
                                        NotificacionV2._Mostrar(GetMessageNoPermisoAgregarEscuela(form._Data.IdEscuela, CModulo.FinanzasProveedores), "ADVERTENCIA");
                                        return false;
                                    }
                                    if (idProveedor == ID_AGREGARPERSONALIZADO)
                                        return !!(_proveedorPersonalizado);
                                    return true;
                                }
                            },
                            {
                                model: "Folio",
                                type: "input",
                                labelText: "d_field_folio",
                                inputAttr: {
                                    maxlength: 100,
                                    onload: (input) => UIUtilMask._ApplyNoWhiteSpacesMask(input.node()),
                                }
                            },
                            {
                                model: "IdEgresoPlantilla",
                                type: "selectMaterial",
                                labelText: "tag_egreso",
                                onValidate: (idEgreso) => {
                                    if (idEgreso == ID_AGREGARPERSONALIZADO && form._Data.IdEscuela && !HasAccionPermiso(ID_PERMISO_AGREGAR, CModulo.FinanzasEgresos, form._Data.IdEscuela)) {
                                        NotificacionV2._Mostrar(GetMessageNoPermisoAgregarEscuela(form._Data.IdEscuela, CModulo.FinanzasEgresos), "ADVERTENCIA");
                                        return false;
                                    }
                                    return true;
                                },
                                selectMaterialAttr: {
                                    valueMember: <keyof IEgresoPlantilla>("Id"),
                                    displayMember: <keyof IEgresoPlantilla>("Descripcion"),
                                    required: true,
                                    ShowAndEnableSearchText: true,
                                    OnStepItemListUI: (container, proveedor: IEgresoPlantilla) => {
                                        container.html(proveedor.Id == ID_AGREGARPERSONALIZADO ? `<i>${proveedor.Descripcion}</i>` : proveedor.Descripcion)
                                    },
                                    Data: () => fnGetEgresosList(form._Data.IdEscuela),
                                    onSelect: (/* id: IEgresoPlantilla["Id"], */ dato: IEgresoPlantilla) => {
                                        const esPlantilla = (dato.Id != ID_AGREGARPERSONALIZADO);

                                        // Reset Cantidad
                                        let controlCantidad = form._ControlsData.get("SubTotal");
                                        let inputCantidad = controlCantidad.selection as TSelectionHTML<"input">;
                                        inputCantidad.property("value", dato.Valor);
                                        fnCalcularTotal(form);

                                        // Asigna TipoEgreso
                                        let controlTipoEgreso = form._ControlsData.get("TipoEgreso");
                                        let selectTipoEgreso = controlTipoEgreso.instance as SelectV2<typeof tipoGastoList[0], "Id">;
                                        selectTipoEgreso._valueSelect(dato.TipoGasto)
                                            ._ShowInputBorder(esPlantilla)
                                            ._disabled = esPlantilla;

                                        // Asigna IdCategoria
                                        let controlCategoriaEgreso = form._ControlsData.get("IdCategoria");
                                        let selectCategoriaEgreso = controlCategoriaEgreso.instance as SelectV2<ICategoriaEgreso, "Id">;
                                        selectCategoriaEgreso
                                            ._UpdateList(() => fnGetEgresoCategoriasList(form._Data.IdEscuela))
                                            ._valueSelect(dato.IdCategoria)
                                            ._ShowInputBorder(esPlantilla)
                                            ._disabled = esPlantilla;

                                        // Actualiza Descripcion
                                        let controlDescripcion = form._ControlsData.get("Descripcion");
                                        let inputDescripcion = controlDescripcion.selection as TSelectionHTML<"input">;
                                        controlDescripcion.row.classed("hide", esPlantilla);
                                        inputDescripcion
                                            .property("required", !esPlantilla)
                                            .property("value", esPlantilla ? dato.Descripcion : "");
                                        if (!esPlantilla && !esEditar) {
                                            setTimeout(() => inputDescripcion.node().focus(), 10);
                                        }
                                    }
                                },
                            },
                            {
                                model: "Descripcion",
                                type: "input",
                                labelText: "d_field_descripcion",
                                inputAttr: { required: true, maxlength: 200, }
                            },
                            {
                                model: "TipoEgreso",
                                type: "selectMaterial",
                                labelText: "d_field_tipoegreso",
                                selectMaterialAttr: {
                                    valueMember: "Id", displayMember: "Name",
                                    required: true, disabled: true
                                },
                                values: tipoGastoList,
                            },
                            {
                                model: "IdCategoria",
                                type: "selectMaterial",
                                labelText: "d_field_categoria",
                                selectMaterialAttr: {
                                    valueMember: "Id", displayMember: "Nombre",
                                    required: true, disabled: true,
                                    Data: () => fnGetEgresoCategoriasList(form._Data.IdEscuela),
                                },
                            },
                            {
                                model: "SubTotal",
                                type: "input",
                                labelText: "tag_subtotal",
                                inputAttr: { type: "currency", min: 1, step: "0.01", required: true, onchange: () => fnCalcularTotal(form), }
                            },
                            {
                                model: "Descuento",
                                type: "input",
                                labelText: "d_field_descuento",
                                inputAttr: { type: "currency", min: 0, step: "0.01", value: "0", onchange: () => fnCalcularTotal(form), }
                            },
                            //#region INTERESES
                            {
                                model: "AplicaIntereses",
                                type: "input",
                                labelText: "tag_desglosa_imp",
                                inputAttr: {
                                    type: "checkbox",
                                    onchange: (e) => {
                                        const aplicaInteres = (e.target as HTMLInputElement).checked;
                                        form._ControlsData.get("TrasladoIVA").row.classed("hide", !aplicaInteres).select("input").property("value", 0);
                                        form._ControlsData.get("TrasladoIEPS").row.classed("hide", !aplicaInteres).select("input").property("value", 0);
                                        form._ControlsData.get("RetencionIVA").row.classed("hide", !aplicaInteres).select("input").property("value", 0);
                                        form._ControlsData.get("RetencionIEPS").row.classed("hide", !aplicaInteres).select("input").property("value", 0);
                                        form._ControlsData.get("RetencionISR").row.classed("hide", !aplicaInteres).select("input").property("value", 0);
                                        fnCalcularTotal(form);
                                    },
                                    onload: () => setTimeout(() => {
                                        if (datoInicial.AplicaIntereses) return;
                                        form._ControlsData.get("TrasladoIVA").row.classed("hide", true);
                                        form._ControlsData.get("TrasladoIEPS").row.classed("hide", true);
                                        form._ControlsData.get("RetencionIVA").row.classed("hide", true);
                                        form._ControlsData.get("RetencionIEPS").row.classed("hide", true);
                                        form._ControlsData.get("RetencionISR").row.classed("hide", true);
                                    })
                                }
                            },
                            {
                                model: "TrasladoIVA",
                                type: "input",
                                labelText: "tag_iva",
                                inputAttr: { type: "currency", min: 0, step: "0.01", value: "0", onchange: () => fnCalcularTotal(form), }
                            },
                            {
                                model: "TrasladoIEPS",
                                type: "input",
                                labelText: "tag_ieps",
                                inputAttr: { type: "currency", min: 0, step: "0.01", value: "0", onchange: () => fnCalcularTotal(form), }
                            },
                            {
                                model: "RetencionIVA",
                                type: "input",
                                labelText: "d_field_retencioniva",
                                inputAttr: { type: "currency", min: 0, step: "0.01", value: "0", onchange: () => fnCalcularTotal(form), }
                            },
                            {
                                model: "RetencionIEPS",
                                type: "input",
                                labelText: "d_field_retencionieps",
                                inputAttr: { type: "currency", min: 0, step: "0.01", value: "0", onchange: () => fnCalcularTotal(form), }
                            },
                            {
                                model: "RetencionISR",
                                type: "input",
                                labelText: "d_field_retencionisr",
                                inputAttr: { type: "currency", min: 0, step: "0.01", value: "0", onchange: () => fnCalcularTotal(form), }
                            },
                            //#endregion INTERESES END
                            {
                                model: "Total",
                                type: "input",
                                labelText: "tag_total",
                                inputAttr: {
                                    type: "currency", value: "0", min: 0, step: "0.01",
                                    removeBorder: true, disabled: true,
                                }
                            },
                            {
                                model: "FechaAplicacion",
                                type: "input",
                                labelText: "d_field_fechaaplicacion",
                                inputAttr: {
                                    type: "date",
                                    value: UIUtilTime._FmtToInputDate(new Date()),
                                    max: UIUtilTime._FmtToInputDate(new Date()),
                                    required: true,
                                    onchange: (e) => {
                                        const fechaAplicacionInput = e.target as HTMLInputElement;
                                        const fechaVencimientoInput = form._ControlsData.get("FechaVencimiento").selection.node() as HTMLInputElement;
                                        const fechaPagoInput = form._ControlsData.get("FechaPago").selection.node() as HTMLInputElement;
                                        if (!fechaAplicacionInput.value) return;
                                        fechaVencimientoInput.min = fechaAplicacionInput.value;
                                        // if (!fechaVencimientoInput.value || fechaEmisionInput.valueAsDate > fechaVencimientoInput.valueAsDate) {
                                        //     fechaVencimientoInput.value = fechaEmisionInput.value;
                                        // }
                                        fechaPagoInput.min = fechaAplicacionInput.value;
                                        fechaPagoInput.max = UIUtilTime._FmtToInputDate(new Date());
                                        // fechaPagoInput.value = UIUtilTime._FmtToInputDate(new Date());
                                    }
                                }
                            },
                            {
                                model: "FechaVencimiento",
                                type: "input",
                                labelText: "d_field_fechavencimiento",
                                inputAttr: { type: "date", min: UIUtilTime._FmtToInputDate(new Date()), }
                                // value: UIUtilTime._FmtToInputDate(new Date()),}
                            },
                            {
                                model: "Pagado",
                                type: "input",
                                labelText: "tag_pagado",
                                inputAttr: {
                                    type: "checkbox",
                                    onchange: (e) => {
                                        const pagado = (e.target as HTMLInputElement).checked;
                                        const controlCtaPago = form._ControlsData.get("IdCuentaPago")
                                        controlCtaPago.row
                                            .classed("hide", !pagado)
                                            .node()
                                            .scrollIntoView({ behavior: "smooth" });
                                        controlCtaPago.selection.attr("required", pagado ? "" : null)
                                        // .select("input");
                                        let controlFechaPago = form._ControlsData.get("FechaPago")
                                        controlFechaPago.row
                                            .classed("hide", !pagado);
                                        controlFechaPago.selection.property("required", pagado);
                                    }
                                }
                            },
                            {
                                model: "IdCuentaPago",
                                type: "selectMaterial",
                                labelText: "tag_cuenta",
                                selectMaterialAttr: {
                                    valueMember: <keyof ICuentaBancaria>("Id"),
                                    displayMember: <keyof ICuentaBancaria>("Beneficiario"),
                                    ShowAndEnableSearchText: true,
                                    OnChangeSearchText_GetDataValueToEval: (dato: ICuentaBancaria) => (dato.Numero + dato.Beneficiario + dato.getTipo + (dato.NombreBanco || "")),
                                    OnStepItemListUI(container, dato: ICuentaBancaria, step) {
                                        if (dato.Id != 0)
                                            container
                                                .style("display", "block")
                                                .html(""
                                                    + `<b>${LangString(_CTABBANCARIA_STRINGKEY, "tag_beneficiario")}:</b> ${dato.Beneficiario}<br>`
                                                    + `<b>${LangString(_CTABBANCARIA_STRINGKEY, "d_field_tipo")}:</b> ${dato.getTipo}`
                                                    + (dato.Numero ? `<br><b>${LangString(_CTABBANCARIA_STRINGKEY, "d_field_numero")}:</b> ${dato.Numero}` : "")
                                                    + (dato.NombreBanco ? `<br><b>${LangString(_CTABBANCARIA_STRINGKEY, "d_field_nombrebanco")}:</b> ${dato.NombreBanco}` : "")
                                                )
                                        else
                                            container.text(dato.Numero);
                                    },
                                    Data: () => fnGetCuentas(form._Data.IdEscuela),
                                },
                            },
                            {
                                model: "FechaPago",
                                type: "input",
                                labelText: "tag_fechapago",
                                inputAttr: {
                                    type: "date",
                                    value: UIUtilTime._FmtToInputDate(new Date()),
                                    min: UIUtilTime._FmtToInputDate(new Date()),
                                    max: UIUtilTime._FmtToInputDate(new Date()),
                                }
                            },
                        ],
                        BuildView: (container, controls) => {
                            const fnRow = (wrapper: TSelectionHTML<"htmlelement">, model: keyof IEgresoForm) => wrapper.append(() => (controls.get(model).row.node()));
                            container.classed("frm_egreso_manual", true)
                            container.append("div")
                                .call(wrapper => {
                                    wrapper.append("b").text(LangString("general", "general"));
                                    fnRow(wrapper, "IdEscuela");
                                    fnRow(wrapper, "IdProveedor");
                                    fnRow(wrapper, "Folio");
                                    fnRow(wrapper, "IdEgresoPlantilla");
                                    fnRow(wrapper, "Descripcion");
                                    fnRow(wrapper, "TipoEgreso");
                                    fnRow(wrapper, "IdCategoria");

                                    fnRow(wrapper, "FechaAplicacion");
                                    fnRow(wrapper, "FechaVencimiento");
                                })

                            container.append("div")
                                .call(wrapper => {
                                    wrapper.append("b").text(LangString(_EGRESO_LANGKEY, "d_field_valorcargo"));
                                    fnRow(wrapper, "SubTotal");
                                    fnRow(wrapper, "Descuento");

                                    fnRow(wrapper, "AplicaIntereses");
                                    fnRow(wrapper, "TrasladoIVA");
                                    fnRow(wrapper, "TrasladoIEPS");
                                    fnRow(wrapper, "RetencionIVA");
                                    fnRow(wrapper, "RetencionIEPS");
                                    fnRow(wrapper, "RetencionISR");

                                    fnRow(wrapper, "Total");

                                    fnRow(wrapper, "Pagado");
                                    fnRow(wrapper, "IdCuentaPago");
                                    fnRow(wrapper, "FechaPago");
                                })
                        }
                    }, datoInicial);

                    form._ControlsData.get("Descripcion").row.classed("hide", true);
                    form._ControlsData.get("IdCuentaPago").row.classed("hide", true);
                    form._ControlsData.get("FechaPago").row.classed("hide", true);
                    if (esEditar) {
                        form._ControlsData.get("Pagado").row.classed("hide", true); // Editar sin opción para marcar como pagado
                    }

                    if (datoInicial.IdEscuela) {
                        fnReqEscuelaModules(datoInicial.IdEscuela)
                            .then((success) => {
                                if (!success) {
                                    mt.Modal._Ocultar();
                                    return;
                                }
                                (form._ControlsData.get("IdEgresoPlantilla").instance as SelectV2)._RefreshList();
                                (form._ControlsData.get("IdProveedor").instance as SelectV2)._RefreshList();
                                (form._ControlsData.get("IdCategoria").instance as SelectV2)._RefreshList();
                                (form._ControlsData.get("IdCuentaPago").instance as SelectV2)._RefreshList();
                                form._AsignaData(datoInicial);
                                fnCalcularTotal(form);
                            });
                    }

                    return form;
                }
            })
        });
    }

    export function _CargarArchivos(idMovimiento: number) {
        return new Promise<boolean>((resolve, reject) => {
            let controls: UIUtilViewFinanzaEgresoUtil.IControlArchivoWrapperResult;
            ModalThings._GetModalToAProccess({
                LangModuleKeyInContext: _EGRESO_LANGKEY,
                Title: "tag_documentacion",
                Width: 570,
                OnClose: () => resolve(false),
                DrawContent: (container, mt) => {
                    mt.BtnLeft.remove();
                    mt.BtnRight.text(LangString("general", "save"));
                    mt.Modal._BodySelection.style("min-height", "260px");
                    Button._EnableButton(mt.BtnRight, false);
                    controls = UIUtilViewFinanzaEgresoUtil._ControlArchivosWrapper({
                        Container: container,
                        IdMovimiento: idMovimiento,
                        OnChange: (nFiles) => {
                            Button._EnableButton(mt.BtnRight, true)
                        },
                        OnError: () => {
                            mt.Modal._Ocultar();
                            MostrarNotificacion(LangString("general", "notif_fail"), "ADVERTENCIA");
                        }
                    });
                },
                OnAccept: async (mt) => {
                    const res = await DataModuloEgresoMovimiento._ActualizarDocumentacion(idMovimiento, controls.Nuevos(), controls.IdsRealesEliminados());
                    if (res.Resultado > 0) resolve(true)
                    return res;
                }
            })
        })
    }
}
