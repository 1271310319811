import { select } from 'd3'
import { CLicenciaModuloExtra, ILicenciaConfigOrganizacion, ILicenciaPlantilla } from '../../data/entidad/Licencia'
import { _SvLicenciaActivarOrganizacion, _SvLicenciaObtenerPlantillas } from '../../data/modulo/Licencia'
import { DataUtil } from '../../data/util/Util'
import { Main } from '../../Main'
import _L, { _FixHTMLTags } from '../../util/Labels'
import { ModalThings } from '../controlD3/ModalThings'
import { HTMLProgressElement } from '../controlWC/ProgressComponent'
import licenciaViewHTML from '../template/plan_licenciamiento.html?raw'
import { UIUtilElement } from '../util/Element'
import { UIUtilFormat } from '../util/Format'
import { UIWindowManager } from '../ventana/WindowManager'

export class PlanLicenciaView {
    private view: HTMLDivElement
    private planesContainer: HTMLDivElement
    private planPreconfigurado: ILicenciaConfigOrganizacion

    constructor(planPreconfigurado: ILicenciaConfigOrganizacion | null) {
        if (!planPreconfigurado?.IdConfig) {
            planPreconfigurado = null
        }
        this.planPreconfigurado = planPreconfigurado
        UIUtilElement._AppendHTML(document.body, _FixHTMLTags(licenciaViewHTML))
        this.view = document.body.querySelector(".plan_licenciamiento") as HTMLDivElement
        const view = this.view

        if (!!planPreconfigurado) {
            view.querySelector("pre[subject]").textContent = _L("planlicenciamiento.description_pre")
        }

        const itemLicTemplate = view.querySelector(".item") as HTMLDivElement
        this.planesContainer = itemLicTemplate.parentElement as HTMLDivElement
        itemLicTemplate.remove()

        const btnLogout = view.querySelector(".btn_logout") as HTMLButtonElement
        btnLogout.onclick = () => {
            Main._Logout()
            this.EnableAllButtons(false)
            //  UIUtilIconResources.CGeneral.Logout
        }

        const btnSeeMore = view.querySelector(".btn_see_more") as HTMLButtonElement
        btnSeeMore.onclick = () => {
            const appsInfo = view.querySelector(".apps_content") as HTMLDivElement
            if (appsInfo.classList.contains("see_more")) {
                appsInfo.classList.remove("see_more")
                btnSeeMore.textContent = _L("planlicenciamiento.see_more")
            }
            else {
                appsInfo.classList.add("see_more")
                btnSeeMore.textContent = _L("planlicenciamiento.see_min")
            }
        }

        if (!!planPreconfigurado) {
            this.BuildItemPlan(itemLicTemplate, planPreconfigurado)
            return
        }

        const progressBar = document.createElement("wc-progress") as HTMLProgressElement
        progressBar.style.height = "3px"
        view.append(progressBar)

        _SvLicenciaObtenerPlantillas()
            .then((res) => {
                if (res.Resultado <= 0) {
                    throw new Error("*")
                }
                res.Datos
                    .sort((a, b) => a.CostoUnitario - b.CostoUnitario)
                    .forEach((d, i) => {
                        this.BuildItemPlan(itemLicTemplate, d)
                    })
            })
            .catch((e) => {
                console.warn(e)
            })
            .finally(() => progressBar.remove())

    }

    private BuildItemPlan(itemLicTemplate: HTMLDivElement, d: ILicenciaPlantilla | ILicenciaConfigOrganizacion) {
        const itemFinalElement = itemLicTemplate.cloneNode(true) as HTMLDivElement
        itemFinalElement.querySelector("*[lic_name]").textContent = d.Nombre
        // itemFinalElement.querySelector("*[lic_desc]").textContent = d.Nombre
        if (d.IVA)
            itemFinalElement.querySelector("*[lic_precio]").innerHTML = _L("planlicenciamiento.costounidad_iva", UIUtilFormat._CurrencyFmt(d.CostoUnitario))
        else
            itemFinalElement.querySelector("*[lic_precio]").innerHTML = _L("planlicenciamiento.costounidad", UIUtilFormat._CurrencyFmt(d.CostoUnitario))

        if (!d.ModulosExtra.includes(CLicenciaModuloExtra.Finanzas)) {
            itemFinalElement.querySelector("div[lic_finanzas]")?.remove()
        }
        const btn = itemFinalElement.querySelector("button")
        if (DataUtil._Usuario._PerfilAdmin)
            btn.onclick = e => this.OnSelectedPlan(itemFinalElement, d)
        else {
            btn["_forcedisabled_"] = true
            btn.disabled = true
            itemFinalElement.append((() => {
                const tagInfo = document.createElement("div")
                tagInfo.style.color = "var(--color_app_pink1)"
                tagInfo.textContent = _L("planlicenciamiento.soloadmin")
                return tagInfo
            })())
        }

        this.planesContainer.appendChild(itemFinalElement)
    }

    private OnSelectedPlan(view: HTMLDivElement, d: ILicenciaPlantilla | ILicenciaConfigOrganizacion) {
        this.OpenModal_Activar(d)
        if (view.classList.contains("selected")) {
            return
        }
        view.classList.add("selected")
        select(this.planesContainer).selectAll<HTMLDivElement, any>(":scope>.item").each((_, i, divs) => {
            const div = divs[i]
            if (div.classList.contains("selected") && div !== view)
                div.classList.remove("selected")
        })
    }

    private EnableAllButtons(enable: boolean) {
        select(this.view).selectAll<HTMLButtonElement, any>("button").each((_, i, btns) => {
            const btn = btns[i]
            if (btn["_forcedisabled_"]) return
            btn.disabled = !enable
            if (enable && btn["fnActive"]) {
                btn.onclick = btn["fnActive"]
                delete btn["fnActive"]
            } else if (!enable && !btn["fnActive"]) {
                btn["fnActive"] = btn.onclick
                btn.onclick = null
            }
        })
    }

    private OpenModal_Activar(lic: ILicenciaPlantilla | ILicenciaConfigOrganizacion) {
        let success = false
        ModalThings._GetModalToAProccess({
            Title: _L("planlicenciamiento.activar") + ": " + lic.Nombre,
            Width: 420,
            DrawContent: (content, mt) => {
                let str: string
                this.EnableAllButtons(false)
                if (lic.IVA) {
                    str = _L("planlicenciamiento.activar_confirma_iva", lic.Nombre, UIUtilFormat._CurrencyFmt(lic.CostoUnitario))
                } else {
                    str = _L("planlicenciamiento.activar_confirma", lic.Nombre, UIUtilFormat._CurrencyFmt(lic.CostoUnitario))
                }
                content.html(str);
                (mt.Modal._ModalSelection.node().parentNode as HTMLElement).style.zIndex = "10000";
                mt.BtnLeft.remove()
            },
            OnClose: () => {
                if (!success)
                    setTimeout(() => this.EnableAllButtons(true), 100)
            },
            OnAccept: async () => {
                const idLicenciaPlantilla = lic !== this.planPreconfigurado ? (lic as ILicenciaPlantilla).Id : 0;
                return _SvLicenciaActivarOrganizacion(idLicenciaPlantilla)
                    .then((res) => {
                        if (res.Resultado > 0) {
                            success = true
                            setTimeout(() => (
                                UIWindowManager._DoHash("home"),
                                window.location.reload()
                            ), 3000)
                        }
                        return res
                    })
            }
        })
    }
}