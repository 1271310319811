import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { IConfiguracionFacturacion } from "../../data/entidad/Factura";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela, { _EscuelaConfigurarFacturacion, _ObtenerEscuelaConfiguracionFacturacion, _SvEscuelaAgregarDiaNoLectivo, _SvEscuelaDiasNoLectivos, _SvEscuelaEditarDiaNoLectivo, _SvEscuelaEliminarDiaNoLectivo } from "../../data/modulo/Escuela";
import DataModuloEscuelaAlumnoInfoExtra from "../../data/modulo/EscuelaAlumnoInfoExtra";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { DateV2 } from "../../util/DateV2";
import _L, { _HttpMsg, _HttpMsgV2 } from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { CheckBox } from "../controlD3/CheckBox";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator, IControlCreated } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { HTMLCheckBoxElement } from "../controlWC/CheckboxComponent";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewEscuelas } from "../utilView/Escuelas";
import { UIUtilViewGrupos } from "../utilView/Grupos";

import CAccionPermiso = Entidad.CAccionPermiso;
import CFormModels = UIUtilViewEscuelas.CFormModels;
import { UIUtilMask } from "../util/Mask";

type IEscuelaForm = UIUtilViewEscuelas.IEscuelaForm;
type IAlumno = Entidad.IAlumno;
type IDiaNoLectivo = Entidad.IEscuelaDiaNoLectivo;
interface IGridExtraColumns {
    TagNivel: string | number;
    TagGrado: string | number;
    TagGrupo: string | number;
    TagAlumno: string | number;
    Alumnos: Map<number, IAlumno>;
}
type IGrupo = {}
    & Entidad.IGrupo
    & IGridExtraColumns

type IGrado = {
    Grupos: IGrupo[]; // Map<number, IGrupo>;
}
    & Entidad.IGrado
    & IGridExtraColumns

type IEscolaridad = {
    Grados: IGrado[]; // Map<number, IGrado>;
}
    & Entidad.IEscolaridad
    & IGridExtraColumns

type IEscuela = {
    Niveles: IEscolaridad[]; // Map<number, IEscolaridad>;
}
    & Entidad.IEscuela
    & IGridExtraColumns

export class UIVentanaEscuelas extends VentanaGrid<IEscuela> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, {
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.HorarioAlumno],
        });

        const tr = this.ctrlTabla._Control
            .classed("tbl_gridescuelas", true)
            .select(".rhead")
            .select("tr");

        /* tr
            .select(".collapsercell")
            .classed("hide", true); */

        /* tr
            .select("th:nth-child(3)")
            .style("width", "calc(20% + 40px)"); */
    }

    private GetForm(action: (CAccionPermiso.Agregar | CAccionPermiso.Editar), datoE = <IEscuela>{}) {
        let dataForm: IEscuelaForm = Object.assign({}, datoE);
        let form = new FormGenerator<IEscuelaForm>();
        let esEditar = (action == CAccionPermiso.Editar);

        // >> Flags Logo and LatLong change
        dataForm.IsChangedLogo = false;
        dataForm.IsChangedLatLong = false;

        if (esEditar) {
            // >> PREPARACIÓN DE DATOS

            // >> Secciona la dirección
            let splitDirection = (dataForm?.Direccion ? dataForm.Direccion : "").split("\n");

            if (splitDirection.length == 8) {
                dataForm.NumInterior = splitDirection[0];
                dataForm.Calle = splitDirection[1];
                dataForm.NumExterior = splitDirection[2];
                dataForm.CodigoPostal = splitDirection[3];
                dataForm.Ciudad = splitDirection[4];
                dataForm.Estado = splitDirection[5];
                dataForm.Pais = splitDirection[6];
                dataForm.LatLong = splitDirection[7];
            }

            // >> Preparación de días de operación
            dataForm.DiasOperacion = [];
            dataForm.HoraEntradas?.forEach((hr, i) => {
                if (hr) {
                    dataForm.DiasOperacion.push(i + 1);
                    dataForm.Entrada = hr.slice(0, 5);
                    dataForm.Salida = dataForm.HoraSalidas[i].slice(0, 5);
                }
            });
        }

        form._Crear({
            LangModuleKeyInContext: this.labelsKeyBase,
            schema: UIUtilViewEscuelas._Form_GetSchemaControls(form),
            BuildView: (container, controlsForm, form) => this.Form_OnBuildView(container, controlsForm, form),
            Validation: (value, field, allObject, controls) => {
                const getIsValido = (val) => (Boolean(val) && Boolean(String(val).trim()));

                if (field == CFormModels.Direccion) {
                    // NOTE Validacion: La zona horaria no puede editarse cuando `esEditar`
                    // FIXME Temporalmete se valida en el modal de Editar
                    return getIsValido(form._DataOrigin.LatLong);
                }
                else if (field == CFormModels.DiasOperacion) {
                    const diasOperaEsc = form._DataOrigin[CFormModels.DiasOperacion];
                    if (getIsValido(diasOperaEsc) && diasOperaEsc.length) {
                        if (dataForm.IdKinder) {
                            const grupos = DataModuloEscuela._LOCALDATA_GetGruposEnEscuela(dataForm.IdKinder);

                            for (const [_, grupo] of grupos) {
                                for (const grupoDia of grupo.Horario) {
                                    if (!diasOperaEsc.find(dia => (grupoDia.IdDia == dia))) {
                                        this.notificacion._Mostrar(
                                            this.VB_GetUIStringModule("notif_err_horariodia")
                                                .replace("_DIA", UIUtilTime._GetDayName(grupoDia.IdDia).toLowerCase())
                                                .replace("_GRUPO", UIUtilViewGrupos._GetLblGrupoName(grupo)),
                                            "ADVERTENCIA"
                                        );
                                        // console.warn(grupo, grupoDia, diasOperaEsc);
                                        return false;
                                    }
                                }
                            }

                            return true;
                        }
                        return true;
                    }
                    return false;
                }
                // else if (field == CFormModels.Correo) {
                // return Utils.emailRegex.test(value);
                // }
                else if (field == CFormModels.Entrada || field == CFormModels.Salida) {
                    // >> Valida que la hora de salida sea mayor que la de entrada
                    if (!getIsValido(value)) {
                        return _L("control.form.required");
                    }
                    const inputHrEntrada = (controls.get(CFormModels.Entrada).selection as TSelectionHTML<"input">).node();
                    const inputHrSalida = (controls.get(CFormModels.Salida).selection as TSelectionHTML<"input">).node();

                    if (inputHrSalida.valueAsNumber > inputHrEntrada.valueAsNumber) {
                        if (dataForm.IdKinder) {
                            const timeZone = form._Data.ZonaHoraria;
                            const diasOperaEsc = form._DataOrigin.DiasOperacion;
                            const grupos = DataModuloEscuela._LOCALDATA_GetGruposEnEscuela(dataForm.IdKinder);
                            for (const [_, grupo] of grupos) {
                                for (const grupoDia of grupo.Horario) {
                                    if (diasOperaEsc.find(dia => (dia == grupoDia.IdDia))) {
                                        let dtEntradaGrupo = new DateV2(grupoDia.Entrada)._SetTimeZone(timeZone);
                                        let dtSalidaGrupo = new DateV2(grupoDia.Salida)._SetTimeZone(timeZone);
                                        let dtDiaEscHrEntrada = new DateV2(dtEntradaGrupo)
                                        dtDiaEscHrEntrada.setHours(0, 0, 0, 0);
                                        dtDiaEscHrEntrada._SetLocalStrHour(inputHrEntrada.value);
                                        let dtDiaEscHrSalida = new DateV2(dtSalidaGrupo)
                                        dtDiaEscHrSalida.setHours(0, 0, 0, 0);
                                        dtDiaEscHrSalida._SetLocalStrHour(inputHrSalida.value);

                                        if ((field == CFormModels.Entrada) && (dtDiaEscHrEntrada > dtEntradaGrupo)) {
                                            this.notificacion._Mostrar(
                                                this.VB_GetUIStringModule("notif_err_hrentrada")
                                                    .replace("_DIA", UIUtilTime._GetDayName(grupoDia.IdDia).toLowerCase())
                                                    .replace("_GRUPO", UIUtilViewGrupos._GetLblGrupoName(grupo)),
                                                "ADVERTENCIA"
                                            );
                                            return false;
                                        }
                                        else if ((field == CFormModels.Salida) && (dtDiaEscHrSalida < dtSalidaGrupo)) {
                                            this.notificacion._Mostrar(
                                                this.VB_GetUIStringModule("notif_err_hrsalida")
                                                    .replace("_DIA", UIUtilTime._GetDayName(grupoDia.IdDia).toLowerCase())
                                                    .replace("_GRUPO", UIUtilViewGrupos._GetLblGrupoName(grupo)),
                                                "ADVERTENCIA"
                                            );
                                            return false;
                                        }
                                    }
                                }
                            }
                        }
                        return true;
                    } else {
                        let mensaje = this.VB_GetUIStringModule("notif_err_horario");
                        if (field == "Entrada") {
                            this.notificacion._Mostrar(mensaje, "ADVERTENCIA");
                        }
                        // this.notificacion.fn_Mostrar("La hora de salida debe ser mayor a la hora de entrada", controlD3.Notificacion.CTipoNotificacion.ADVERTENCIA);
                        return false;
                    }
                }
                else if (controls.get(field).type == Fields.input) {
                    // console.log(field, value)
                    let input = controls.get(field).selection.node() as HTMLInputElement;
                    if (input.validity.valid) {
                        if (field == CFormModels.NumeroInterior || field == CFormModels.NumeroExterior || field == CFormModels.CodigoPostal) {
                            return !isNaN(Number(value));
                        }
                        return true;
                    }
                    return false;
                } else {
                    return getIsValido(value);
                }
            }
        }, dataForm);

        if (esEditar) {
            // >> Ajuste de controles a los que el formulario no tiene acceso directo

            let latLngSplit = dataForm.LatLong?.split(",");
            if (latLngSplit?.length == 2) {
                let control = form._ControlsData.get(CFormModels.Direccion);
                let map = (control.instance as any) as google.maps.Map;
                let marker = (control["_marker"] as google.maps.Marker);

                let initialLocation = new google.maps.LatLng(Number(latLngSplit[0]), Number(latLngSplit[1]));
                map.setCenter(initialLocation);
                map.setZoom(15);
                marker.setPosition(initialLocation);
                marker.setTitle(dataForm.Nombre);
            }

            let controlDias = form._ControlsData.get(CFormModels.DiasOperacion);
            controlDias["_AssingData"](dataForm.DiasOperacion);
        }

        return form;
    }

    private Form_OnBuildView(container: TSelectionHTML<"form">, controlsForm: Map<keyof IEscuelaForm, IControlCreated<any>>, form: FormGenerator<IEscuelaForm>) {
        // if (this.configModal?.TipoModal == controlD3.CTipoModal.Agregar || this.configModal?.TipoModal == controlD3.CTipoModal.Editar) {
        container
            .classed("escuela_infoedit", true)
            .style("flex-direction", "row")
            .style("gap", "30px")
            .html(`
                        <div>
                            <div class="row_1">
                                <div class="sub_area"><h3>${this.VB_GetUIStringModule("frm1_sectiontag_conf")}</h3> </div>
                            </div>
                            <div class="row_5">
                                <div class="sub_area"><h3>${this.VB_GetUIStringModule("frm1_sectiontag_hor")}</h3> </div>
                            </div>
                        </div>
                        <div>
                            <div class="row_2">
                                <div class="sub_area"><h3>${this.VB_GetUIStringModule("frm1_sectiontag_hor")}</h3> </div>
                            </div>
                            <div class="row_4 ${UIUtilGeneral.FBoxOrientation.Vertical}"></div>
                        </div>
                        `)

        let ObtenerInformacionDeUbicacion = async (lat: number, lng: number) => {
            let res = await DataModuloEscuela._GetDirectionInformation((lat + "," + lng), UIUtilLang._GetLanguage());
            if (res.Resultado == -1) {
                // this.notificacion.fn_Mostrar("Ha ocurrido un error al intentar obtener los datos de ubicación", controlD3.Notificacion.CTipoNotificacion.ADVERTENCIA);
                return null;
            }
            return res.Data.results;
        }

        const UpdateControlsLocation = async (geocoder_place_result: google.maps.GeocoderResult | google.maps.places.PlaceResult, lat: number, lng: number, control: IControlCreated<Fields>) => {
            // console.log(geocoderResult, "geocoderResult");
            let latlng = (lat + "," + lng);

            if (geocoder_place_result?.address_components?.length > 0) {
                form._DataOrigin.LatLong = latlng;
                form._DataOrigin.IsChangedLatLong = true;
                // (form["_DatoEscuela"] as IDataEscuela).Direccion = latlng;

                const AsignaValores = (type: string, keyDato: keyof IEscuelaForm) => {
                    let componet = geocoder_place_result.address_components.find(d => d.types.indexOf(type) > -1);
                    if (componet) {
                        controlsForm.get(keyDato)?.selection?.property("value", componet.long_name);
                        if (keyDato == CFormModels.Pais) form._DataOrigin.CodigoPais = componet.short_name;
                    } else {
                        controlsForm.get(keyDato)?.selection?.property("value", "");
                        if (keyDato == CFormModels.Pais) form._DataOrigin.CodigoPais = "";
                    }
                }

                AsignaValores("", CFormModels.NumeroInterior);
                AsignaValores("street_number", CFormModels.NumeroExterior);
                AsignaValores("route" /* street_address */, CFormModels.Calle);
                AsignaValores("postal_code", CFormModels.CodigoPostal);
                AsignaValores("locality", CFormModels.Ciudad);
                AsignaValores("administrative_area_level_1", CFormModels.Estado);
                AsignaValores("country", CFormModels.Pais);
            }
        }

        const AppendUI = (idLocation: (1 | 2 | 3 | 4 | 5), idModel: (keyof IEscuelaForm), rowVertical = false) => {
            let control = controlsForm.get(idModel);
            // console.log("Control on build", idModel, Boolean(control));

            if (control) {
                container.select(".row_" + idLocation)
                    .append(() => control.row.node());

                control.row
                    .style("flex-direction", (rowVertical ? "column" : null), "important")
                    .style("row-gap", (rowVertical ? "10px" : null))

                if (idModel == CFormModels.Direccion) {
                    control.row
                        .classed("map_row", true)
                        .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                        .style("align-items", "flex-start")
                        .style("row-gap", "15px")
                        .style("flex-wrap", "wrap")
                        .style("flex-direction", "column")
                        .style("border-radius", "5px");

                    const mapDiv = control.row.append("div")
                        .attr("id", "map")
                        .style("width", "100%")
                        .style("height", "200px");

                    const inputSearch = control.row.append("div")
                        .classed("search_wrapper input_content", true)
                        .style("width", "60%")
                        .append("input")
                        .attr("type", "text")
                        .attr("placeholder", UIUtilLang._GetUIString("general", "buscar"))
                        .classed("input-form", true);

                    const myLatlng = new google.maps.LatLng(21.1437, -98.4181);

                    const mapOptions = <google.maps.MapOptions>{
                        zoom: 6,
                        center: myLatlng,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: false
                    };

                    const map = new google.maps.Map(mapDiv.node(), mapOptions);

                    const markerLocation = new google.maps.Marker({
                        position: null,
                        map: map,
                        title: "School location"
                    });

                    const searchBox = new google.maps.places.SearchBox(inputSearch.node());
                    // map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputSearch); // FIXME

                    // Bias the SearchBox results towards current map's viewport.
                    map.addListener("bounds_changed", () => {
                        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
                        // console.log("google maps - Bounds changed...", map.getBounds());
                    });

                    let markersSearchResult: google.maps.Marker[] = [];
                    searchBox.addListener("places_changed", () => {
                        const places = searchBox.getPlaces();

                        if (places.length == 0) {
                            return;
                        }

                        // Clear out the old markers.
                        markersSearchResult.forEach((marker) => {
                            marker.setMap(null);
                        });
                        markersSearchResult = [];

                        // Apply selected place data to school data form
                        const isAPlaceSelected = (places.length == 1);
                        if (isAPlaceSelected) {
                            let latlong = places[0].geometry.location;
                            map.setCenter(latlong);
                            map.setZoom(18);
                            markerLocation.setPosition(latlong);
                            UpdateControlsLocation(places[0], latlong.lat(), latlong.lng(), control);
                            return;
                        }

                        // For each place, get the icon, name and location.
                        const bounds = new google.maps.LatLngBounds();
                        places.forEach((place) => {
                            if (!place.geometry || !place.geometry.location) {
                                console.log("Returned place contains no geometry");
                                return;
                            }

                            if (!isAPlaceSelected) {
                                const icon = {
                                    url: place.icon as string,
                                    size: new google.maps.Size(71, 71),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(17, 34),
                                    scaledSize: new google.maps.Size(25, 25),
                                };

                                // Create a marker for each place.
                                markersSearchResult.push(
                                    new google.maps.Marker({
                                        map,
                                        icon,
                                        title: place.name,
                                        position: place.geometry.location,
                                    })
                                );
                            }

                            if (place.geometry.viewport) {
                                // Only geocodes have viewport.
                                bounds.union(place.geometry.viewport);
                            } else {
                                bounds.extend(place.geometry.location);
                            }
                        });
                        map.fitBounds(bounds);
                    });

                    map.addListener("click", (e: google.maps.MapMouseEvent) => {
                        console.log(e, e.latLng.lat(), e.latLng.lng());
                        markerLocation.setPosition(e.latLng);

                        ObtenerInformacionDeUbicacion(e.latLng.lat(), e.latLng.lng())
                            .then(infoLocation => {
                                if (infoLocation?.length > 0) {
                                    UpdateControlsLocation(infoLocation[0], e.latLng.lat(), e.latLng.lng(), control);
                                } else if (form._DataOrigin?.LatLong) { // ((<IDataEscuela>form["_DatoEscuela"])?.LatLong) {
                                    let latlongTemp = form._DataOrigin?.LatLong.split(","); // (<IDataEscuela>form["_DatoEscuela"])?.LatLong?.split(",");
                                    if (latlongTemp?.length == 2) {
                                        markerLocation.setPosition(new google.maps.LatLng(Number(latlongTemp[0]), Number(latlongTemp[1])));
                                    }
                                }
                            });
                    });

                    // if (navigator.geolocation) { // NOTE ¿Descomentar?
                    //     console.log("control escuela en AGREGAR");
                    //     navigator.geolocation.getCurrentPosition((position) => {
                    //         let initialLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    //         map.setCenter(initialLocation);
                    //         marker.setPosition(initialLocation);

                    //         ObtenerInformacionDeUbicacion(position.coords.latitude, position.coords.latitude)
                    //             .then(infoLocation => {
                    //                 if (infoLocation) {
                    //                     UpdateControlsLocation(infoLocation, position.coords.latitude, position.coords.latitude);
                    //                 }
                    //             })
                    //     });
                    // }

                    control.instance = map as any; // google.maps.Map
                    control["_marker"] = markerLocation;
                    control.selection = mapDiv;
                }
                if (idModel == CFormModels.DiasOperacion) {
                    let contDays = control.row
                        .style("align-items", "flex-start")
                        .append("div")
                        .classed("cont_days", true)
                        .classed(UIUtilGeneral.FBoxOrientation.Vertical, true);

                    const UpdateChecked = (dataChecked: number[]) => {
                        let dataItems = UIUtilViewData._GetAllWeekDays()
                            .map(d => ({
                                Check: (dataChecked.find(dC => (dC == d.Id)) !== undefined),
                                Id: d.Id,
                                Name: d.Name
                            }))
                        type TItemDay = typeof dataItems[0];

                        const UpdateItemDay = (item: TSelectionHTML<"div", TItemDay>) => {
                            // Update Label
                            item.select("label").text(d => d.Name);
                            // Update CheckBox
                            let checkbox = item.select(".checkbox_table").each((d, i, arrCheck) => {
                                CheckBox._UpdateCheckStatus(d3.select(arrCheck[i]) as any, d.Check);
                            })
                            // Update Item click event
                            item.on("click", (d, i, arrElems) => {
                                d.Check = !d.Check;

                                CheckBox._UpdateCheckStatus(d3.select(checkbox.nodes()[i]) as any, d.Check);

                                form._DataOrigin.DiasOperacion = dataItems
                                    .filter(dItem => dItem.Check)
                                    .map(dItem => dItem.Id);
                            })
                        }

                        contDays.selectAll<HTMLDivElement, TItemDay>(".item_day")
                            .data(dataItems)
                            .join(
                                enter => {
                                    let itemDay = enter.append("div")
                                        .classed("item_day", true)
                                        .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                                        .classed(UIUtilGeneral.FBoxAlign.StartCenter, true);

                                    // itemDay.append<HTMLInputElement>("input").attr("type", "checkbox");
                                    itemDay.append(() => CheckBox._GetCheckElement().node());

                                    itemDay.append("label");

                                    UpdateItemDay(itemDay);
                                    return itemDay;
                                },
                                update => {
                                    UpdateItemDay(update);
                                    return update;
                                },
                                exit => exit.remove()
                            )
                    }

                    UpdateChecked([]);
                    control["_AssingData"] = UpdateChecked;
                    control.selection = contDays;
                }
            }
        }
        AppendUI(1, CFormModels.Logo);
        AppendUI(1, CFormModels.Nombre);
        AppendUI(1, CFormModels.Tamanio);
        AppendUI(1, CFormModels.Telefono);
        AppendUI(1, CFormModels.Correo);
        // AppendUI(1, CFormModels.TipoLicencia);

        AppendUI(2, CFormModels.Direccion);

        AppendUI(4, CFormModels.Calle);
        AppendUI(4, CFormModels.NumeroExterior);
        AppendUI(4, CFormModels.NumeroInterior);
        AppendUI(4, CFormModels.CodigoPostal);
        AppendUI(4, CFormModels.Ciudad);
        AppendUI(4, CFormModels.Estado);
        AppendUI(4, CFormModels.Pais);

        AppendUI(5, CFormModels.DiasOperacion); // DiasOperacion
        // appendUI("row_6", CFormModels.HorarioInicial);
        AppendUI(5, CFormModels.Entrada);
        AppendUI(5, CFormModels.Salida);
        // }
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<IEscuela>[] {
        return null;
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let acciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];
        if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
            acciones.push(...<Table.ITableMenuTopDefaultOptionConfig[]>[
                {
                    Label: "Agregar",
                    Callback: () => this.OpenModal_FormularioAgregar()
                },
                {
                    Label: "action_addlevel",
                    Callback: () => this.OpenModal_Escolaridad_FormularioAgregarEditar(),
                },
            ])
        }
        return acciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", escuelas: IEscuela[]): Array<Table.ITableMenuDataSelectedOptionConfig<IEscuela>> {
        let acciones: Array<Table.ITableMenuDataSelectedOptionConfig<IEscuela>> = [];
        if (escuelas.length) {
            // if (menuLocation == "inRow") {
            // if (!escuelas[0].CalendarioCfg && this.GridHasPermisoAccionV2(CAccionPermiso.Editar, escuelas.map(d => d.IdKinder))) {
            //     acciones.push({
            //         Label: "action_linkgoogleaccount",
            //         Callback: (dato) => {
            //             this.Open_VincularEscuela(dato[0].IdKinder, dato[0].Nombre);
            //         },
            //         MultiData: false,
            //     })
            // }
            // if (escuelas[0].CalendarioCfg && this.GridHasPermisoAccionV2(CAccionPermiso.Editar, escuelas.map(d => d.IdKinder))) {
            //     acciones.push({
            //         Label: "action_assigngooglecalendar",
            //         Callback: (dato) => {
            //             this.OpenModal_AsignarCalendarioGoogle(dato[0]);
            //         },
            //         MultiData: false,
            //     })
            // }
            // }
            if (this.GridHasPermisoAccionV2(CAccionPermiso.Editar, [escuelas[0]?.IdKinder])) {
                acciones.push({
                    Label: "Editar",
                    MultiData: false,
                    Callback: (dato) => {
                        this.OpenModal_FormularioEditar(dato[0]);
                    }
                })
            }
            if (this.GridHasPermisoAccionV2(CAccionPermiso.Editar, escuelas.map(d => d.IdKinder))) {
                acciones.push({
                    Label: "action_confinfoextra",
                    MultiData: false,
                    Callback: (escuelas) => this.OpenModal_ConfigurarInfoExtra(escuelas[0])
                })
            }
            if (this.GridHasPermisoAccionV2(CAccionPermiso.Editar, escuelas.map(d => d.IdKinder))) {
                acciones.push({
                    Label: (escuelas[0].UsaEntradaSalidaQR ? "tag_deshab_qr" : "tag_hab_qr"),
                    MultiData: false,
                    Callback: (escuelas) => this.OpenModal_ConfigurarEntradaSalidaQR(escuelas[0])
                });
            }
            if (this.GridHasPermisoAccionV2(CAccionPermiso.Editar, [escuelas[0]?.IdKinder])) {
                acciones.push({
                    Label: _L("escuelas.tag_diasnolectivos"),
                    MultiData: false,
                    Callback: (dato) => {
                        this.OpenModal_ConfigurarDiasNoLectivos(dato[0]);
                    },
                })
            }
            if (escuelas[0]?.UsaFactura) { //  && this.GridHasPermisoAccionV2(CAccionPermiso.Editar, [escuelas[0]?.IdKinder])
                acciones.push({
                    Label: "action_confifactura",
                    MultiData: false,
                    Callback: ([escuela]) => {
                        this.OpenModal_ConfigurarFacturacion(escuela.IdKinder);
                    }
                })
            }
            if (this.GridHasPermisoAccionV2(CAccionPermiso.Eliminar, escuelas.map(d => d.IdKinder))) {
                acciones.push({
                    Label: "Eliminar",
                    // MultiData: true,
                    Callback: (dato) => {
                        this.OpenModal_EliminarDatos(dato);
                    }
                })
            }
        }
        return acciones;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IEscuela> {
        return {
            IdTabla: "Escuelas",
            Title: "",
            DefaultSort: "Nombre",
            IdData: "IdKinder",
            MinWidth: 700,
            Columns: [
                { Field: "Nombre", Label: "Escuela", Width: "20%", MinWidth: "75px" },
                // { Field: "Direccion", Label: "Dirección", Width: "30%", MinWidth: "75px" },
                { Field: "TagNivel", Label: "Niveles", Width: "10%", MinWidth: "150px" },
                { Field: "TagGrado", Label: "Grados", Width: "10%", MinWidth: "75px" },
                { Field: "TagGrupo", Label: "Grupos", Width: "10%", MinWidth: "75px" },
                { Field: "TagAlumno", Label: "Alumnos activos", Width: "15%", MinWidth: "75px" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IEscuela> {
        const fnUpdateClickInRow = (datum: any, tr: TSelectionHTML<"tr">, titleKeyTag: string = null, level?: number) => {
            // >> Identa botones para colapsar
            const tab = 30;
            const startIdentPx = ((level - 2) * tab);
            const collCell = tr.select(".collapsercell")
                .style("position", "relative");

            collCell.select("wc-ic-collapse")
                .style("position", "absolute")
                .style("left", ((startIdentPx > 0) ? (startIdentPx + "px") : ""));

            // Evento de fila
            tr.on("click", () => {
                const controlMenuRow = this.ctrlTabla._MenuInRow;
                controlMenuRow._ControlContainer.classed("hide", true);
                setTimeout(() => {
                    controlMenuRow._ControlContainer.classed("hide", false);
                    if (controlMenuRow._IsVisible) {
                        const fnAjustaUbicacion = (oldParent: TSelectionHTML, newParent: TSelectionHTML) => {
                            controlMenuRow
                                ._OnParentChanged(null)
                                ._SetParent(this.ctrlTabla._Control.select(".area_acciones"))
                                ._OnParentChanged(fnAjustaUbicacion);
                        }

                        const controlMenuTop = this.ctrlTabla._MenuTopDefault;
                        if (!controlMenuTop._ControlContainer.classed("menu_top"))
                            controlMenuTop._ControlContainer.classed("menu_top", true);

                        controlMenuRow
                            ._OnParentChanged(fnAjustaUbicacion)
                            ._SetParent(this.ctrlTabla._Control.select(".area_acciones"))
                            ._SetOrientation("lefttoright")
                            ._SetDescription(this.VB_GetUIStringModule(titleKeyTag) + ":")
                            ._OnHide(() => {
                                if (!this.ctrlTabla._MenuTopOfDataChecked._IsVisible) {
                                    this.ctrlTabla._Control.classed("row_selected", false);
                                }
                            })
                            ._OnShow(() => {
                                this.ctrlTabla._Control.classed("row_selected", true);
                            })
                            ._Refresh()
                            ._ControlContainer
                            .style("background", "none");

                        controlMenuTop._Refresh();
                    }
                    if (controlMenuRow._IsVisible) {
                        this.ctrlTabla._Control.classed("row_selected", true);
                    }
                    else if (!this.ctrlTabla._MenuTopOfDataChecked._IsVisible) {
                        this.ctrlTabla._Control.classed("row_selected", false);
                    }
                }, 100);
            })
        }

        const fnUpdateTagWhitCheck = (container: TSelectionHTML<"div">, tag: string) => {
            if (!container.select("wc-checkbox").node()) {
                const checkbox = d3.create<HTMLCheckBoxElement>("wc-checkbox")
                    .attr("checked", "")
                    .attr("disabled", "")
                    .attr("style-form", "circle-check")

                if (!container.select("a").node()) {
                    container.text("").append("label");
                }
                container
                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                    .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
                    .style("white-space", "normal")
                    .node()
                    .insertAdjacentElement("afterbegin", checkbox.node());

                checkbox
                    // .classed("aux_selected", true)
                    .style("min-width", "18px")
                    .style("display", "none");

                container.select("a")
                    .style("overflow", "hidden")
                    .style("text-overflow", "ellipsis");
            }
            if (container.select("a").node())
                container.select("a").text(tag);
            else
                container.select("label").text(tag);
        }

        return {
            EnableRelevanceSelections: false,
            // HideItemWhenItHasNoChildItems: false,
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IEscuela, IEscolaridad, IGrado>>{
                // >> Nivel 1: Escuelas
                // ActiveDataOnlyWhenDisplayData: true,
                ShowNoContentTagRow: true,
                HideLevelCollapseIndicator: true,
                OnStepRowTable: (d, tr) => {
                    /* tr
                        .select(".collapsercell")
                        .classed("hide", true); */

                    /* tr.select("td:nth-child(3)")
                        .style("width", "calc(20% + 40px)"); */

                    fnUpdateClickInRow(d, tr, "tbltopmaintag_school");
                },
                OnStepCellTable: (container, dato, field: keyof IEscuela) => {
                    switch (field) {
                        case "Nombre":
                            fnUpdateTagWhitCheck(container, dato.Nombre);
                            break;
                        // case "Direccion":
                        //     container.text(Escuelas.fn_GetDirectionStr(dato));
                        //     break;
                        case "TagAlumno":
                            container.text(this.GetTagAlumnosActivos(Array.from(dato.Alumnos.values())));
                            break;
                    }
                },

                EvaluatorSubLevel: {
                    // >> Nivel 2: Niveles escolares (Escolaridades)
                    IdMember: "Id",
                    // ActiveDataOnlyWhenDisplayData: true,
                    ShowNoContentTagRow: true,
                    RemoveLevelCheckboxes: true,
                    OnGetData: (dato) => dato.Niveles,
                    OnStepRowTable: (d, tr) => fnUpdateClickInRow(d, tr, "tbltopmaintag_level", 2),
                    OnStepCellTable: (container, dato, field: keyof IEscuela) => {
                        // const tr = d3.select(container.node().parentElement.parentElement)
                        // const td3rd = tr.select("td:nth-child(3)")
                        // if (td3rd.node() == container.node().parentElement) {
                        //     const widthCollapserCell = tr.select<HTMLElement>(":scope > .collapsercell").node().clientWidth;
                        //     const actualWidth = this.ctrlTabla._ColumnsDimConfig.find(d => d.Field = field).Width;
                        //     td3rd.style("width", `calc(${actualWidth} - ${widthCollapserCell}px)`);
                        //     td3rd.style("min-width", `calc(${actualWidth} - ${widthCollapserCell}px)`)
                        // }
                        switch (field) {
                            case "Nombre":
                                container.text("");
                                break;
                            case "TagNivel":
                                fnUpdateTagWhitCheck(container, dato.TagNivel.toString());
                                break;
                            case "TagAlumno":
                                container.text(this.GetTagAlumnosActivos(Array.from(dato.Alumnos.values())));
                                break;
                        }
                    },
                    GetOptionsInRowV2: (escolaridad) => {
                        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IEscolaridad>> = [];
                        // let escuela = DataModuloEscuela._DiccFullEscuelas.get(escolaridad.IdEscuela);

                        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
                            opciones.push({
                                Label: UIUtilLang._GetUIString("c_actions", "editar"),
                                Callback: (datos: Array<IEscolaridad>) => {
                                    this.OpenModal_Escolaridad_FormularioAgregarEditar(datos[0]);
                                }
                            });
                        }

                        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
                            opciones.push({
                                Label: UIUtilLang._GetUIString("c_actions", "eliminar"),
                                Callback: (datos: Array<IEscolaridad>) => this.OpenModal_Escolaridad_EliminarDatos(datos),
                                GetDetails: (datos: Array<IEscolaridad>) => {
                                    let enable = datos.every(d => (DataModuloEscolaridad._LOCALDATA_GetGradosDeEscolaridad(d.Id).size == 0))
                                    return {
                                        Enabled: enable,
                                        Description: enable ? '' : this.VB_GetUIStringModule("tlt_eliminar_fail")
                                    }
                                }
                                /* {
                                    return datos.every(d => (Data.Modulo.Escolaridad.fn_LOCALDATA_GetGradosDeEscolaridad(d.Id).size == 0));
                                } */
                            });
                        }

                        // if (escuela.CalendarioCfg && this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
                        //     opciones.push({
                        //         Label: this.VB_GetUIStringModule("action_calfood"),
                        //         Callback: (dato: Array<IEscolaridad>) => {
                        //             UIUtilViewEscuelas._OpenModal_Escolaridad_AsignarAlimentosACalendarioGoogle(dato[0]);
                        //         },
                        //         MultiData: false,
                        //         // GetDetails: (datos) => (datos.length == 1)
                        //     });
                        // }

                        let grupos: Entidad.IGrupo[] = [];
                        DataModuloEscolaridad._LOCALDATA_GetGradosDeEscolaridad(escolaridad.Id)
                            .forEach(nivel => {
                                DataModuloGrado._LOCALDATA_GetGruposEnGrado(nivel.IdNivel)
                                    .forEach(grupo => grupos.push(grupo));
                            })
                        // if (escuela.CalendarioCfg && grupos.length > 0 && UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Editar, Entidad.CModulo.Grupos, escolaridad.IdEscuela)) {
                        //     opciones.push({
                        //         Label: this.VB_GetUIStringModule("action_calgroups"),
                        //         Callback: (dato) => {
                        //             UIUtilViewGrupos._OpenModal_AsignarCalendarioGoogle(grupos);
                        //         },
                        //         MultiData: false
                        //         //GetDetails: (datos) => (datos.length == 1)
                        //     })
                        // }

                        return {
                            MaxOptionsInRow: 5,
                            Options: opciones
                        };
                    },

                    EvaluatorSubLevel: {
                        // >> Nivel 3: Grados
                        IdMember: "IdNivel",
                        // ActiveDataOnlyWhenDisplayData: true,
                        ShowNoContentTagRow: true,
                        RemoveLevelCheckboxes: true,
                        OnGetData: (dato) => dato.Grados,
                        OnStepRowTable: (d, tr) => fnUpdateClickInRow(d, tr, "tbltopmaintag_grade", 3),
                        OnStepCellTable: (container, dato, field: keyof IEscuela) => {
                            switch (field) {
                                case "Nombre":
                                    container.text("");
                                    break;
                                case "TagGrado":
                                    fnUpdateTagWhitCheck(container, dato.TagGrado.toString());
                                    break;
                                case "TagAlumno":
                                    container.text(this.GetTagAlumnosActivos(Array.from(dato.Alumnos.values())));
                                    break;
                            }
                        },
                        // GetOptionsInRowV2: (nivel) => {
                        //     let opcionesDeGrupoRow: Table.ITableMenuDataSelectedOptionConfig<Object>[] = [];
                        //     let gruposEnGrado = DataModuloGrado._LOCALDATA_GetGruposEnGrado(nivel.IdNivel);
                        //     let escuela = DataModuloEscuela._DiccFullEscuelas.get(nivel.IdKinder);

                        //     if (escuela.CalendarioCfg && gruposEnGrado.size > 0 && UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Editar, Entidad.CModulo.Grupos, nivel.IdKinder)) {
                        //         opcionesDeGrupoRow.push({
                        //             Label: this.VB_GetUIStringModule("action_calgroups"),
                        //             Callback: () => {
                        //                 let gruposEnGrado = Array.from(DataModuloGrado._LOCALDATA_GetGruposEnGrado(nivel.IdNivel).values());
                        //                 UIUtilViewGrupos._OpenModal_AsignarCalendarioGoogle(gruposEnGrado);
                        //             }
                        //         })
                        //     }

                        //     return {
                        //         MaxOptionsInRow: 4,
                        //         Options: opcionesDeGrupoRow
                        //     }
                        // },

                        EvaluatorSubLevel: <Table.IStepSubLevelEvaluator<IGrado, IGrupo, IAlumno>>{
                            // >> Nivel 4: Grupos
                            IdMember: "IdGrupo",
                            RemoveLevelCheckboxes: true,
                            OnGetData: (dato) => dato.Grupos,
                            OnStepRowTable: (d, tr) => fnUpdateClickInRow(d, tr, "tbltopmaintag_group", 4),
                            OnStepCellTable: (container, dato, field: keyof IEscuela) => {
                                switch (field) {
                                    case "Nombre":
                                        container.text("");
                                        break;
                                    case "TagAlumno":
                                        container.text(this.GetTagAlumnosActivos(Array.from(dato.Alumnos.values())));
                                        break;
                                    case "TagGrupo":
                                        const hrefG = `#escuelas/grupos/panel--id=${dato.IdGrupo}&mode=All`;
                                        if (!container.select("a").node()) {
                                            container.text("").append(() => UIUtilGeneral._CreateLinkeableElement(dato.TagGrupo + "", hrefG).node());
                                        } else {
                                            container.text(dato.TagGrupo + "").attr("href", hrefG);
                                        }
                                        fnUpdateTagWhitCheck(container, dato.TagGrupo.toString());
                                        // Util.fn_ElementAdd_LinkToGoToPanel(container.select("label"), "escuelas/grupos/panel", dato.IdGrupo);
                                        break;
                                }
                            },
                            // GetOptionsInRowV2: (grupo) => {
                            //     let opcionesDeGrupoRow: Table.ITableMenuDataSelectedOptionConfig<Object>[] = [];
                            //     let esucela = DataModuloEscuela._DiccFullEscuelas.get(grupo.IdKinder);

                            //     if (esucela.CalendarioCfg && UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Editar, Entidad.CModulo.Grupos, grupo.IdKinder)) {
                            //         opcionesDeGrupoRow.push({
                            //             Label: this.VB_GetUIStringModule("action_calgroup"),
                            //             Callback: () => UIUtilViewGrupos._OpenModal_AsignarCalendarioGoogle([grupo])
                            //         })
                            //     }
                            //     return {
                            //         MaxOptionsInRow: 4,
                            //         Options: opcionesDeGrupoRow
                            //     }
                            // }
                        }
                    }
                }
            },
        }
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Escuela;
    }

    protected async GridUpdateData() {
        await super.GridUpdateData();

        setTimeout(() => {
            let refresh = false;

            this.ctrlTabla._data
                .forEach(d => {
                    const item = this.ctrlTabla
                        ._SelectItemData(d.IdKinder);

                    if (item.IsCollapsed) {
                        refresh = true;
                        item.CollapseItem(false);
                    }
                });

            if (refresh) {
                this.ctrlTabla._RefreshView();
            }
        }, 100);
    }

    protected async GridGetData(): Promise<Array<IEscuela>> {
        let escuelas = await super.GridGetData();

        return escuelas.map<IEscuela>(escuela => {
            const newEscuela = Object.assign({}, escuela) as IEscuela;
            const escolaridadesDeEscuela = Array.from(DataModuloEscuela._LOCALDATA_GetEscolaridadesDeEscuela(escuela.IdKinder).values());

            newEscuela.Alumnos = DataModuloEscuela._LOCALDATA_GetAlumnosEnEscuela(escuela.IdKinder);
            newEscuela.TagAlumno = Array.from(newEscuela.Alumnos.values()).filter(d => (d.IdChildMovimiento == Entidad.CNinioMovimiento.Activo)).length;
            newEscuela.TagGrupo = 0;
            newEscuela.TagGrado = 0;
            newEscuela.TagNivel = escolaridadesDeEscuela.length;

            // >> Niveles / Escolaridades
            newEscuela.Niveles = escolaridadesDeEscuela
                .map(nivel => {
                    const newNivel = Object.assign({}, nivel) as IEscolaridad;
                    const gradosDeEscolaridad = Array.from(DataModuloEscolaridad._LOCALDATA_GetGradosDeEscolaridad(nivel.Id).values())

                    newNivel.Alumnos = new Map();
                    newEscuela.Alumnos
                        .forEach(al => {
                            if (nivel.Id == al.IdEscolaridad) {
                                newNivel.Alumnos.set(al.IdChild, al);
                            }
                        });

                    newNivel.TagAlumno = newNivel.Alumnos.size;
                    newNivel.TagGrupo = 0;
                    newNivel.TagNivel = newNivel.Nombre;
                    newNivel.TagGrado = gradosDeEscolaridad.length;

                    (newEscuela.TagGrado as number) += gradosDeEscolaridad.length;

                    // >> Grados
                    newNivel.Grados = gradosDeEscolaridad
                        .map<IGrado>(grado => {
                            const newGrado = Object.assign({}, grado) as IGrado;
                            const gruposDeNivel = Array.from(DataModuloGrado._LOCALDATA_GetGruposEnGrado(grado.IdNivel).values());

                            newGrado.Alumnos = new Map();
                            newNivel.Alumnos
                                .forEach(d => {
                                    if (d.IdGrado == grado.IdNivel) {
                                        newGrado.Alumnos.set(d.IdChild, d);
                                    }
                                })
                            newGrado.TagAlumno = newGrado.Alumnos.size;
                            newGrado.TagGrupo = gruposDeNivel.length;
                            newGrado.TagGrado = grado.Nombre;

                            (newEscuela.TagGrupo as number) += gruposDeNivel.length;
                            (newNivel.TagGrupo as number) += gruposDeNivel.length;

                            // >> Grupos
                            newGrado.Grupos = gruposDeNivel
                                .map<IGrupo>(grupo => {
                                    const newGrupo = Object.assign({}, grupo) as IGrupo;

                                    // >> Alumnos
                                    newGrupo.Alumnos = DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(grupo.IdGrupo);
                                    // newGrupo.Alumnos.forEach(alumno => {
                                    //     newNivel.Alumnos.set(alumno.IdChild, alumno);
                                    //     newGrado.Alumnos.set(alumno.IdChild, alumno);
                                    // });

                                    newGrupo.TagGrupo = UIUtilViewGrupos._GetLblGrupoName(newGrupo, false);
                                    newGrupo.TagAlumno = Array.from(newGrupo.Alumnos.values()).filter(d => (d.IdChildMovimiento == Entidad.CNinioMovimiento.Activo)).length;
                                    // (newGrado.TagAlumno as number) += newGrupo.TagAlumno;
                                    // (newNivel.TagAlumno as number) += newGrupo.TagAlumno;

                                    return newGrupo;
                                });


                            return newGrado;
                        });

                    return newNivel;
                });

            return newEscuela;
        });
    }

    protected GetTagAlumnosActivos(alumnos: IAlumno[]) {
        return alumnos.filter(d => (d.IdChildMovimiento == Entidad.CNinioMovimiento.Activo)).length + "/" + alumnos.length;
    }

    // **********************************************************************
    // Forms things
    // **********************************************************************

    private async OpenModal_FormularioAgregar() {
        await UIUtilGeneral._GetGoogleLoad();

        if (!google) {
            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
            return;
        }

        this.GridOpenModal_ActionFormToAGridData({
            Action: CAccionPermiso.Agregar,
            Title: "frm_add_header",
            Width: 1100,
            GetForm: () => this.GetForm(CAccionPermiso.Agregar),
            OnAccept: async (form) => {
                let datoEscuela = {
                    ...form._DataOrigin,
                    ...form._Data
                }
                // >> Request TimeZone
                let zoneTimeData = await DataModuloEscuela._GetTimeZone(datoEscuela.LatLong);
                if (zoneTimeData.Resultado == -1) {
                    this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_err_getzonah"), "ADVERTENCIA")
                    return null;
                }
                datoEscuela.ZonaHoraria = zoneTimeData.Data.timeZoneId;
                // >> Prepare Data
                let dataP = this.GetPrepareDataToService(datoEscuela);
                datoEscuela.HoraEntradas = dataP.HoraEntradas;
                datoEscuela.HoraSalidas = dataP.HoraSalidas;
                datoEscuela.Direccion = dataP.Direccion;

                let res = await this.Sv_AltaEscuela(datoEscuela);
                // if (res.Resultado > 0) {
                //     await this.Open_VincularEscuela(res.Resultado, datoEscuela.Nombre);
                // }
                return res;
            },
        })
    }

    private async OpenModal_FormularioEditar(dato: IEscuela) {
        await UIUtilGeneral._GetGoogleLoad();

        if (!google) {
            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
            return;
        }

        this.GridOpenModal_ActionFormToAGridData({
            Action: CAccionPermiso.Editar,
            Title: "frm_edit_header",
            IdsEscuelas: [dato.IdKinder],
            Width: 1100,
            GetForm: () => this.GetForm(CAccionPermiso.Editar, dato),
            OnAccept: async (form) => {
                let datoEscuela = {
                    ...form._DataOrigin,
                    ...form._Data
                }
                if (datoEscuela.IsChangedLatLong) {
                    // >> Request TimeZone: Puede cambiar de Direccion dentro de la zona horaria en la que fue registrado
                    let zoneTimeData = await DataModuloEscuela._GetTimeZone(datoEscuela.LatLong);
                    if (zoneTimeData.Resultado == -1) {
                        this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_err_getzonah"), "ADVERTENCIA");
                        return null;
                    }
                    if (datoEscuela.ZonaHoraria != zoneTimeData.Data.timeZoneId) {
                        this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_err_zonachange"), "ADVERTENCIA");
                        return null;
                    }
                }
                // >> Prepare Data
                let dataP = this.GetPrepareDataToService(datoEscuela);
                datoEscuela.HoraEntradas = dataP.HoraEntradas;
                datoEscuela.HoraSalidas = dataP.HoraSalidas;
                datoEscuela.Direccion = dataP.Direccion;

                if (datoEscuela.IsChangedLogo) {
                    let resLogo = await UIUtilViewEscuelas._SvUpdateFoto(datoEscuela, datoEscuela.Logo as any);
                    if (resLogo.Resultado < 0 && resLogo.Mensaje) {
                        this.notificacion._Mostrar(resLogo.Mensaje, "ADVERTENCIA");
                    }
                }
                return DataModuloEscuela._ActualizaEscuela(datoEscuela);
            },
        })
    }


    private async OpenModal_ConfigurarDiasNoLectivos(escuela: IEscuela) {
        let tbl: Table.Tabla<IDiaNoLectivo>

        const fnRefreshDiasNoLectivos = async (mt: ModalThings.IModalThings) => {
            mt.Progress.attr("oculto", false);
            const resDiasNoLectivos = await _SvEscuelaDiasNoLectivos(escuela.IdKinder);
            mt.Progress.attr("oculto", true);
            if (resDiasNoLectivos.Resultado < 1) { this.notificacion._Mostrar(_L("escuelas.notif_err_getdiasnolectivos"), "ADVERTENCIA"); return; }
            const DiasNoLectivos: IDiaNoLectivo[] = resDiasNoLectivos.Datos || [];
            DiasNoLectivos.forEach(d => { d.FechaInicio = d.FechaInicio.split("T")[0]; d.FechaFin = d.FechaFin.split("T")[0]; })
            tbl._UpdateData(DiasNoLectivos);
        }

        const modalDiasNoLectivos = ModalThings._GetModalToAProccess({
            Title: _L("escuelas.tag_diasnolectivos"),
            Width: 600,
            Height: "550px",
            DrawContent: async (container, mt) => {
                // mt.BtnLeft.classed("hide", true);
                // mt.BtnRight.text(_L("general.save"));
                tbl = new Table.Tabla<IDiaNoLectivo>({
                    IdData: "Id",
                    IdTabla: "Escuelas",
                    HideCheckboxes: true,
                    MinWidth: 400,
                    Parent: container,
                    RenderColumnHeadings: [
                        { Field: "Nombre", Label: _L("general.descripcion"), MinWidth: "80px", Width: "60%", IsSortable: false },
                        { Field: "FechaInicio", Label: _L("general.inicio"), MinWidth: "80px", Width: "40%", IsSortable: false },
                        { Field: "FechaFin", Label: _L("general.fin"), MinWidth: "80px", Width: "40%", IsSortable: false },
                    ],
                    OptionsTopDefaultV2: {
                        MaxOptionsInRow: 2,
                        Options: [{
                            Label: _L("c_actions.agregar"),
                            Callback: async () => {
                                fnOpenModal_DiasNoLectivos();
                            }
                        }]
                    },
                    EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IDiaNoLectivo, any, any>>{
                        GetOptionsInRowV2: () => ({
                            MaxOptionsInRow: 2,
                            Options: [
                                {
                                    Label: _L("c_actions.editar"),
                                    MultiData: false,
                                    Callback: async (datos) => {
                                        const datoItem = datos[0];
                                        fnOpenModal_DiasNoLectivos({
                                            ...datoItem,
                                            Frecuencia: Boolean(datoItem.Frecuencia)
                                        });
                                    },
                                },
                                {
                                    Label: _L("c_actions.eliminar"),
                                    MultiData: false,
                                    Callback: (datos) => {
                                        fnEliminarDiaNoLectivo(datos[0])
                                    }
                                }
                            ]
                        }),
                        OnStepCellTable: (container, datum, fieldName: keyof IDiaNoLectivo) => {
                            switch (fieldName) {
                                case "FechaInicio":
                                    let dateInit = UIUtilTime._GetLocalDateFromInputDateString(datum.FechaInicio);
                                    if (datum.Frecuencia) container.text(UIUtilTime._DateFormatStandar(dateInit, "d MMM"));
                                    else container.text(UIUtilTime._DateFormatStandar(dateInit, "d MMM yyyy"));
                                    break;
                                case "FechaFin":
                                    let dateEnd = UIUtilTime._GetLocalDateFromInputDateString(datum.FechaFin);
                                    if (datum.Frecuencia) container.text(UIUtilTime._DateFormatStandar(dateEnd, "d MMM"));
                                    else container.text(UIUtilTime._DateFormatStandar(dateEnd, "d MMM yyyy"));
                                    break;
                            }
                        }
                    }
                })
            }
        })

        fnRefreshDiasNoLectivos(modalDiasNoLectivos);

        interface IDiaNoLectivoForm extends Omit<IDiaNoLectivo, "Frecuencia"> {
            Frecuencia?: boolean;
            EsRango?: boolean;
        }
        const fnOpenModal_DiasNoLectivos = (dato = <IDiaNoLectivoForm>{}) => {
            const esAgregar = dato.Id == null;
            dato.EsRango = dato.FechaInicio != dato.FechaFin;
            dato.Frecuencia = dato.Frecuencia != null ? dato.Frecuencia : true;
            dato.FechaFin = dato.FechaFin != null ? dato.FechaFin : "";
            dato.FechaInicio = dato.FechaInicio != null ? dato.FechaInicio : "";
            dato.Nombre = dato.Nombre != null ? dato.Nombre : "";

            let año = esAgregar ? new Date().getFullYear() : dato.FechaInicio.split("-")[0];

            let inputDateAuxInit: HTMLInputElement = d3.select("body").append("input").classed("hide", true).attr("type", "date").attr("required", "").node() //.attr("min", dato.Frecuencia ? null : UIUtilTime._FmtToInputDate(new Date())).node();
            let inputDateAuxEnd: HTMLInputElement = d3.select("body").append("input").classed("hide", true).attr("type", "date").attr("required", "").node() //.attr("min", dato.FechaInicio).node();

            ModalThings._GetModalToForm({
                Title: esAgregar ? _L("c_actions.agregar") : _L("c_actions.editar"),
                Width: 380,
                OnClose: () => {
                    inputDateAuxInit.remove()
                    inputDateAuxEnd.remove()
                },
                GetForm: () => {
                    const fnFixDtMask = (input: TSelectionHTML<"input">, esFrecuencia: boolean) => {
                        if (esFrecuencia)
                            UIUtilMask._ApplyDateMask_DD_MM(input)
                        else
                            UIUtilMask._ApplyDateMask_DD_MM_YYYY(input)
                    }
                    const fnGetDtValueFromISO = (dt: string) => {
                        const esFrecuencia = dato.Frecuencia
                        const [y, m, d] = dt.split("-")
                        if (esFrecuencia)
                            return `${d}/${m}/YYYY`
                        else
                            return `${d}/${m}/${y}`
                    }
                    const fnGetDtValue = (input: HTMLInputElement) => {
                        const esFrecuencia = form._GetModelValue("Frecuencia")
                        const dateDDMM = input.value.split("/")
                        if (esFrecuencia)
                            return `${año}-${dateDDMM[1]}-${dateDDMM[0]}`
                        else
                            return `${dateDDMM[2]}-${dateDDMM[1]}-${dateDDMM[0]}`
                    }
                    const fnValidateDtInput = (model: keyof IDiaNoLectivoForm & string, viewValue: string, auxInput: HTMLInputElement) => {
                        const esFrecuencia = form._GetModelValue("Frecuencia")
                        const esRango = form._GetModelValue("EsRango")
                        if (['__/__/YYYY', '__/__/____'].includes(viewValue))
                            return _L("control.form.required")
                        if (viewValue.includes("_"))
                            return _L("control.form.invalid_fmt")
                        if (model == "FechaInicio") {
                            if (esRango && auxInput.valueAsDate && inputDateAuxEnd.valueAsDate && auxInput.valueAsDate > inputDateAuxEnd.valueAsDate) {
                                return _L("control.form.range_overflow", form._GetModelValue("FechaFin"))
                            } else if (!esFrecuencia && !esRango && auxInput.valueAsDate && new Date(auxInput.valueAsDate.toISOString().replace("Z", "")) < new DateV2()._Truncate("Hour")) {
                                return _L("control.form.range_underflow", UIUtilTime._FmtToInputDate(new DateV2()._Truncate("Hour")))
                            }
                        }
                        if (model == "FechaFin") {
                            if (esRango && auxInput.valueAsDate && inputDateAuxInit.valueAsDate && auxInput.valueAsDate < inputDateAuxInit.valueAsDate) {
                                return _L("control.form.range_underflow", form._GetModelValue("FechaInicio"))
                                // } else if (!esFrecuencia && auxInput.valueAsDate && new Date(auxInput.valueAsDate.toISOString().replace("Z", "")) > new DateV2()._Truncate("Hour")) {
                                //     return _L("control.form.range_underflow", UIUtilTime._FmtToInputDate(new DateV2()._Truncate("Hour")))
                            }
                        }
                        return auxInput.validity.valid
                    }
                    const form = new FormGenerator<IDiaNoLectivoForm>()._Crear({
                        LabelMaxWidth: 120,
                        schema: [
                            {
                                model: "Nombre", type: "input", labelText: _L("general.descripcion"), inputAttr: { required: true, maxlength: 200 }
                            },
                            {
                                model: "Frecuencia", labelText: _L("escuelas.repetir_anual"), type: "input",
                                inputAttr: {
                                    type: "checkbox",
                                    onchange: (e) => {
                                        const esFrecuencia = (e.target as HTMLInputElement).checked
                                        fnFixDtMask(form._GetModelControl<"input">("FechaInicio").selection, esFrecuencia)
                                        fnFixDtMask(form._GetModelControl<"input">("FechaFin").selection, esFrecuencia)
                                    }
                                }
                            },
                            {
                                model: "FechaInicio", type: "input", labelText: _L("general.inicio"),
                                onValidate: (viewVal) => fnValidateDtInput("FechaInicio", viewVal, inputDateAuxInit),
                                inputAttr: {
                                    required: true,
                                    onload: input => setTimeout(() => {
                                        fnFixDtMask(input, dato.Frecuencia)
                                        if (!esAgregar) input.property("value", fnGetDtValueFromISO(dato.FechaInicio))
                                        inputDateAuxInit.value = fnGetDtValue(input.node())
                                    }),
                                    oninput: event => setTimeout(() => {
                                        inputDateAuxInit.value = fnGetDtValue(event.target as HTMLInputElement)
                                    }),
                                }
                            },
                            {
                                model: "EsRango", type: "input", labelText: _L("escuelas.rango"),
                                inputAttr: {
                                    type: "checkbox",
                                    onchange: (e) => {
                                        const esRango = (e.target as HTMLInputElement).checked
                                        form._GetModelControl("FechaFin").row.classed("hide", !esRango);
                                        if (!esRango)
                                            inputDateAuxEnd.value = ""
                                    }
                                }
                            },
                            {
                                model: "FechaFin", type: "input", labelText: _L("general.fin"),
                                onValidate: (viewVal) => fnValidateDtInput("FechaFin", viewVal, inputDateAuxEnd),
                                inputAttr: {
                                    required: true,
                                    onload: input => setTimeout(() => {
                                        fnFixDtMask(input, dato.Frecuencia)
                                        if (!esAgregar) input.property("value", fnGetDtValueFromISO(dato.FechaFin))
                                        inputDateAuxEnd.value = fnGetDtValue(input.node())
                                        form._GetModelControl("FechaFin").row.classed("hide", !dato.EsRango)
                                    }),
                                    oninput: event => setTimeout(() => {
                                        inputDateAuxEnd.value = fnGetDtValue(event.target as HTMLInputElement)
                                    })
                                },
                            },
                        ],
                    }, dato)
                    return form
                },
                OnAccept: async (form, mt) => {
                    const formData = form._Data;
                    const strDTInit = new DateV2(UIUtilTime._GetDateConcatenatedDateTime(inputDateAuxInit.value))._ToISOLocalString();
                    const strDTEnd = formData.EsRango ? new DateV2(UIUtilTime._GetDateConcatenatedDateTime(inputDateAuxEnd.value))._ToISOLocalString() : strDTInit;
                    if (esAgregar) {
                        const resAddDiaNoLectivo = await _SvEscuelaAgregarDiaNoLectivo(escuela.IdKinder, formData.Nombre, strDTInit, strDTEnd, Number(formData.Frecuencia));
                        if (resAddDiaNoLectivo.Resultado > 0) {
                            fnRefreshDiasNoLectivos(modalDiasNoLectivos);
                        }
                        return resAddDiaNoLectivo;
                    } else {
                        const resEditDiaNoLectivo = await _SvEscuelaEditarDiaNoLectivo(escuela.IdKinder, formData.Nombre, strDTInit, strDTEnd, Number(formData.Frecuencia), dato.Id)
                        if (resEditDiaNoLectivo.Resultado > 0 || resEditDiaNoLectivo.Resultado == -1) {
                            fnRefreshDiasNoLectivos(modalDiasNoLectivos);
                        }
                        if (resEditDiaNoLectivo.Resultado == -1) mt.Modal._Ocultar();
                        return resEditDiaNoLectivo;
                    }
                },
            })
        }

        const fnEliminarDiaNoLectivo = (dato: IDiaNoLectivo) => {
            ModalThings._GetConfirmacionModal({
                Title: _L("c_actions.eliminar"),
                Width: 250,
                Message: _L("confirmation.eliminar_registro"),
                OnAccept: async () => {
                    const resDeleteDia = await _SvEscuelaEliminarDiaNoLectivo(escuela.IdKinder, dato.Id);
                    if (resDeleteDia.Resultado < -1) { this.notificacion._Mostrar(_L("general.notif_fail"), "ADVERTENCIA"); return }
                    this.notificacion._Mostrar(_L((resDeleteDia.Resultado > 0 ? "escuelas.notif_success_proccess" : "escuelas.notif_err_dianolect_noexist")), resDeleteDia.Resultado > 0 ? "INFO" : "ADVERTENCIA")
                    fnRefreshDiasNoLectivos(modalDiasNoLectivos);
                }
            })
        }
    }

    private OpenModal_EliminarDatos(datos: IEscuela[]) {
        this.GridOpenModal_ProccessArrayData({
            Title: "frm_elimin_header",
            DataToProccess: datos,
            OnGetIdEscuela: (dato) => dato.IdKinder,
            OnError_GetItemDataTag: (dato) => dato.Nombre,
            OnStepAProccess: (dato) => DataModuloEscuela._EliminaEscuela(dato.IdKinder),
        })
    }

    private OpenModal_ConfigurarEntradaSalidaQR(escuela: IEscuela) {
        this.GridOpenModal_ProccessArrayData({
            Title: this.VB_GetUIStringModule(escuela.UsaEntradaSalidaQR ? "tag_deshab_qr" : "tag_hab_qr"),
            Message: this.VB_GetUIStringModule(escuela.UsaEntradaSalidaQR ? "tag_deshab_qr_mess" : "tag_hab_qr_mess"),
            AccionToHttpMessage: (escuela.UsaEntradaSalidaQR ? "deshabilitar_qr" : "habilitar_qr"),
            OnError_GetItemDataTag: (escuela) => escuela.Nombre,
            DataToProccess: [escuela],
            OnGetIdEscuela: () => escuela.IdKinder,
            OnStepAProccess: (escuela) => DataModuloEscuela._ConfigurarEntradaSalidaQR(escuela.IdKinder, !escuela.UsaEntradaSalidaQR),
            Action: Entidad.CAccionPermiso.Editar,
        })
    }

    private OpenModal_ConfigurarFacturacion(idEscuela: number): void {
        type FormData = Pick<IConfiguracionFacturacion, "FPLUGIN" | "FApiKey" | "FSecretKey">
        const accionEditarID = CAccionPermiso.Editar
        this.GridOpenModal_ActionFormToAGridData({
            Title: "action_confifactura",
            Width: 520,
            Action: accionEditarID,
            GetForm: () => new FormGenerator<FormData>()._Crear({
                LabelMaxWidth: 110,
                schema: [
                    // { model: "FPLUGIN", type: "input", labelText: _L("escuelas.confifactura_plugin"), inputAttr: { required: true } },
                    { model: "FApiKey", type: "input", labelText: _L("escuelas.confifactura_apikey"), inputAttr: { required: true } },
                    { model: "FSecretKey", type: "input", labelText: _L("escuelas.confifactura_secretkey"), inputAttr: { required: true } },
                ]
            }),
            DrawContent: (_, form, mt) => {
                let msgErr: string
                mt.Modal._DeshabilitarBtns()
                mt.Progress.node()._Visible = true
                _ObtenerEscuelaConfiguracionFacturacion(idEscuela)
                    .then((res) => {
                        if (res.Resultado <= 0) {
                            msgErr = _HttpMsgV2(res, "consulta")
                            return
                        }
                        form._AsignaData(res.Datos)
                    })
                    .catch(() => {
                        msgErr = _HttpMsg("escuela/ObtenerConfiguracionFacturacion", 0, "consulta")
                    })
                    .finally(() => {
                        if (msgErr) {
                            NotificacionV2._Mostrar(msgErr, "ADVERTENCIA")
                            setTimeout(() => {
                                mt.Modal._Ocultar()
                            }, 1000);
                        }
                        if (UIUtilPermission._HasAccionPermission(accionEditarID, this.modulo, idEscuela))
                            mt.Modal._HabilitarBtns()
                        mt.Progress.node()._Visible = false
                    })
            },
            OnAccept: (form) => {
                const { FApiKey, FSecretKey } = form._Data
                return _EscuelaConfigurarFacturacion(idEscuela, FApiKey, FSecretKey)
            }
        })
    }

    private OpenModal_ConfigurarInfoExtra(escuela: IEscuela) {
        type IConfigInfoExtra = Entidad.IEscuelaConfigInfoExtra;
        // type CTipoDato = data.Entidades.CEscuelaInfoExtraTipoDato;
        const idReqEscInfo = Entidad.CTipoRequest.EscuelaAlumnoInfoExtra;
        const tiposDatosList = UIUtilViewData._GetList_InfoExtraTipoDato();
        let auxIdCount = 0;
        let originItemsList: Entidad.IEscuelaConfigInfoExtra[] = [];
        let playgroundItemsList: IConfigInfoExtra[] = [];

        let tbl: Table.Tabla<Entidad.IEscuelaConfigInfoExtra>;

        ModalThings._GetModalToAProccess({
            LangModuleKeyInContext: this.labelsKeyBase,
            Title: "action_confinfoextra",
            Width: 650,
            Height: "550px",
            DrawContent: async (container, mt) => {
                mt.BtnLeft.classed("hide", true);
                mt.BtnRight.text(UIUtilLang._GetUIString("general", "save"))
                mt.Progress.attr("oculto", false);
                mt.Modal._DeshabilitarBtns();

                // DRAWING
                let eventDragOverIndex = -1;

                function fn_UpdateArrayOrder() {
                    playgroundItemsList.forEach((d, i) => {
                        d.Orden = (i + 1);
                    })
                }
                tbl = new Table.Tabla<IConfigInfoExtra>({
                    IdTabla: "EscuelasInfoExtra",
                    LangModuleKeyInContext: this.labelsKeyBase,
                    IdData: "Id",
                    RenderColumnHeadings: [
                        { Field: "Orden", Label: "", Width: "1%", MinWidth: "20px", IsSortable: false },
                        { Field: "Tags", Label: "Etiquet", LabelLangKey: "tag_aluminfoextra_tag", Width: "40%", MinWidth: "80px", IsSortable: false },
                        { Field: "Tipo", Label: "Tipo", LabelLangKey: "tag_aluminfoextra_tipo", Width: "20%", MinWidth: "50px", IsSortable: false },
                        { Field: "Requerido", Label: "Requerido", LabelLangKey: "tag_aluminfoextra_required", Width: "10%", MinWidth: "85px", IsSortable: false },
                        // { Field: "Eliminar", Label: "", Width: "10%", MinWidth: "40px", IsSortable: false }
                    ],
                    MinWidth: 550,
                    HideCheckboxes: true,
                    Parent: container,
                    OptionsTopDefaultV2: {
                        MaxOptionsInRow: 2,
                        Options: [{
                            Label: UIUtilLang._GetUIString("c_actions", "agregar"),
                            Callback: async () => {
                                let itemNew = await fnOpenModal_ConfInfoExtraItem();
                                playgroundItemsList.push(itemNew);
                                tbl._UpdateData(playgroundItemsList);
                            }
                        }]
                    },
                    EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IConfigInfoExtra, any, any>>{
                        GetOptionsInRowV2: () => ({
                            MaxOptionsInRow: 2,
                            Options: [
                                {
                                    Label: UIUtilLang._GetUIString("c_actions", "editar"),
                                    Callback: async (datos: IConfigInfoExtra[]) => {
                                        let itemEdited = await fnOpenModal_ConfInfoExtraItem(datos[0]);
                                        let itemReal = playgroundItemsList.find(d => (d.Id == itemEdited.Id));
                                        for (let k in itemEdited) {
                                            itemReal[k] = itemEdited[k];
                                        }
                                        tbl._UpdateData(playgroundItemsList);
                                    }
                                },
                                {
                                    Label: UIUtilLang._GetUIString("c_actions", "eliminar"),
                                    Callback: async (datos: IConfigInfoExtra[]) => {
                                        let itemEliminar = datos[0];
                                        let indexItemReal = playgroundItemsList.findIndex(d => (d.Id == itemEliminar.Id));
                                        if (indexItemReal > -1) {
                                            playgroundItemsList.splice(indexItemReal, 1);
                                            tbl._UpdateData(playgroundItemsList);
                                        } else {
                                            console.warn("-d", "Error al eliminar");
                                        }
                                    }
                                }
                            ]
                        }),
                        OnStepRowTable: (datum, tblRow, rowBody, indexOrigin) => {
                            let rowBodyElement = rowBody.node();
                            rowBodyElement.dataset.key = indexOrigin.toString();
                            rowBodyElement.setAttribute("draggable", true + "");

                            if (indexOrigin == eventDragOverIndex) {
                                rowBody.style("background-color", "var(--color_primary3)");
                            } else {
                                rowBody.style("background-color", null);
                            }

                            rowBodyElement.ondragover = function (e) {
                                e.preventDefault();

                                let lastIndex = eventDragOverIndex;
                                eventDragOverIndex = Number(e.currentTarget["dataset"].key);

                                if (lastIndex == eventDragOverIndex) {
                                    return;
                                }

                                playgroundItemsList = UIUtilGeneral._ArrayMoveIndex(playgroundItemsList, lastIndex, eventDragOverIndex);
                                fn_UpdateArrayOrder();
                                tbl._UpdateData(playgroundItemsList);
                                // console.debug("Drag over", event.currentTarget, lastIndex, eventDragOverIndex, playgroundItemsList.length);
                            }

                            rowBodyElement.ondragstart = function (e) {
                                eventDragOverIndex = Number(e.currentTarget["dataset"].key);
                                rowBody.style("background-color", "var(--color_primary3)");
                            }

                            rowBodyElement.ondragend = function (e) {
                                eventDragOverIndex = -1;
                                tbl._RefreshView();
                            }
                        },
                        OnStepCellTable: (container, datum, field: keyof IConfigInfoExtra) => {
                            if (datum.Id == 0) {
                                return;
                            }
                            switch (field) {
                                case "Orden":
                                    container
                                        .style("transform", "rotate(90deg)")
                                        .style("color", "gray")
                                        .style("cursor", "grab")
                                        .text("|||");
                                    break;
                                case "Tags":
                                    container.text(datum.Tags.join(", "));
                                    break;
                                case "Tipo":
                                    container.text(UIUtilViewData._GetStr_InfoExtraTipoDato(datum.Tipo));
                                    break;
                                case "Requerido":
                                    container.text(UIUtilLang._GetUIString("general", (datum.Requerido ? "afirma" : "niega")));
                                    break;
                            }
                        }
                    }
                });

                // GET DATA
                // let resDatos: IConfigInfoExtra[] = null;

                await MainPage._ReloadServiceAndAwait(idReqEscInfo, escuela.IdKinder)
                    .then(() => {
                        let resDatos = DataModuloMain._GetReqDataArrayById(idReqEscInfo)
                            .filter(d => (d.IdEscuela == escuela.IdKinder))
                            .sort((a, b) => (a.Orden - b.Orden));

                        playgroundItemsList = resDatos
                            .sort((a, b) => (a.Orden - b.Orden))
                            .map(d => {
                                const dd = Object.assign({}, d);
                                dd.Tags = new Array(...d.Tags);
                                return dd;
                            });

                        originItemsList = playgroundItemsList
                            .map(d => {
                                const dd = Object.assign({}, d);
                                dd.Tags = new Array(...d.Tags);
                                return dd;
                            });

                        tbl._UpdateData(playgroundItemsList);
                    })
                    .catch(() => {
                        this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                        mt.Modal._FooterSelection
                            .classed("hide", true);
                    })
                    .finally(() => {
                        mt.Progress.attr("oculto", true);
                        mt.Modal._HabilitarBtns();
                    });
            },
            OnAccept: async (mt) => {
                let itemsAdded: IConfigInfoExtra[] = [];
                let itemsEdited: IConfigInfoExtra[] = [];
                playgroundItemsList
                    .forEach((d, i) => {
                        d.Orden = (i + 1);
                        if (d.Id < 0) {
                            itemsAdded.push(d);
                        } else {
                            let itemO = originItemsList.find(dA => (dA.Id == d.Id));
                            if (itemO.Orden != d.Orden || itemO.Requerido != d.Requerido || itemO.Tags.toString() != d.Tags.toString()) {
                                itemsEdited.push(d);
                            }
                        }
                    });
                let itemsDeleted = originItemsList
                    .filter(dA => {
                        let itemFounded = playgroundItemsList.find(dB => (dB.Id == dA.Id));
                        return !Boolean(itemFounded);
                    })
                    .map(d => d.Id);

                if (itemsAdded.length == 0 && itemsEdited.length == 0 && itemsDeleted.length == 0) {
                    this.notificacion._Mostrar(UIUtilLang._GetUIString("escuelas", "notif_err_nofoundchanges"), "ADVERTENCIA");
                    return null;
                }

                let res = await DataModuloEscuelaAlumnoInfoExtra._InfoExtraActualizar(
                    escuela.IdKinder,
                    itemsAdded.map(d => ({
                        Tags: d.Tags,
                        Tipo: d.Tipo,
                        Orden: d.Orden,
                        Requerido: d.Requerido,
                    })),
                    itemsEdited.map(d => ({
                        Id: d.Id,
                        Tags: d.Tags,
                        Orden: d.Orden,
                        Requerido: d.Requerido,
                    })),
                    itemsDeleted
                );

                if (res.Resultado > 0) {
                    MainPage._ReloadService(idReqEscInfo, escuela.IdKinder);
                }

                return res;
            }
        })

        const fnOpenModal_ConfInfoExtraItem = (datoExtra = <IConfigInfoExtra>{}) => {
            const esAgregar = (datoExtra.Id == null);
            const esAux = esAgregar || datoExtra.Id < 0;
            let datoFrm = {
                Tag: (datoExtra.Tags ? datoExtra.Tags[0] : ""),
                Tipo: datoExtra.Tipo,
                Required: (esAgregar ? true : datoExtra.Requerido)
            }
            type TDataForm = typeof datoFrm;

            return new Promise<IConfigInfoExtra>(resolve => {
                ModalThings._GetModalToForm({
                    Title: UIUtilLang._GetUIString("c_actions", ((datoExtra.Id > 0) ? "editar" : "agregar")),
                    Width: 350,
                    GetForm: (content) => new FormGenerator<TDataForm>()
                        ._Crear({
                            LabelMaxWidth: 100,
                            LangModuleKeyInContext: this.labelsKeyBase,
                            schema: [
                                {
                                    model: "Tag", type: Fields.input,
                                    labelAttr: { text: "tag_aluminfoextra_tag" },
                                    inputAttr: { type: "text", maxlength: 150, required: true }
                                },
                                {
                                    model: "Tipo", type: Fields.selectMaterial,
                                    labelAttr: { text: "tag_aluminfoextra_tipo" },
                                    selectMaterialAttr: { valueMember: "Id", displayMember: "Name", disabled: !esAux, removeBorder: !esAux, required: true },
                                    values: tiposDatosList
                                },
                                {
                                    model: "Required", type: Fields.input,
                                    labelAttr: { text: "tag_aluminfoextra_required" },
                                    inputAttr: { type: "checkbox" }
                                }
                            ],
                            //Validation: (val, field, dataForm,) => {
                            //    if (field == "Tag" || field == "Tipo") {
                            //        return Boolean(val);
                            //    }
                            //    return true;
                            //}
                        }, datoFrm),
                    OnAccept: (form, mt) => {
                        let formData = form._Data;
                        // let objExtraConfig = {
                        //     Required: formData.Required
                        // }
                        let id = datoExtra.Id;
                        if (esAgregar) {
                            auxIdCount--; // Id temporal
                            id = auxIdCount;
                        }
                        resolve({
                            ...datoExtra,
                            ...{
                                Id: id,
                                Tags: [formData.Tag],
                                Tipo: formData.Tipo,
                                Requerido: formData.Required,
                                ExtraConfig: "",
                                ExtraConfigObj: {},
                            }
                        });
                        mt.Modal._Ocultar();
                        return null;
                    }
                })
            });
        }
    }

    // private Open_VincularEscuela(/* showModal: boolean, */ idEscuela: number, nombreEscuela: string): Promise<void> {
    //     // window.open('https://rst1-dev.kidi.mx/adminjsv2/calendario/ObtenerConfiguracion?Id=9K3');
    //     return new Promise(resolve => {

    //         const OpenNewWindow = () => {
    //             let windowReference = window.open(DataModuloEscuela._GetUrlCalendarioObtenerConfiguracion(idEscuela));
    //             return new Promise<void>(resolve2 => {
    //                 let timer = setInterval(() => {
    //                     if (!windowReference || windowReference.closed) {
    //                         clearInterval(timer);
    //                         MainPage._ReloadService(Entidad.CTipoRequest.Escuela);
    //                         // this.ctrlTabla.prop_menuFlexInRow.met_Remove();
    //                         resolve2();
    //                     }
    //                 }, 1000);
    //             })
    //         }

    //         // if (showModal) {
    //         ModalThings._GetConfirmacionModal({
    //             Title: "frm_linkschool_header",
    //             Message: this.VB_GetUIStringModule("frm_linkschool_message")
    //                 .replace("_SCHOOLNAME", nombreEscuela),
    //             StrModalBotons: "sí_no",
    //             OnClose: () => resolve(),
    //             OnAccept: async () => OpenNewWindow(),
    //             LangModuleKeyInContext: this.labelsKeyBase
    //         })
    //         // } else {
    //         //     OpenNewWindow(() => {
    //         //         resolve(true);
    //         //     });
    //         // }
    //     })
    // }

    // private OpenModal_AsignarCalendarioGoogle(escuela: IEscuelaForm) {
    //     ModalThings._GetConfirmacionModal({
    //         Title: "frm_sch_asigncalend_header",
    //         Message: this.VB_GetUIStringModule("frm_sch_asigncalend_message")
    //             .replace("_SCHOOLNAME", escuela.Nombre),
    //         OnAccept: () => this.Sv_AsignarCalendarioGoogle(escuela),
    //         LangModuleKeyInContext: this.labelsKeyBase
    //     })
    // }

    private OpenModal_Escolaridad_FormularioAgregarEditar(dato = <IEscolaridad>{}) {
        const action = (dato.Id ? CAccionPermiso.Editar : CAccionPermiso.Agregar);
        const escuelasPermission = this.GridGetEscuelasConPermisoDeAccion(action);

        if (!escuelasPermission.length) {
            this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_sinescuelas"), "ADVERTENCIA");
            return;
        }

        this.GridOpenModal_ActionFormToAGridData({
            Action: action,
            Title: (dato.Id ? "action_editlevel" : "action_addlevel"),
            IdsEscuelas: dato.Id ? [dato.IdEscuela] : undefined,
            Width: 400,
            GetForm: () => {
                return new FormGenerator<IEscolaridad>()
                    ._Crear({
                        schema: [
                            {
                                model: "IdEscuela",
                                type: Fields.selectMaterial,
                                labelAttr: { text: "d_field_nombre" },
                                values: escuelasPermission,
                                selectMaterialAttr: {
                                    valueMember: "IdKinder",
                                    displayMember: "Nombre",
                                    disabled: (dato.Id != null),
                                    removeBorder: (dato.Id != null),
                                    required: true
                                }
                            },
                            {
                                model: "Nombre",
                                type: Fields.input,
                                inputAttr: { type: "text", required: true },
                                labelAttr: { text: "frm_level_name" }
                            },
                        ],
                        LangModuleKeyInContext: this.labelsKeyBase,
                        //Validation: (value, field, daaForm, controls) => value && Boolean(String(value).trim()),
                        LabelMaxWidth: 120
                    }, dato);
            },
            OnAccept: async (form) => {
                // if (form.met_GetIsValidFormV2()) {
                let res: DataDRequest.IResultadoPeticion<unknown> = null
                const dataForm = form._Data;

                if (action == CAccionPermiso.Agregar) {
                    res = await DataModuloEscolaridad._AltaEscolaridad(dataForm.IdEscuela, dataForm.Nombre, []);
                }
                else if (action == CAccionPermiso.Editar) {
                    res = await DataModuloEscolaridad._ActualizarEscolaridad(dataForm.Id, dataForm.Nombre, [], []);
                }

                if (res.Resultado > 0) {
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Escolaridad, dataForm.IdEscuela);
                }
                return res;
                // }
                //return null;
            },
            // AutoReloadGridRequestOnFinally: false
        })
    }

    private OpenModal_Escolaridad_EliminarDatos(datos: IEscolaridad[]) {
        this.GridOpenModal_ProccessArrayData<IEscolaridad>({
            DataToProccess: datos,
            Title: "Eliminar nivel",
            TypeRequest: Entidad.CTipoRequest.Escolaridad,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnError_GetItemDataTag: (dato) => dato.Nombre,
            OnStepAProccess: (dato) => DataModuloEscolaridad._EliminarEscolaridad(dato.Id),
            // OnEndProccess: (results) => {
            //     if (results.find(d => (d.Resultado > 0))) {
            //         // app.ReloadService(data.Entidades.CTipoRequest.Nivel, datos[0].IdEscuela);
            //         app.ReloadService(data.Entidades.CTipoRequest.Escolaridad, datos[0].IdEscuela);
            //     }
            // },
        })
    }

    protected GRID_GetExportarConfig(dataGrid: IEscuela[]): IConfigGridExcelExport<IEscuela> {
        return {
            IdsEscuelas: [... new Set(dataGrid.map(d => (d.IdKinder)))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IEscuela>>(d => ({
                    Field: d.Field as keyof IEscuela,
                    HeaderTag: d.Label,
                    WidthCell: (d.Field == "Direccion" ? 50 : 20),
                })),
            OnGetDataBySheets: async () => {
                let datosExport: IEscuela[] = [];

                const fnSetData = (typeData: "escuela" | "nivel" | "grado" | "grupo", dato: IEscuela | IEscolaridad | IGrado | IGrupo) => {
                    datosExport.push(<IEscuela>{
                        Nombre: typeData == "escuela" ? dato.Nombre : "",
                        // Direccion: typeData == "escuela" ? Escuelas.fn_GetDirectionStr(dato) : "",
                        TagNivel: dato.TagNivel,
                        TagGrado: dato.TagGrado,
                        TagGrupo: dato.TagGrupo,
                        TagAlumno: this.GetTagAlumnosActivos(Array.from(dato.Alumnos.values()))
                    })
                }

                dataGrid.forEach((escuela) => {
                    fnSetData("escuela", escuela);
                    escuela.Niveles.forEach((nivel) => {
                        fnSetData("nivel", nivel);
                        nivel.Grados.forEach(grado => {
                            fnSetData("grado", grado);
                            grado.Grupos.forEach((grupo) => {
                                fnSetData("grupo", grupo);
                            })
                        })
                    })
                })

                return [{
                    IdSheet: 0,
                    SheetName: UIUtilViewData._GetStr_Modulo(this.modulo),
                    Data: datosExport
                }]
            },
            OnGetEscuelasTagInSheet: (dataGrid) => UIUtilViewData._GetStr_Modulo(this.modulo)
        }
    }

    /**
     * * Ajusta la estructura del arreglo de HoraEntradas y HoraSalidas
     * * Crea la cadena para el campo de Direccion, en base a los campos del formulario
    */
    private GetPrepareDataToService(datoEscuela: IEscuelaForm) {
        let { DiasOperacion, ZonaHoraria, Entrada, Salida } = datoEscuela;
        let horaEntradas = [null, null, null, null, null, null, null];
        let horaSalidas = [null, null, null, null, null, null, null];
        let direccion = `${datoEscuela.NumInterior}\n${datoEscuela.Calle}\n${datoEscuela.NumExterior}\n${datoEscuela.CodigoPostal}\n${datoEscuela.Ciudad}\n${datoEscuela.Estado}\n${datoEscuela.Pais}\n${datoEscuela.LatLong}`;

        DiasOperacion.forEach((idDia, i) => {
            horaEntradas[idDia - 1] = new DateV2()
                ._SetTimeZone(ZonaHoraria)
                ._SetLocalStrHour(Entrada)
                ._Truncate("Second")
                ._ToISOString();
            horaSalidas[idDia - 1] = new DateV2()
                ._SetTimeZone(ZonaHoraria)
                ._SetLocalStrHour(Salida)
                ._Truncate("Second")
                ._ToISOString();
        });

        return {
            HoraEntradas: horaEntradas,
            HoraSalidas: horaSalidas,
            Direccion: direccion,
        }
    }

    // **********************************************************************
    // Window things
    // **********************************************************************

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId): void {
        // this.panelEscuela.met_OnServiceEvent(eventName);
        super._OnServiceEvent(eventName);
        switch (eventName) {
            case Entidad.CTipoRequest.HorarioAlumno:
                this.GridUpdateData();
                break;
            case Entidad.CTipoRequest.Escolaridad:
                this.GridUpdateData();
                break;
            case this.GRID_GetDataRequestID():
                this.ctrlProgressBar.attr("oculto", true);
                break;
        }
    }

    // **********************************************************************
    // Process Data
    // **********************************************************************

    private async Sv_AltaEscuela(dato: IEscuelaForm): Promise<DataDRequest.IResultadoPeticion<null>> {
        // console.warn(dato, "data to insert")
        let isFile = ((<any>dato.Logo) instanceof File);
        let res = await DataModuloEscuela._AltaEscuela(dato, (isFile ? dato.Logo as any : null));

        if (res.Resultado > 0) {
            // NOTE Un usuario diferente de Admin, con permisos para dar de alta Escuelas,
            // crea y guarda localmente permisos temporales con vigencia de sesión,
            // reloguarse implica perder tales permisos hasta que un usuario Admin se los asigne.
            //await Data.Util.Permission.fn_CreateAndSaveTemporal_AllPermisosByEscuela(res.Resultado);
            MainPage._CrearPermisosLocalesWK(res.Resultado);
        }
        return res;
    }

    // private async Sv_AsignarCalendarioGoogle(escuela: IEscuelaForm) {
    //     let res = await DataModuloEscuela._AsignarCalendarioGoogle(escuela.IdKinder);
    //     let message = UIUtilLang._GetHTTPMessage(res, "asignargooglecalendar");

    //     if (res.Resultado > 0) {
    //         await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Escuela, escuela.IdKinder);
    //     }
    //     message = message?.replace("_ESCUELA", escuela.Nombre);
    //     this.notificacion._Mostrar(message, (res.Resultado > 0) ? "INFO" : "ADVERTENCIA");
    // }
}
