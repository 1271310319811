import { Entidad } from "../data/Entidad";
import { DataUtil } from "../data/util/Util";
import { VentanaBase } from "../ui/controlD3/AVentanaBase";
import { UIVentanaAlumnos } from "../ui/ventana/Alumnos";
import { UIVentanaAlumnosPanelV2 } from "../ui/ventana/AlumnosPanelV2";
import { UIVentanaAyudaAdmin, UIVentanaAyudaAlumno, UIVentanaAyudaPapas, UIVentanaAyudaTeacher } from "../ui/ventana/AyudaBase";
import { UIVentanaBoletasGeneradas } from "../ui/ventana/BoletasGeneradas";
import { UIVentanaCalendarios } from "../ui/ventana/Calendarios";
import { UIVentanaCircularesV3 } from "../ui/ventana/CircularesV3";
import { UIVentanaCovidEncuesta } from "../ui/ventana/CovidEncuesta";
import { UIVentanaEscuelas } from "../ui/ventana/Escuelas";
import { UIVentanaEscuelasPanelV2 } from "../ui/ventana/EscuelasPanelV2";
import { UIVentanaFinanzasCargos } from "../ui/ventana/FinanzasCargos";
import { UIVentanaFinanzasCargosPorAplicar } from "../ui/ventana/FinanzasCargosPorAplicar";
import { UIVentanaFinanzasCatalogoEgresos } from "../ui/ventana/FinanzasCatalogoEgresos";
import { UIVentanaFinanzasCuentasBancarias } from "../ui/ventana/FinanzasCuentasBancarias";
import { UIVentanaFinanzasEgresosCategorias } from "../ui/ventana/FinanzasEgresosCategorias";
import { UIVentanaFinanzasEgresosMovimientos } from "../ui/ventana/FinanzasEgresosMovimientos";
import { UIVentanaFinanzasEgresosMovimientosProveedor } from "../ui/ventana/FinanzasEgresosMovimientosProveedor";
import { UIVentanaFinanzasEgresosPagosPendientes } from "../ui/ventana/FinanzasEgresosPagosPendientes";
import { UIVentanaFinanzasHorasExtras } from "../ui/ventana/FinanzasHorasExtras";
import { UIVentanaFinanzasMetodosPago } from "../ui/ventana/FinanzasMetodosPago";
import { UIVentanaFinanzasPagosPendientes } from "../ui/ventana/FinanzasPagosPendientes";
import { UIVentanaFinanzasPagosRecibidosV2 } from "../ui/ventana/FinanzasPagosRecibidos";
import { UIVentanaFinanzasProveedores } from "../ui/ventana/FinanzasProveedores";
import { UIVentanaGrupos } from "../ui/ventana/Grupos";
import { UIVentanaGruposPanelV2 } from "../ui/ventana/GruposPanelV2";
import { UIVentanaHome } from "../ui/ventana/Home";
import { UIVentanaLicenciasPagosPendientes } from "../ui/ventana/LicenciasPagosPendientesOld";
import { UIVentanaLicenciasPeriodos } from "../ui/ventana/LicenciasPeriodosOld";
import { UIVentanaLoadServicesV2 } from "../ui/ventana/LoadServicesV2";
import { UIVentanaLogin } from "../ui/ventana/Login";
import { UIVentanaLogroCategorias } from "../ui/ventana/LogroCategorias";
import { UIVentanaLogro } from "../ui/ventana/Logros";
import { UIVentanaMaestros } from "../ui/ventana/Maestros";
import { UIVentanaMateria } from "../ui/ventana/Materias";
import { UIVentanaMateriasCalificadas } from "../ui/ventana/MateriasCalificadas";
import { UIVentanaReportesAsistencia } from "../ui/ventana/ReportesAsistencia";
import { UIVentanaReportesEventos } from "../ui/ventana/ReportesEventos";
import { UIVentanaReporteLogros } from "../ui/ventana/ReportesLogros";
import { UIVentanaTutor } from "../ui/ventana/Tutores";
import { UIVentanaUsuarios } from "../ui/ventana/Usuarios";
import CModulo = Entidad.CModulo;

const ROUTES = {
    "loading": <IRouteItem>{
        View: UIVentanaLoadServicesV2,
    },
    "login": <IRouteItem>{
        View: UIVentanaLogin,
    },
    "home": <IRouteItem>{
        View: UIVentanaHome,
    },
    // "escuelas": null,
    "escuelas/escuelas": <IRouteItem>{
        View: UIVentanaEscuelas,
        IdModulo: CModulo.Escuelas,
    },
    "escuelas/escuelas/panel": <IRouteItem>{
        View: UIVentanaEscuelasPanelV2,
        IdModulo: CModulo.Escuelas,
    },
    "escuelas/grupos": <IRouteItem>{
        View: UIVentanaGrupos,
        IdModulo: CModulo.Grupos,
    },
    "escuelas/grupos/panel": <IRouteItem>{
        View: UIVentanaGruposPanelV2,
        IdModulo: CModulo.Grupos,
    },
    "escuelas/maestros": <IRouteItem>{
        View: UIVentanaMaestros,
        IdModulo: CModulo.Maestros,
    },
    "escuelas/calendarios": <IRouteItem>{
        View: UIVentanaCalendarios,
    },
    // "alumnos": null,
    "alumnos/alumnos": <IRouteItem>{
        View: UIVentanaAlumnos,
        IdModulo: CModulo.Alumnos,
    },
    "alumnos/alumnos/panel": <IRouteItem>{
        View: UIVentanaAlumnosPanelV2,
        IdModulo: CModulo.Alumnos,
    },
    "alumnos/tutores": <IRouteItem>{
        View: UIVentanaTutor,
        IdModulo: CModulo.Tutores
    },
    // "alumnos/licencias": <IRouteItem>{
    //     Environment: "debug",
    // },
    "alumnos/licencias/licencias": <IRouteItem>{
        View: UIVentanaLicenciasPeriodos,
        Environment: "beta",
    },
    "alumnos/licencias/pagospendientes": <IRouteItem>{
        View: UIVentanaLicenciasPagosPendientes,
        Environment: "beta",
    },
    "alumnos/covid": <IRouteItem>{
        View: UIVentanaCovidEncuesta,
    },
    // "finanzas": <IRouteItem>null,
    // "finanzas/catalogos": <IRouteItem>null,
    "finanzas/catalogos/metodospago": <IRouteItem>{
        View: UIVentanaFinanzasMetodosPago,
        IdModulo: CModulo.FinanzasMetodosPago,
    },
    "finanzas/catalogos/ingresos": <IRouteItem>{
        View: UIVentanaFinanzasCargos,
        IdModulo: CModulo.FinanzasCargo,
    },
    "finanzas/catalogos/proveedores": <IRouteItem>{
        View: UIVentanaFinanzasProveedores,
        IdModulo: CModulo.FinanzasProveedores,
    },
    "finanzas/catalogos/cuentas": <IRouteItem>{
        View: UIVentanaFinanzasCuentasBancarias,
        IdModulo: CModulo.FinanzasCuentasBancarias,
    },
    "finanzas/catalogos/egresos": <IRouteItem>{
        View: UIVentanaFinanzasCatalogoEgresos,
        IdModulo: CModulo.FinanzasEgresos,
    },
    "finanzas/catalogos/egresoscategorias": <IRouteItem>{
        View: UIVentanaFinanzasEgresosCategorias,
        IdModulo: CModulo.FinanzasCategoriasEgresos,
    },
    // "finanzas/ingresos": <IRouteItem>null,
    "finanzas/ingresos/horasextras": <IRouteItem>{
        View: UIVentanaFinanzasHorasExtras,
        IdModulo: CModulo.FinanzasHorasExtras,
    },
    "finanzas/ingresos/pagosrecibidos": <IRouteItem>{
        View: UIVentanaFinanzasPagosRecibidosV2,
        IdModulo: CModulo.FinanzasPagosRecibidos,
    },
    "finanzas/ingresos/pagospendientes": <IRouteItem>{
        View: UIVentanaFinanzasPagosPendientes,
        IdModulo: CModulo.FinanzasPagosPendientes,
    },
    "finanzas/ingresos/cargosporaplicar": <IRouteItem>{
        View: UIVentanaFinanzasCargosPorAplicar,
        IdModulo: CModulo.FinanzasCargosPorAplicar,
    },
    // "finanzas/egresos": <IRouteItem>null,
    "finanzas/egresos/cuentasporpagar": <IRouteItem>{
        View: UIVentanaFinanzasEgresosPagosPendientes,
        IdModulo: CModulo.FinanzasEgresosPagosPendientes,
    },
    "finanzas/egresos/movimientos": <IRouteItem>{
        View: UIVentanaFinanzasEgresosMovimientos,
        IdModulo: CModulo.FinanzasEgresosMovimientos,
        DefaultParams: "escs=0",
    },
    "finanzas/egresos/movimientosproveedor": <IRouteItem>{
        View: UIVentanaFinanzasEgresosMovimientosProveedor,
        IdModulo: CModulo.FinanzasEgresosMovimientos,
        DefaultParams: "escs=0",
    },
    "circulares": <IRouteItem>{
        View: UIVentanaCircularesV3,
        IdModulo: CModulo.Circulares
    },
    // "logros": <IRouteItem>null,
    "logros/logros": <IRouteItem>{
        View: UIVentanaLogro,
        IdModulo: CModulo.Logros,
    },
    "logros/categorias": <IRouteItem>{
        View: UIVentanaLogroCategorias,
        IdModulo: CModulo.LogrosCategorias,
    },
    // "calificaciones": <IRouteItem>null,
    "calificaciones/materias": <IRouteItem>{
        View: UIVentanaMateria,
        IdModulo: CModulo.Materias,
    },
    "calificaciones/boletasgeneradas": <IRouteItem>{
        View: UIVentanaBoletasGeneradas,
        IdModulo: CModulo.Materias,
    },
    "calificaciones/materiascalificadas": <IRouteItem>{
        View: UIVentanaMateriasCalificadas,
        IdModulo: CModulo.Materias,
        DefaultParams: "escs=0",
        Environment: "debug"
    },
    "usuarios": <IRouteItem>{
        View: UIVentanaUsuarios,
        IdModulo: CModulo.Usuarios
    },
    // "reportes": <IRouteItem>null,
    "reportes/asistencias": <IRouteItem>{
        View: UIVentanaReportesAsistencia,
        IdModulo: CModulo.ReporteAsistencias,
        DefaultParams: "escs=0",
    },
    "reportes/eventos": <IRouteItem>{
        View: UIVentanaReportesEventos,
        IdModulo: CModulo.ReporteEventos,
        DefaultParams: "escs=0",
    },
    "reportes/logros": <IRouteItem>{
        View: UIVentanaReporteLogros,
        IdModulo: CModulo.ReporteLogros,
    },
    // "ayuda": <IRouteItem>null,
    "ayuda/admin": <IRouteItem>{
        View: UIVentanaAyudaAdmin,
    },
    "ayuda/maestros": <IRouteItem>{
        View: UIVentanaAyudaTeacher,
    },
    "ayuda/papas": <IRouteItem>{
        View: UIVentanaAyudaPapas,
    },
    "ayuda/alumnos": <IRouteItem>{
        View: UIVentanaAyudaAlumno,
    },
} as const;

export const _ROUTES = ((R) => {
    for (const k in ROUTES) {
        const r = ROUTES[k] as typeof ROUTES[TRoutePath];
        if (!DataUtil._EvalItemEnvironment(r.Environment, true))
            continue;
        R[k] = r;
    }
    return R;
})(<typeof ROUTES>{})

export type TRoutePath = keyof typeof _ROUTES;

export type IRouteItem = /* (typeof VentanaBase<CModulo>) | */ {
    readonly View: typeof VentanaBase<CModulo>;
    /** Se valida de acuerdo a los permisos de usuario, en caso de undefined, el item es válido */
    readonly IdModulo?: CModulo,
    readonly Environment?: TEnvironment;
    readonly DefaultParams?: string | ({ [k in string]: (string | number | boolean | any[]) });
}
