import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { CAlumnoEdoLicencia, CLicenciaEstado, ILicenciaEstado, ILicenciaPeriodo, ILicenciaPeriodoDetalle } from '../../data/entidad/LicenciaOld';
import { _DICC_ALUMNO } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { _SvLicenciaObtenerListaPagoProgramado, _SvLicenciaObtenerListaPeriodos, _SvLicenciaObtenerListaPorPeriodo } from "../../data/modulo/LicenciaOld";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { HTMLImage2Component } from "../controlWC/HTMLImage2Component";
import { UIUtilFormat } from "../util/Format";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { _OpenModal_ProcesoPago } from "../utilView/LicenciaPago";

interface ILicenciaPeriodoGrid extends ILicenciaPeriodo {
    PeriodoFmt: string
    NombreKinder: string
    TienePagoProgramado: boolean
    Count: number
    KinderFiltro: number[]
}

interface ILicenciaPagar {
    IdLicencia: number
    IdChild: number
    NombreCompleto: string
    LicEstado: CLicenciaEstado
    LicDias?: number;
    // /** NO representa al total a pagar */
    LicDescripcion?: string;
    LicAdeudo: number
    LicFechaAsignacionFmt: string
    LicFechaVencimientoFmt: string
}
const LIC_EDO_PUEDEPAGAR: (CAlumnoEdoLicencia | CLicenciaEstado)[] = [
    CLicenciaEstado.Vencido,
    CLicenciaEstado.Caducado,
]

export class UIVentanaLicenciasPeriodos extends VentanaGrid<ILicenciaPeriodoGrid> {
    constructor(content: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(content, modulo, "licencia");
        this.GridTreeEscuelasMultiselect(false);
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ILicenciaPeriodoGrid[]): Table.ITableMenuDataSelectedOptionConfig<ILicenciaPeriodoGrid>[] {
        let opciones: Table.ITableMenuDataSelectedOptionConfig<ILicenciaPeriodoGrid>[] = [];

        opciones.push({
            Label: PeriodoPuedePagarse(dataGridSelected[0]) ? "tag_pay" : "tag_detail",
            Callback: ([periodo]) => this.OpenModal_Detalles(periodo),
            MultiData: false,
        });

        return opciones;
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<ILicenciaPeriodoGrid>[] {
        return []
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ILicenciaPeriodoGrid> {
        return {
            IdTabla: "Licencias",
            IdData: "Periodo",
            DefaultSort: null,
            MinWidth: 600,
            Columns: [
                { Field: "PeriodoFmt", Label: "periodo", Width: "14%", MinWidth: "100px" },
                { Field: "Count", Label: "Licencias", Width: "12%", MinWidth: "80px" },
                { Field: "NombreKinder", Label: "Escuela", Width: "14%", MinWidth: "100px" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ILicenciaPeriodoGrid> {
        const fnAdjustUIRow = (datum: ILicenciaPeriodoGrid, container: TSelectionHTML<"div">) => {
            if (datum.TienePagoProgramado) {
                let infoDiv = container.select(".infopago").classed("hide", false);

                if (!infoDiv.node()) {
                    infoDiv = container.append("div")
                        .classed("infopago", true)
                        .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                        .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
                        .style("column-gap", "5px");

                    infoDiv.append("img")
                        .attr("src", UIUtilIconResources.CGeneral.InfoMark)
                        .attr("draggable", false)
                        .style("width", "15px");

                    infoDiv.append("span")
                        .style("font-size", "var(--fontsize_me2)")
                        .style("color", "var(--color_app_red1)")
                        .text("Pago pendiente");
                }
            } else {
                container.select(".infopago").classed("hide", true);
            }
        }

        return {
            EvaluatorAndSubLevelsBuild: {
                OnEvalIsEnableRow: (datum) => !datum.TienePagoProgramado,
                OnStepCellTable: (container, datum, field: keyof ILicenciaPeriodoGrid) => {
                    container.style("color", PeriodoPuedePagarse(datum) ? "var(--color_app_red1)" : "")
                    switch (field) {
                        case "PeriodoFmt":
                            fnAdjustUIRow(datum, container);
                            break;
                        case "Count":
                            const licenciasHTML = GetLicenciasCellHTML(datum)

                            container.style("width", "100%")
                            container.html(licenciasHTML)
                            container.selectAll("td").style("border", "none")
                            break;
                    }
                }
            }
        }
    }

    protected GRID_GetExportarConfig(dataGrid: ILicenciaPeriodoGrid[]): IConfigGridExcelExport<ILicenciaPeriodoGrid> {
        return null
        // const idEscuela = dataGrid[0].IdEscuela;
        // const nombreEscuela = dataGrid[0].NombreKinder;
        // const fileName = _L("licencia.export_filename") + " " + nombreEscuela;
    }

    protected async GridGetData(): Promise<ILicenciaPeriodoGrid[]> {
        return new Promise(async (resolve) => {
            const idsEscuelasFiltro = this.GridGetEscuelasSeleccionadas().map(d => d.IdKinder);

            const resLicPagoProgramado = await _SvLicenciaObtenerListaPagoProgramado(idsEscuelasFiltro);
            if (resLicPagoProgramado.Resultado <= 0) {
                this.notificacion._Mostrar(_L("general.notif_fail_infoupdate"), "ADVERTENCIA");
                resolve([]);
                return;
            }

            const licenciasPeriodos: ILicenciaPeriodo[] = await (async () => {
                const list: ILicenciaPeriodo[] = []
                for (const idEscuela of idsEscuelasFiltro) {
                    const res = await _SvLicenciaObtenerListaPeriodos(idEscuela);
                    if (res.Resultado <= 0) {
                        // resolve([]);
                        this.notificacion._Mostrar(_L("general.notif_fail_infoupdate"), "ADVERTENCIA");
                        return [];
                    }
                    list.push(...res.Datos)
                }
                return list;
            })()


            // const licenciaPagosPendientes: ILicenciaPagoPendiente[] = resLicPagoProgramado.Datos;
            // const licencias: ILicenciaEdo[] = resLic;
            // const alumnos = DataModuloMain._GetReqDataArrayByName("Alumno", true);
            //const licenciasPorAlumnoMap = d3Group(licencias, (d) => d.IdAlumno);
            const idsAlumnosPagoProgramado = (() => {
                const res = new Set<number>()
                resLicPagoProgramado.Datos.forEach(licpp => {
                    licpp.IdsAlumno.forEach(idAlumno => {
                        if (!res.has(idAlumno)) {
                            res.add(idAlumno);
                        }
                    })
                })
                return res
            })()


            let gridData: ILicenciaPeriodoGrid[] = licenciasPeriodos.map<ILicenciaPeriodoGrid>(d => ({
                ...d,
                Count: d.Licencias.length,
                KinderFiltro: [d.IdEscuela],
                PeriodoFmt: UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(d.Periodo, "MMM yyyy")),
                TienePagoProgramado: d.Licencias.some(l => idsAlumnosPagoProgramado.has(l.IdAlumno)),
                NombreKinder: DataModuloMain._GetDataValueFieldByName("Escuela", d.IdEscuela, "Nombre"),
            }))

            resolve(gridData)
        })
    }

    protected async GridOnKinderSelectionChange(): Promise<void> {
        await super.GridOnKinderSelectionChange();
        this.ctrlTabla._CheckAllItems(false);
        this.GridUpdateDataWithDelay();
    }

    // **************************************************************************
    // MODAL ACTIONS
    // **************************************************************************

    private async OpenModal_Detalles({ IdEscuela: idEscuela, Periodo, PeriodoFmt }: ILicenciaPeriodoGrid) {
        this.ctrlProgressBar.node()._Visible = true;
        const licencias = await _SvLicenciaObtenerListaPorPeriodo(idEscuela, Periodo)
            .then((res) => {
                return res.Datos
            })
            .finally(() => {
                this.ctrlProgressBar.node()._Visible = false;
            })
        const licenciasPagar = licencias.filter(d => LIC_EDO_PUEDEPAGAR.includes(d.Estado))

        // const idEscuela = alumnos[0].IdKinder;
        const escuela = DataModuloEscuela._DiccFullEscuelas.get(idEscuela);
        let ctrlTabla: Table.Tabla<ILicenciaPagar>;
        let totalPagar = this.GetPay_TotalPago(licenciasPagar);

        const fnGetTotalAlumnos = () => {
            // let totalAlumnos = this.GetTotalAlumnosAPagarEnEscuela(idEscuela);
            const alumnosEnEscuela = Array.from(_DICC_ALUMNO.values()).filter(d => d.IdKinder == idEscuela)
            return licenciasPagar.length + "/" + alumnosEnEscuela.length;
        }
        const datosTbl: ILicenciaPagar[] = licencias.map<ILicenciaPagar>(d => {
            const dtAsignacion = new DateV2(d.Asignacion)._SetTimeZoneByIdSchool(idEscuela)
            const daysInMonth = UIUtilTime._GetDaysInMonth(dtAsignacion)
            return {
                IdLicencia: d.Id,
                IdChild: d.IdAlumno,
                NombreCompleto: _DICC_ALUMNO.get(d.IdAlumno).NombreCompleto,
                LicEstado: d.Estado as any,
                LicDescripcion: (d.Dias == daysInMonth) ? _L("licencia.liccompleta") : _L("licencia.licparcial"),
                LicDias: d.Dias,
                LicAdeudo: d.CostoTotal,
                LicFechaAsignacionFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.Asignacion, idEscuela),
                LicFechaVencimientoFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.Vencimiento, idEscuela),
            }
        })

        ModalThings._GetModalToAProccess({
            Title: licenciasPagar.length ? _L("licencia.mainpay_title") : _L("licencia.tag_detail"),
            Width: 1000,
            Height: "800px",
            DrawContent: (container, mt) => {
                container.classed("licencia_pagar_view_seleccionados", true)
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("gap", "var(--padding2)");

                let infoContainer = container.append("div")
                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                    .classed(UIUtilGeneral.FBoxAlign.SpacebetweenStart, true)
                // .style("min-height", "100px");
                let leftInfoCont = infoContainer.append("div")
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .classed(UIUtilGeneral.FBoxAlign.StartStart, true)
                    .style("row-gap", "5px");
                let rightInfoCont = infoContainer.append("div")

                // LEFT INFO
                leftInfoCont.html(`<label><b>${_L("licencia.tag_escuela")}</b>: ${escuela.Nombre}</label>
                    <label><b>${_L("licencia.tag_periodo")}</b>: ${PeriodoFmt}</label>`
                    + (licenciasPagar.length
                        ? `<label><b>${_L("licencia.tag_totalalumnos")}</b>: ${fnGetTotalAlumnos()}</label>
                    <label><b>${_L("licencia.tag_totalpago")}</b>: ${UIUtilFormat._CurrencyFmt(totalPagar)}</label>`
                        : ""))

                // RIGHT INFO
                rightInfoCont.append<HTMLImage2Component>("wc-img")
                    .attr("class", "escuela_logo")
                    .attr("spinner-border-width", 5)
                    .attr("spinner-dim", 40)
                    .attr("src", escuela.Logo);

                // TABLA
                ctrlTabla = new Table.Tabla<ILicenciaPagar>({
                    IdTabla: "Licencias-Pagar",
                    IdData: "IdChild",
                    MinWidth: 900,
                    Parent: container,
                    HideCheckboxes: true,
                    FilterByStrSearch: "none",
                    RenderColumnHeadings: [
                        { Field: "NombreCompleto", Label: _L("licencia.d_field_nombrecompleto"), Width: "20%", MinWidth: "100px" },
                        { Field: "LicAdeudo", Label: _L("licencia.totaladeudo"), Width: "14%", MinWidth: "100px" },
                        { Field: "LicFechaAsignacionFmt", Label: _L("licencia.d_field_licfechaasignacion"), Width: "15%", MinWidth: "100px" },
                        { Field: "LicFechaVencimientoFmt", Label: _L("licencia.d_field_licfechavencimiento"), Width: "15%", MinWidth: "100px" },
                        { Field: "LicDias", Label: _L("licencia.d_field_licdias"), Width: "8%", MinWidth: "60px" },
                        { Field: "LicDescripcion", Label: _L("licencia.descripcion"), Width: "15%", MinWidth: "100px" },
                        { Field: "LicEstado", Label: _L("licencia.d_field_licestado"), Width: "10%", MinWidth: "100px" }
                    ],
                    EvaluatorAndSubLevelsBuild: {
                        OnStepCellTable: (container, datum, field: keyof ILicenciaPagar) => {
                            switch (field) {
                                case "LicAdeudo":
                                    container.text(UIUtilFormat._CurrencyFmt(datum[field]));
                                    break;
                                case "LicEstado":
                                    container.text(UIUtilViewData._GetStr_LicenciaEdo(datum[field]));
                                    break;
                            }
                        }
                    }
                });

                ctrlTabla._UpdateData(datosTbl);

                // PAGAR
                if (licenciasPagar.length) {
                    mt.BtnLeft.remove()
                    mt.BtnRight.text(_L("licencia.tag_pay"))
                } else {
                    mt.Modal._EnableEnterKey(false)
                    mt.Modal._FooterSelection.remove()
                }
            },
            OnAccept: (mt) => {
                mt.Progress.attr("oculto", true);
                const licenciasPagarFinal: ILicenciaEstado[] = licenciasPagar
                    .map(d => ({
                        ...d,
                        FechaAsignacion: d.Asignacion,
                        FechaVencimiento: d.Vencimiento,
                        IdLicencia: d.Id
                    })) ///: ILicenciaPeriodoGrid[] = licencias.map(d => d.Licencia)

                return _OpenModal_ProcesoPago(idEscuela, totalPagar, licenciasPagarFinal, licenciasPagar.length)
                    .then(res => {
                        if (res) {
                            console.warn("licencia > uncheck, updateview")
                            this.ctrlTabla._CheckAllItems(false);
                            this.GridUpdateData();
                        }
                        return res
                    })
            }
        })
    }

    // **************************************************************************
    // DATA
    // **************************************************************************

    private GetPay_TotalPago(alumnos: ILicenciaPeriodoDetalle[]) {
        let totalPago = 0;
        alumnos.forEach(d => {
            // d.Licencia.forEach(lic => {
            totalPago += d.CostoTotal;
            // });
        })
        return totalPago;
    }

}

function PeriodoPuedePagarse(dataGridSelected: ILicenciaPeriodoGrid): boolean {
    return dataGridSelected.Licencias.some(l => LIC_EDO_PUEDEPAGAR.includes(l.Estado))
}

function GetLicenciasCellHTML(datum: ILicenciaPeriodoGrid): string {
    const statusCount: { [k in keyof typeof CLicenciaEstado & string]: number } = <any>{}
    datum.Licencias.forEach(d => {
        if (statusCount[CLicenciaEstado[d.Estado]] == null)
            statusCount[CLicenciaEstado[d.Estado]] = 1
        else
            statusCount[CLicenciaEstado[d.Estado]]++
    })
    const alumnosSinLics = (() => {
        const esPeriodoActual = new Date(datum.Periodo).getTime() == UIUtilTime._TruncDate(new Date(), "Day").getTime()
        if (!esPeriodoActual)
            return 0
        const totalEscuelaAlumnos = Array.from(_DICC_ALUMNO.values()).filter(d => d.IdKinder == datum.IdEscuela).length
        const totalEscuelaLicencias = datum.Licencias.length
        return totalEscuelaAlumnos - totalEscuelaLicencias
    })()
    const fnCreateRow = (tag: string, value: number) => (value > 0 ? `<tr> <td>${tag}</td> <td style="text-align:end;">${value}</td> </tr>` : "")

    let licenciasHTML = `<table width="85%">`
    licenciasHTML += fnCreateRow(_L("licencia.tag_lics_activa"), statusCount.Activo)
    licenciasHTML += fnCreateRow(_L("licencia.tag_lics_pausa"), statusCount.Pausado)
    licenciasHTML += fnCreateRow(_L("licencia.tag_lics_vencida"), statusCount.Vencido)
    licenciasHTML += fnCreateRow(_L("licencia.tag_lics_caducada"), statusCount.Caducado)
    licenciasHTML += fnCreateRow(_L("licencia.tag_lics_pagada"), statusCount.Pagado)
    licenciasHTML += fnCreateRow(_L("licencia.tag_alumn_sinlics"), alumnosSinLics)
    licenciasHTML += "</table>"
    return licenciasHTML
}
