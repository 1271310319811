import { Selection, ascending, select } from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DiccEscuela } from "../../data/modulo/Escuela";
import { DataUtilLocalStorage } from "../../data/util/LocalStorage";
import { HTMLImage2Component } from "../controlWC/HTMLImage2Component";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilMonitorRequestObserver } from "../util/MonitorRequestObserver";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilGeneral } from "../util/Util";
import { UIWindowManager } from "../ventana/WindowManager";
import { VentanaBase } from "./AVentanaBase";
import { ExcelThings } from "./ExcelExport";
import { FormGenerator } from "./Formulario";
import { ModalThings } from "./ModalThings";
import { NotificacionV2 } from "./NotificacionV2";
import { Table } from "./Tabla";
import { TreeView } from "./TreeView";

import CModulo = Entidad.CModulo;
export type IGridRenderInfo<TData = Object> = Pick<Table.IConfig<TData>,
    "IdTabla" | "Title" | "IdData" | "MinWidth" | "MenuInRowNoCellsToIgnoreWidth" | "ItemGrouperField"
> & {
    DefaultSort: Table.IConfig<TData>["OrderDefault"] | (keyof TData & string),
    Columns: Array<Table.IColumn<TData>>,
    MaxOptionsInRow?: number;
}

export type IGridExtraTableConfig<T> = Pick<
    Table.IConfig<T>,
    "StickyCheckInRow" | "EnableStickyFields" | "AddNameFieldClassToCells"
    | "EvaluatorAndSubLevelsBuild" | "HideCheckboxes" | "OnEndUpdateDataInView"
    | "FilterByStrSearch" | "EnableRelevanceSelections" | "HideItemWhenItHasNoChildItems"
    | "OnFilter"
>

type DatumTreeView = Entidad.IEscuela;

interface IStatusGrid {
    TreeCollapsed: boolean;
    TableOrderField: string;
    TableOrderType: any;
}

interface IVentanaGridConfigExtra {
    LabelsKeyBase?: string;
    /** Modulos que refrescan la tabla cuando el Monitoreo retorna datos
     *
     * Si los TipoRequest no son monitoreables; activa monitoreo al Mostrar, desactiva al Destruir ventana */
    ModuloObservableToTblRefresh?: DataModuloMain.TipoRequestMonitorId[];
    ModuloForceRequestOnMostrar?: DataModuloMain.TipoRequestMonitorId[];
    TableExportAutoBuildData?: boolean;
}
interface IVentanaGridConfigExtraControl extends IVentanaGridConfigExtra {
    /** Activa monitoreo al Mostrar, desactiva al Destruir ventana */
    __ModuloObservableToTblRefreshForce?: DataModuloMain.TipoRequestMonitorId[];
}

export abstract class VentanaGrid<GridType extends { KinderFiltro?: number[] }, Modulo extends CModulo = CModulo> extends VentanaBase<Modulo> {
    protected rightContent: Selection<HTMLDivElement, undefined, HTMLElement, any>;
    private leftContent: Selection<HTMLDivElement, undefined, HTMLElement, any>;

    protected treeView: TreeView<DatumTreeView>;
    protected ctrlProgressBar: TSelectionHTML<"wc-progress">;

    protected kinders: Array<Entidad.IEscuela>;
    // private tablePrimaryID: any;
    // private initStatus: IGridParamsUse;

    protected dataGrid: GridType[];
    private extras: IVentanaGridConfigExtraControl;

    constructor(content: Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Modulo, extras?: string | IVentanaGridConfigExtra) {
        const labelsKeyBase = typeof extras === "string" ? extras : extras?.LabelsKeyBase;
        super(content, modulo, labelsKeyBase);

        this.kinders = this.ObtenerEscuelasList()
        this.dataGrid = [];
        this.extras = (() => {
            if (typeof extras === "string") {
                return {
                    LabelsKeyBase: extras,
                };
            }
            return extras || {};
        })();

        this.InitGridStatus();
        this._InitLeftContent();
        this._InitMainContent();

        if (this.GRID_GetDataRequestID() != null) {
            MainPage._ReloadService(this.GRID_GetDataRequestID(), (this.kinders.length ? this.kinders.map(d => d.IdKinder) : null), () => {
                this.ctrlProgressBar.attr("oculto", true);
            });
        }

        setTimeout(() => {
            this.InitInicialParams();
        });

        if (this.extras.ModuloObservableToTblRefresh?.length) {
            this.extras.__ModuloObservableToTblRefreshForce = this.extras.ModuloObservableToTblRefresh
                .filter(d => !(DataModuloMain._GetModuleConfig(d).IsMonitorable));

            new UIUtilMonitorRequestObserver()
                ._SetCancelingElement(this.windowContent.node())
                ._SetCallbackLinked(this.extras.ModuloObservableToTblRefresh, ({ Data: datos, TipoRequest }) => {
                    if (datos.length) {
                        this.ctrlTabla._RefreshView();
                    }
                })
                ._ObserveAll();
        }
    }

    private InitGridStatus() {
        const currentPath = UIWindowManager._GetHashInfoInHistory().Path;
        this.VB_SetStatusKey(currentPath);
        let strWinStTreeSchools = DataUtilLocalStorage._GetItem("winstatus", "treeschools");
        let objWinStTreeSchools = strWinStTreeSchools ? JSON.parse(strWinStTreeSchools) : {};
        if (objWinStTreeSchools["Collapsed"]) {
            // console.log("Collapse");
            this.CollapseLeftContent(true);
        }
    }

    private async InitInicialParams() {
        const paramsAux = UIWindowManager._GetCurrentParams();
        let paramIdsEscuelas = (paramsAux.get("escs")?.split(",").map(d => Number(d)) || null)
        let paramSearchTable = paramsAux.get("src")
        // console.warn("Grid init paramas", paramIdsEscuelas, paramSearchTable);

        this.ctrlProgressBar.attr("oculto", false);
        this.ctrlTabla._SetSearchText(paramSearchTable);

        this.GridRefreshKinderList();

        if (paramIdsEscuelas === null) {
            paramIdsEscuelas = this.kinders.map(d => d.IdKinder);
        }
        this.treeView._CheckItems(paramIdsEscuelas, true);

        await this.GridUpdateData(true, true);

        // Event listener
        const onchangeparams: ((e: UIWindowManager.HashEvent) => void) = this.OnChangeParams.bind(this);
        this["__onchangeparams"] = onchangeparams;
        UIWindowManager._AddOnParamsChangeEventListener(onchangeparams);
    }

    private OnChangeParams(e: UIWindowManager.HashEvent) {
        let paramIdsEscuelas = e.detail.Params.get("escs")?.split(",").map(d => Number(d)) || null;
        let paramSearchTable = e.detail.Params.get("src");
        console.warn("Grid onchange params", paramIdsEscuelas, paramSearchTable);

        if (this.ctrlTabla._CurrentSearchText != paramSearchTable) {
            this.ctrlTabla._SetSearchText(paramSearchTable);
        }
        if (paramIdsEscuelas == null) {
            paramIdsEscuelas = this.kinders.map(d => d.IdKinder);
        }

        if (this.treeView._DataChecked.map(d => d.IdKinder).toString() != paramIdsEscuelas?.toString()) {
            this.treeView
                ._CheckAllItems(false)
                ._CheckItems(paramIdsEscuelas, true);
            this.GridOnKinderSelectionChange();
        }
        // this.GridUpdateData(false, false);
    }

    public _Mostrar(): void {
        super._Mostrar();
        this.extras.ModuloForceRequestOnMostrar?.forEach(idReq => {
            MainPage._ReloadService(idReq);
        });
        this.extras.__ModuloObservableToTblRefreshForce?.forEach(idReq => {
            // console.debug("Activando Monitor Req:", idReq, Entidad.CTipoRequest[idReq])
            MainPage._ActivarServiceMonitor(idReq, true);
        });
    }

    public _Destroy(): void {
        super._Destroy();
        if (this["__onchangeparams"]) {
            UIWindowManager._RemoveOnParamsChangeEventListener(this["__onchangeparams"]);
        }
        this.extras.__ModuloObservableToTblRefreshForce?.forEach(idReq => {
            MainPage._ActivarServiceMonitor(idReq, false);
        })
    }

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId, reloadId?: number, error?: Error): void {
        super._OnServiceEvent(eventName, reloadId, error);
        if (eventName == Entidad.CTipoRequest.Escuela) {
            this.GridRefreshKinderList(/* this.kinders.length == 0 */);
        }
        if (eventName == this.GRID_GetDataRequestID()) {
            this.GridUpdateData();
        }
    }

    private _InitMainContent(): void {
        this.rightContent = this.windowContent.append("div")
            .attr("class", "right")
            .style("overflow", "auto")
            .style("position", "relative");

        this.GridInitTable();
    }

    protected GridInitTable(): void {
        this.CreatTabla(this.rightContent);
        this.ctrlProgressBar = this.ctrlTabla._Control.append<HTMLProgressElement>("wc-progress").attr("oculto", true) as unknown as TSelectionHTML<"wc-progress">;
    }

    private CollapseLeftContent(collapsed: boolean = !this.windowContent.classed("collapsed")) {
        this.windowContent
            .classed("collapsed", collapsed);
        let strWinStTreeSchools = DataUtilLocalStorage._GetItem("winstatus", "treeschools")
        let objWinStTreeSchools = strWinStTreeSchools ? JSON.parse(strWinStTreeSchools) : {}
        objWinStTreeSchools["Collapsed"] = collapsed;
        DataUtilLocalStorage._SetItem("winstatus", "treeschools", JSON.stringify(objWinStTreeSchools));
    }

    private _InitLeftContent(): void {
        this.leftContent = this.windowContent.append("div")
            .attr("class", "left");

        const headerContainer = this.leftContent
            .append("div")
            .attr("class", "header " + UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.SpacebetweenCenter);

        headerContainer.append("label")
            .attr("class", "lbl_header")
            .text(UIUtilLang._GetUIString("grid", "tree_header"));

        headerContainer.append("img")
            .attr("class", "btn_collapser")
            .attr("src", UIUtilIconResources.CGeneral.AngleDown)
            .attr("draggable", false)
            .on("click", () => {
                this.CollapseLeftContent();
            });

        let chanceParamSrcTimeout: NodeJS.Timeout;
        this.treeView = new TreeView({
            ParentContainer: this.leftContent,
            IdDataSub: "IdKinder",
            GetContentTop: (container) => UIUtilLang._GetUIString("grid", "tree_vertodo"),
            StepUpdateContent: (container, dato) => {
                let logoIMG = container.select<HTMLImage2Component>(":scope>wc-img");
                if (!logoIMG.node()) {
                    logoIMG = container.append<HTMLImage2Component>("wc-img")
                        .attr("default-src", UIUtilIconResources.CMenuIco.Escuela);
                    container.append("label");
                    select(container.node().parentElement).append<HTMLTooltipComponent>("wc-tooltip")
                        .attr("position", "right");
                }
                if (logoIMG.attr("src") != dato.Logo) {
                    logoIMG.attr("src", dato.Logo);
                }

                container.select(":scope > label")
                    .text(dato.Nombre);
                select(container.node().parentElement).select<HTMLTooltipComponent>(":scope > wc-tooltip")
                    .text(dato.Nombre);
            },
            OnChangeByUser: () => {
                const escuelasSeleccionadas = this.GridGetEscuelasSeleccionadas();
                if (chanceParamSrcTimeout) {
                    clearTimeout(chanceParamSrcTimeout);
                    chanceParamSrcTimeout = null;
                }
                chanceParamSrcTimeout = setTimeout(() => {
                    this.GridOnKinderSelectionChange();
                    chanceParamSrcTimeout = null;
                    UIWindowManager._SetParam("escs", escuelasSeleccionadas.map(d => d.IdKinder));
                }, 500);
            },
        });
    }

    protected GridTreeEscuelasMultiselect(multiselect: boolean = true) {
        this.treeView._Multiselect(multiselect);
    }

    protected GridRefreshKinderList(): void {
        this.kinders = this.ObtenerEscuelasList()

        this.treeView._UpdateData(this.kinders);
    }

    protected GridSetEscuelasSeleccionadas(idsEscuelas: number[], checked: boolean) {
        return this.treeView._CheckItems(idsEscuelas, checked);
    }

    protected GridGetEscuelasSeleccionadas() {
        return this.treeView._DataChecked;
    }

    protected TranslateArrayObject<ObjOriginal, ObjCopy>(originArray: Array<ObjOriginal>, parametros: Array<{ keyOrigen: keyof ObjOriginal, keyCopy: keyof ObjCopy }>): Array<ObjCopy> {
        let auxObject = Array<ObjCopy>();
        originArray.forEach(objOrigen => {
            let objAux = <ObjCopy>{}
            parametros.forEach(param => {
                objAux[param.keyCopy] = objOrigen[param.keyOrigen] as any
            })
            auxObject.push(objAux)
        })
        return auxObject;
    }

    // NOTE
    // FIXME AJUSTAR SOOBREESCRITURAS
    protected async GridOnKinderSelectionChange(): Promise<void> {
        await this.GridUpdateData(false, false);
    }

    protected abstract GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId; // data.Entidades.CTipoRequest

    protected abstract GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig>;

    /** Aplica para las filas del nivel 1 */
    protected abstract GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: GridType[]): Array<Table.ITableMenuDataSelectedOptionConfig<GridType>>;

    protected abstract GRID_GetFilters(): Array<Table.IParametroFiltro<GridType>>;

    protected abstract GRID_GetTableConfigBase(): IGridRenderInfo<GridType>;

    protected abstract GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<GridType>;

    protected abstract GRID_GetExportarConfig(dataGrid: GridType[]): IConfigGridExcelExport<GridType | any> | null;

    /** Tabla principal del Grid */
    protected ctrlTabla: Table.Tabla<GridType>;
    private CreatTabla(content: Selection<HTMLDivElement, {}, HTMLElement, null>) {
        const gridTableConfig = this.GRID_GetTableConfigBase();

        gridTableConfig.Columns
            .forEach(d => {
                if (d.LabelLangKey === undefined) {
                    d.LabelLangKey = UIUtilLang._FixFieldKey(d.Field);
                }
            })

        if (gridTableConfig.MaxOptionsInRow === undefined)
            gridTableConfig.MaxOptionsInRow = 10;
        let fnGetOptTop = () => {
            let opciones: Table.ITableMenuTopDefaultOptionConfig[];
            opciones = (this.GRID_GetMenuTopGrid() || [])
                .map(d => {
                    let dd = Object.assign({}, d);

                    if (Entidad.CAccionPermiso[d.Label] != null) {
                        dd.Label = UIUtilLang._GetUIString("c_actions", d.Label as any);
                    } else {
                        dd.Label = this.VB_GetUIStringModule(dd.Label);
                    }

                    return dd;
                });
            let hasOnePermiss = this.GridGetEscuelasSeleccionadas().find(d => (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.ExportarAExcel, [d.IdKinder]) == true));
            if ((this.modulo == null && this.ctrlTabla?._dataFiltered.length > 0) || (hasOnePermiss && this.ctrlTabla?._dataFiltered.length > 0) || (this.GridGetEscuelasSeleccionadas().length < 1 && this.ctrlTabla?._dataFiltered.length > 0)) {
                opciones.push({
                    Label: UIUtilLang._GetUIString("c_actions", "exportaraexcel"),
                    Callback: () => {
                        if (this.ctrlTabla._dataFiltered.length < 1) {
                            NotificacionV2._Mostrar("No hay registros para exportar", "ADVERTENCIA");
                        }
                        else {
                            this.GridOpen_ExportarData(this.ctrlTabla._dataFiltered, "filtered");
                        }
                    },
                })
            }
            return opciones;
        }

        const confOptionsDefault: Table.ITableMenuTopDefaultConfig = {
            MaxOptionsInRow: gridTableConfig.MaxOptionsInRow, //1,
            Options: fnGetOptTop()
        }

        let fnGetOpcionExportar: (/* typeMenu: "row" | "top-selected" */) => Table.ITableMenuDataSelectedOptionConfig<GridType>;
        if (this.modulo == null || this.GridHasPermisoAccion(Entidad.CAccionPermiso.ExportarAExcel)) {
            fnGetOpcionExportar = () => ({
                Label: UIUtilLang._GetUIString("c_actions", "exportaraexcel"),
                MultiData: true,
                Callback: (gridData: Array<GridType>) => {
                    this.GridOpen_ExportarData(gridData, "checked");
                },
                GetDetails: (gridData: Array<GridType>) => Boolean(gridData?.length)
            });
        }

        let fnGetOpcionesSeleccionados: (typeMenu: "row" | "top-selected", datos: GridType[]) => Table.ITableMenuDataSelectedConfig<GridType> = (typeMenu, datos) => {
            let opciones = this.GRID_GetSelectionDataMenuV2(typeMenu, datos);
            if (opciones) {
                opciones = opciones
                    .map(d => {
                        let dd = Object.assign({}, d);

                        if (Entidad.CAccionPermiso[d.Label] != null) {
                            dd.Label = UIUtilLang._GetUIString("c_actions", d.Label as any);
                        } else {
                            dd.Label = this.VB_GetUIStringModule(dd.Label);
                        }

                        return dd;
                    })
            }

            if (typeMenu == "top-selected" && fnGetOpcionExportar) {
                if (opciones) {
                    opciones.push(fnGetOpcionExportar());
                } else {
                    opciones = [fnGetOpcionExportar()];
                }
            }
            if (opciones?.length) {
                return {
                    MaxOptionsInRow: typeMenu == "row" ? 4 : 5,
                    Options: opciones
                }
            }
            return null;
        }

        const advancedConfig = this.GRID_GetTableConfigAdvanced()?.EvaluatorAndSubLevelsBuild || {};
        const colEscuela = gridTableConfig.Columns.find(d => d.Label == "Escuela") || null;

        let evalConfig: Table.IStepEvaluator<GridType, any, any> = {
            OnCheckedData: (dataCheck) => {
                console.debug("Check", dataCheck.length);
            },
            GetOptionsInRowV2: (dato) => {
                if (dato) {
                    return fnGetOpcionesSeleccionados("row", [dato]);
                }
                return null;
            },
            ...advancedConfig,
            OnStepCellTable: (...args) => {
                const [cont, d, f] = args;
                if (colEscuela != null && f == colEscuela.Field) {
                    const idEscuela = d.KinderFiltro ? d.KinderFiltro[0] : null;
                    const content = d[f as any];
                    cont.classed("link_item", !!idEscuela);
                    if (idEscuela) {
                        if (!cont.select("a").node()) {
                            cont.text("");
                            cont.append(() => UIUtilGeneral._CreateLinkeableElement(content, `#escuelas/escuelas/panel--id=${idEscuela}`).node());
                        }
                        cont.select("a").attr("href", `#escuelas/escuelas/panel--id=${idEscuela}`).text(content);
                    }
                    else {
                        cont.text(content);
                    }
                }
                if (advancedConfig.OnStepCellTable) {
                    advancedConfig.OnStepCellTable(...args);
                }
            },
        }

        // let gridTableConfig = this.GridGetTableConfig();
        let lastOrderField = this.VB_GetStatusProp<IStatusGrid>("TableOrderField");
        let lastOrderType = this.VB_GetStatusProp<IStatusGrid>("TableOrderType");
        this.ctrlTabla = new Table.Tabla<GridType>({
            IdTabla: gridTableConfig.IdTabla,
            Title: gridTableConfig.Title,
            Parent: content,
            IdData: gridTableConfig.IdData,
            OrderDefault: {
                Field: (lastOrderField || (typeof gridTableConfig.DefaultSort == "string" ? gridTableConfig.DefaultSort : gridTableConfig.DefaultSort?.Field)),
                Type: (lastOrderType || (typeof gridTableConfig.DefaultSort == "string" ? Table.CStatusOrder.Asc : (gridTableConfig.DefaultSort?.Type || Table.CStatusOrder.Asc))),
            },
            MinWidth: gridTableConfig.MinWidth,
            MenuInRowNoCellsToIgnoreWidth: gridTableConfig.MenuInRowNoCellsToIgnoreWidth,
            RenderColumnHeadings: gridTableConfig.Columns,
            OptionsTopDefaultV2: confOptionsDefault,
            ItemGrouperField: gridTableConfig.ItemGrouperField,
            OptionsOfDataCheckV3: (datos) => {
                if (datos?.length) {
                    return fnGetOpcionesSeleccionados("top-selected", datos);
                }
                return null;
            },
            FilterParameters: (this.GRID_GetFilters() || [])
                .map(d => {
                    if (d.LabelLangKey === undefined) {
                        d.LabelLangKey = UIUtilLang._FixFieldKey(d.Field);
                    }
                    return d;
                }),
            OnChangeOrder: (field, order) => {
                this.VB_SetStatusProp<IStatusGrid>("TableOrderField", field);
                this.VB_SetStatusProp<IStatusGrid>("TableOrderType", order);
            },
            OnFilter: () => {
                this.ctrlTabla._MenuTopDefault._UpdateConfig({
                    MaxOptionsInRow: gridTableConfig.MaxOptionsInRow,
                    Options: fnGetOptTop()
                });

                const inputSearchTxt = this.ctrlTabla._Control.select<HTMLInputElement>(".search > input").node();
                if (inputSearchTxt && (UIWindowManager._GetCurrentParams().get("src") || "") != inputSearchTxt.value)
                    UIWindowManager._SetParam("src", inputSearchTxt.value);

                const tableCfAdv = this.GRID_GetTableConfigAdvanced()
                if (tableCfAdv?.OnFilter) {
                    tableCfAdv.OnFilter();
                }
            },
            FilterExtraBeforeFilterTable: (datoGrid) => {
                let escuelas = this.GridGetEscuelasSeleccionadas();

                return (datoGrid.KinderFiltro == null) ||
                    Boolean(escuelas.find(esc => (datoGrid.KinderFiltro.indexOf(esc.IdKinder) > -1)))
            },
            OnValueSelectRow: (key, value) => {
                console.log(key, value);
            },
            HideCheckboxes: this.GRID_GetTableConfigAdvanced()?.HideCheckboxes,
            StickyCheckInRow: this.GRID_GetTableConfigAdvanced()?.StickyCheckInRow,
            EnableStickyFields: this.GRID_GetTableConfigAdvanced()?.EnableStickyFields,
            AddNameFieldClassToCells: this.GRID_GetTableConfigAdvanced()?.AddNameFieldClassToCells,
            EnableRelevanceSelections: this.GRID_GetTableConfigAdvanced()?.EnableRelevanceSelections,
            OnEndUpdateDataInView: this.GRID_GetTableConfigAdvanced()?.OnEndUpdateDataInView,
            FilterByStrSearch: this.GRID_GetTableConfigAdvanced()?.FilterByStrSearch,
            EvaluatorAndSubLevelsBuild: evalConfig,
            OnClickSyncTable: async () => {
                this.dataGrid = await this.GridOnSyncData();
                return this.dataGrid;
            },
            LangModuleKeyInContext: this.labelsKeyBase
        })
    }

    // NOTE Las implementaciones "Especiales" deben de ir en los Grid correspondientes
    protected async GridGetData(): Promise<Array<GridType>> {
        const idReq = this.GRID_GetDataRequestID();
        if (idReq) {
            return DataModuloMain._GetReqDataArrayById(idReq as any, true) as unknown as GridType[];
        }
        return [];
    }

    private updateDataDelay: NodeJS.Timeout;
    /**
     * @param delayMs @default 1000 ms
     */
    protected GridUpdateDataWithDelay(delayMs = 1000) {
        this.ctrlProgressBar.attr("oculto", false);

        if (this.updateDataDelay) {
            clearTimeout(this.updateDataDelay);
            this.updateDataDelay = null;
        }

        this.updateDataDelay = setTimeout(() => {
            this.GridUpdateData();
            this.updateDataDelay = null;
        }, delayMs);
    }

    protected async GridUpdateData(showProgressBar: boolean = false, reloadGetData = true): Promise<void> {
        if (showProgressBar) {
            this.ctrlProgressBar.attr("oculto", false);
        }
        let lastLength = this.dataGrid.length;
        if (reloadGetData) {
            this.dataGrid = await this.GridGetData();
        }
        this.ctrlTabla._UpdateData(this.dataGrid);
        console.debug("Grid >> Get data:", this.dataGrid.length, " items");

        if (this.dataGrid.length || lastLength !== this.dataGrid.length) {
            this.ctrlProgressBar.attr("oculto", true);
        }
        else if (this.GRID_GetDataRequestID() == null) {
            this.ctrlProgressBar.attr("oculto", true);
        }
        // NOTE Si no hay datos y sí hay RequestID, el progress se remueve hasta que termine el request hecho en el constructor
    }

    protected async GridOnSyncData(): Promise<GridType[]> {
        console.debug("Start Sync...");
        return new Promise(async resolve => {
            this.ctrlProgressBar.attr("oculto", false);

            let GridUpdateData2 = async () => {
                let dataToGrid = await this.GridGetData();
                resolve(dataToGrid);
                // this.GridOnKinderSelectionChange(this.GridGetEscuelasSeleccionadas());
                this.ctrlProgressBar.attr("oculto", true);
                console.debug("End Sync!!!");
            }

            if (this.GRID_GetDataRequestID() != null) {
                await MainPage._ReloadServiceAndAwaitBool(this.GRID_GetDataRequestID(), this.kinders.length ? this.kinders.map(d => d.IdKinder) : null);
                // console.debug("End ReloadService", this.gridRequestID, data.Entidades.CTipoRequest[this.gridRequestID]);
                GridUpdateData2();
            } else {
                GridUpdateData2();
            }
        })
    }

    /** Punto final para exportar datos */
    protected GridOpen_ExportarData(dataToExport: GridType[], type: "filtered" | "checked") {
        const configInit = this.GRID_GetExportarConfig(dataToExport)
        const configFinal: IConfigGridExcelExport<GridType> = configInit || <IConfigGridExcelExport<GridType>>{}
        let dataGrid: GridType[] = dataToExport
        if (configInit == null) {
            if (type == "filtered") {
                dataToExport = this.ctrlTabla._DataFilteredInnerText as any
                dataGrid = this.ctrlTabla._dataFiltered
            } else {
                dataToExport = this.ctrlTabla._DataCheckedInnerText as any
                dataGrid = this.ctrlTabla._dataChecked
            }
            const dataset: { datatable: Object, datagrid: GridType }[] = dataGrid.map((dGrid, i) => ({
                datagrid: dGrid,
                datatable: dataToExport[i],
            }))
            configFinal.OnGetEscuelasTagInSheet = ([dato]) => _DiccEscuela.get(dato.KinderFiltro[0]).Nombre || ""
            configFinal.OnGetDataBySheets = async () => Array.from(d3Group(dataset, d => d.datagrid.KinderFiltro[0]))
                .map<ExcelThings.ISheetConfig<GridType>>(([idEscuela, datos]) => ({
                    IdSheet: idEscuela,
                    SheetName: _DiccEscuela.get(idEscuela).Nombre,
                    Data: datos.map(d => ({ ...d.datatable, KinderFiltro: d.datagrid.KinderFiltro })) as GridType[],
                }))
        }
        // let configFinal: IConfigGridExcelExport<GridType>
        if (!configFinal.IdsEscuelas) {
            configFinal.IdsEscuelas = (() => {
                const idsKinder = new Set<number>()
                dataGrid.forEach(d => (d.KinderFiltro || []).forEach(idK => {
                    idsKinder.add(idK)
                }))
                return Array.from(idsKinder)
            })()
        }
        if (!configFinal.FileName) {
            configFinal.FileName = this.VB_GetUIStringModule("export_filename");
        }
        if (!configFinal.ColumnsConfig?.length) {
            configFinal.ColumnsConfig = this.ctrlTabla._InfoColumns
                .filter(d => !!d.Label)
                .map(d => ({
                    Field: d.Field as (keyof GridType),
                    HeaderTag: d.Label,
                    WidthCell: 32,
                }))
        }
        ExcelThings._ExportData<GridType>({
            ColumnsConfig: configFinal.ColumnsConfig,
            FileName: configFinal.FileName,
            IdCategoria: 0,
            IdsEscuelas: configFinal.IdsEscuelas,
            Modulo: this.modulo,
            OnGetDataBySheets: configFinal.OnGetDataBySheets,
            OnGetEscuelasTagInSheet: configFinal.OnGetEscuelasTagInSheet,
            OnStepFieldInDataRow: configFinal.OnStepFieldInDataRow,
            RequestType: this.GRID_GetDataRequestID(),
            TagEncabezadoHoja: configFinal.TagEncabezadoHoja
        })
    }

    /**
     * * Si la propiedad "Action" no tiene un valor, Modulo y IdsEscuelas ya no son requeridos. No se verifican permisos.
     * * Solo con las acciones Agregar y Editar construye un Tittle predefinido Action + (Module | TipeRequest). Ej: "Agregar circular".
     * Excepto si se define Tittle
     * * Activa el progressbar si res.Resultado > 0 */
    protected GridOpenModal_ActionFormToAGridData<TData>(config: IConfigModalForm<TData>) { // FIX_LANGUAGE MODAL E IMPLEMENTACIONES
        // const typeRequest = this.GRID_GetDataRequestID();
        let autoReloadOnFinally = (config.AutoReloadGridRequestOnFinally == null ? true : config.AutoReloadGridRequestOnFinally);
        const idsEscuelas = Array.from(new Set(config.IdsEscuelas || []));

        ModalThings._GetModalToForm({
            Title: config.Title,
            Action: config.Action,
            LangModuleKeyInContext: this.labelsKeyBase,
            Modulo: (config.Action ? this.modulo : undefined),
            DrawContent: config.DrawContent,
            AccionToHttpMessage: config.AccionToHttpMessage,
            GetForm: config.GetForm,
            IdsEscuelas: (config.Action ? idsEscuelas : undefined),
            OnAccept: !config.OnAccept ? null : async (form, modalThings) => {
                let res = await config.OnAccept(form, modalThings);

                if (res?.Resultado > 0) {
                    if (autoReloadOnFinally && this.GRID_GetDataRequestID()) {
                        const escuelasToReload = config.OnGetIdEscuelasToReload ? config.OnGetIdEscuelasToReload(form, res) : idsEscuelas.length ? idsEscuelas : null;
                        MainPage._ReloadServiceAndAwait(this.GRID_GetDataRequestID(), escuelasToReload)
                            .catch(() => setTimeout(() => {
                                this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                            }, 2000));
                    }
                    this.ctrlProgressBar.attr("oculto", false);
                }

                return res;
            },
            OnClose: config.OnClose,
            Width: config.Width,
            Height: config.Height
        })
    }

    /**
     * * Preparado por defecto para la acción de Eliminar un array de datos
     * * Revisa permisos de usuario !
     * * Activa el progressbar si al final de todo el proceso si al menos un item de DataToProccess es res.Resultado > 0
     * @defaults
     * * Action -> Por defecto es data.Entidades.CAccionPermiso.Eliminar
     * * Message -> Por defecto es el referente a "eliminarseleccionados"
     * * TypeRequest -> por defecto es el Tipo Request del Grid
     */
    protected async GridOpenModal_ProccessArrayData<T = GridType>(config: IConfigModalToProccessArrayData<T>) {
        const typeRequest = config.TypeRequest || this.GRID_GetDataRequestID();
        let autoReloadOnFinally = (config.AutoReloadGridRequestOnFinally == null ? true : config.AutoReloadGridRequestOnFinally);
        let idsEscuelas: number[] = (config.OnGetIdEscuela ? [] : null);
        config.Action = (config.Action || Entidad.CAccionPermiso.Eliminar);

        if (config.OnGetIdEscuela) {
            config.DataToProccess
                .map(d => config.OnGetIdEscuela(d))
                .filter(d => Boolean(d))
                .forEach(d => {
                    if (typeof d == "number") {
                        d = [d];
                    }
                    d.forEach(idEscuela => {
                        if (idsEscuelas.indexOf(idEscuela) == -1) {
                            idsEscuelas.push(idEscuela);
                        }
                    })
                })
        }

        // >> Message Fix Lang context
        if (!config.Message) {
            if (config.Action == Entidad.CAccionPermiso.Eliminar) {
                config.Message = UIUtilLang._GetUIString("confirmation", "eliminarseleccionados")
                    .replace("_NDATA", config.DataToProccess.length + "");
            } else {
                console.warn("-d", "Message no found!!!");
                config.Message = UIUtilLang._GetUIString("c_actions", Entidad.CAccionPermiso[config.Action] as any);
            }
        }

        let finalResults = await ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
            DataToProccess: config.DataToProccess,
            Title: config.Title,
            Message: config.Message,
            Action: config.Action,
            Modulo: this.modulo,
            IdsEscuelas: idsEscuelas,
            TypeRequest: typeRequest,
            LangModuleKeyInContext: this.labelsKeyBase,
            OnDrawContent: config.OnDrawContent,
            OnError_GetItemDataTag: config.OnError_GetItemDataTag,
            OnStepAProccess: config.OnStepAProccess,
            OnStepAllProccess: config.OnStepAllProccess,
            OnEndProccess: config.OnEndProccess,
            Width: (config.Width || 400),
            AccionToHttpMessage: config.AccionToHttpMessage
        })

        const successResults = (finalResults?.filter(d => (d.Resultado > 0)) || []);
        const successResultsData = (config.OnEndAndCloseProccess ? successResults.map(d => d.ItemData) : null);

        if (successResults.length) {
            // -> Lanza el reload de las escuelas de los datos procesados correctamente
            // * Si tan solo uno de los datos no tiene Id de Escuela, se realizará el reload de Todas las escuelas
            let escuelasARecargar: number[] = [];
            for (let res of successResults) {
                if (config.OnGetIdEscuela) {
                    let idsEscuelas2 = config.OnGetIdEscuela(res.ItemData);
                    if (typeof idsEscuelas2 == "number") {
                        idsEscuelas2 = [idsEscuelas2];
                    }
                    if (idsEscuelas2?.length) {
                        idsEscuelas2.forEach(idEscuela => {
                            if (escuelasARecargar.indexOf(idEscuela) == -1) {
                                escuelasARecargar.push(idEscuela);
                            }
                        })
                    } else {
                        console.warn("-d", "Item sin Id de Escuela", res.ItemData);
                        escuelasARecargar = [];
                        break;
                    }
                }
            }
            if (autoReloadOnFinally) {
                MainPage._ReloadServiceAndAwait(typeRequest as DataModuloMain.TipoRequestMonitorId, (escuelasARecargar.length ? escuelasARecargar : null))
                    .then(() => {
                        if (config.OnEndAndCloseProccess) {
                            config.OnEndAndCloseProccess(successResultsData, escuelasARecargar, finalResults);
                        }
                    })
                    .catch(() => setTimeout(() => {
                        this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                    }, 2000));
            }
            else if (config.OnEndAndCloseProccess) {
                config.OnEndAndCloseProccess(successResultsData, escuelasARecargar, finalResults);
            }
            this.ctrlProgressBar.attr("oculto", false);
        }
        else if (config.OnEndAndCloseProccess) {
            config.OnEndAndCloseProccess(successResultsData, null, finalResults);
        }
    }

    // ***********************************************************
    // PERMISOS THINGS
    // ***********************************************************
    /**
     * @deprecated 
     */
    protected GridHasPermisoAccion(idAccion: Entidad.CAccionPermiso, ...idsEscuelas: number[]) {
        let hasPermission = false;
        if (idsEscuelas?.length) {
            const permisoRes = UIUtilPermission._HasActionsPermissionFromManySchools(idAccion, this.modulo, idsEscuelas);
            hasPermission = permisoRes.HasPermission;
            if (!permisoRes.HasPermission && permisoRes.Message) {
                this.notificacion._Mostrar(permisoRes.Message, "ADVERTENCIA");
            }
        }
        else {
            hasPermission = UIUtilPermission._HasAccionPermission(idAccion, this.modulo);
        }
        return hasPermission;
    }

    /**
     * @param idAccion
     * @param idsEscuelas @default []
     * @param printMessage @default false
     * @returns `boolean`
     */
    protected GridHasPermisoAccionV2(idAccion: Entidad.CAccionPermiso, idsEscuelas: number[] = [], printMessage: boolean = false) {
        let hasPermission = false;
        if (idsEscuelas.length) {
            const permisoRes = UIUtilPermission._HasActionsPermissionFromManySchools(idAccion, this.modulo, idsEscuelas);
            hasPermission = permisoRes.HasPermission;
            if (!permisoRes.HasPermission && permisoRes.Message && printMessage) {
                this.notificacion._Mostrar(permisoRes.Message, "ADVERTENCIA");
            }
        }
        else {
            hasPermission = UIUtilPermission._HasAccionPermission(idAccion, this.modulo);
        }
        return hasPermission;
    }

    protected GridGetEscuelasConPermisoDeAccion(idAccion: Entidad.CAccionPermiso, idEscuela?: number) {
        let escuelas: Entidad.IEscuela[];
        if (!this.modulo) {
            escuelas = DataModuloMain._GetReqDataArrayByName("Escuela", true);
        } else {
            escuelas = UIUtilPermission._GetSchoolsByActionModule(this.modulo, idAccion, idEscuela);
        }
        return escuelas;
    }

    // ****************************************************************
    // DATA
    // ****************************************************************

    private ObtenerEscuelasList() {
        let escuelas = Array.from(_DiccEscuela.values())

        if (this.modulo) {
            const esModuloFinanzaBloqueable = (Entidad._MODULOS_FINANZAS.indexOf(this.modulo) != -1);
            const esModuloEvalBloqueable = [...Entidad._MODULOS_LOGROS, ...Entidad._MODULOS_CALIFICACIONES].includes(this.modulo);
            escuelas = escuelas.filter(d => {
                if (!UIUtilPermission._HasModulePermission(this.modulo, d.IdKinder)) return false;
                if (!esModuloFinanzaBloqueable && !esModuloEvalBloqueable) return true;
                if (esModuloFinanzaBloqueable)
                    return UIUtilPermission._HasFinanzasModulesPermissionFromSchools(d.IdKinder);
                else
                    return UIUtilPermission._ResolveModuloEvaluacion(this.modulo, d.IdKinder);
            });
        }

        return escuelas.sort((a, b) => ascending(a.Nombre.toLowerCase(), b.Nombre.toLowerCase()));
    }
}

interface IGridActionProccess {
    /** @default true */
    AutoReloadGridRequestOnFinally?: boolean;
}

type IConfigModalToProccessArrayData<TData> = Pick<ModalThings.IConfigProcessServiceByServiceBasic<TData>, "DataToProccess" | "OnStepAProccess" | "OnError_GetItemDataTag" | "AccionToHttpMessage" | "OnEndProccess" | "Width" | "OnDrawContent">
    & Partial<Pick<ModalThings.IConfigProcessServiceByServiceBasic<TData>, "Title" | "TypeRequest" | "Message" | "OnStepAllProccess" | "Action">>
    & IGridActionProccess
    & {
        OnGetIdEscuela: (dato: TData) => (number | number[]);
        OnEndAndCloseProccess?: (datosCorrectos: TData[], idsEscuelas: number[], results: DataDRequest.IRequestResponseA[]) => void;
    };

type IConfigModalForm<TData> = Pick<ModalThings.IConfigModalToForm<TData>, "Action" | "DrawContent" | "AccionToHttpMessage" | "GetForm" | "IdsEscuelas" | "OnAccept" | "OnClose" | "Width" | "Height">
    & Partial<Pick<ModalThings.IConfigProcessServiceByServiceBasic<TData>, "Title">>
    & IGridActionProccess
    & {
        /** Los IDs de escuelas que se retornen son usados para `ReloadRequest`
         * Se lanza al finalizar `OnAccept` y si `AutoReloadGridRequestOnFinally` == `true` */
        OnGetIdEscuelasToReload?: (form: FormGenerator<TData>, requestResult: DataDRequest.IRequestResponseA<any>) => number[];
    }

export interface IConfigGridExcelExport<T> extends Pick<
    ExcelThings.IConfigExcelExport<T>,
    "IdsEscuelas" | "OnGetDataBySheets" | "OnGetEscuelasTagInSheet" | "OnStepFieldInDataRow" | "TagEncabezadoHoja" | "ConsultarLogos"
> {
    /** @default this.VB_GetUIStringModule("export_filename") */
    FileName?: string;
    /** @default
     * this.ctrlTabla.prop_InfoColumns
            .map(d => ({
                Field: d.Field as (keyof T),
                HeaderTag: d.Label
            }))
    */
    ColumnsConfig?: ExcelThings.IColumnToExcelExportFileConfig<T>[];
}
