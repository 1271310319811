/// <reference path="../../types/openpay.d.ts"/>

import { DataDRequest } from "../DRequest";
import { DataUtilAlertBot } from "../util/AlertBot";
import { DataUtil } from "../util/Util";

export function _SvOpenPayCardTokenCreate(cardNumber: string, holderName: string, expirationYear: string, expirationMonth: string, cvv2: string) {
    DataUtil._BuildOpenPay();

    return new Promise<DataDRequest.IRequestResponseA<OpenPay.IToken>>((resolve, reject) => {
        OpenPay.token.create(
            {
                "card_number": cardNumber,
                "holder_name": holderName,
                "expiration_year": expirationYear,
                "expiration_month": expirationMonth,
                "cvv2": cvv2,
                "address": undefined
                // "address": {
                //     "city": "Querétaro",
                //     "line3": "Queretaro",
                //     "postal_code": "76900",
                //     "line1": "Av 5 de Febrero",
                //     "line2": "Roble 207",
                //     "state": "Queretaro",
                //     "country_code": "MX"
                // }
            },
            // OnSuccess
            (result) => {
                resolve({
                    Resultado: result.status,
                    Datos: result.data
                });
            },
            // onError
            (result) => {
                DataUtilAlertBot._SendInfo("Error al crear token de tarjeta ", result.message, "status: \n" + JSON.stringify(result.data));
                let res = (result.status > 0 ? -result.status : result.status);

                resolve({
                    Resultado: res
                });
            }
        );
    })
}
